import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Tooltip } from '@mui/material';

import './document-grouping-container.css';
import Loading from '../../components/common/Loading';
import CaseContext from '../Case/CaseContext';
import { useTimelineList } from '../Timeline/useTimeline';
import CaseHeader from '../Timeline/CaseHeader';
import { getCaseFile } from '../../api';
import { useAsync } from '../../hooks/useAsync';
import { useGroupings } from './utils/documentState';
import Modal from '../../components/common/HTML_components/Modal/Modal';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';
import DocumentGroupingContainer from './DocumentGroupingContainer';

export default function GroupingTab() {
  const { fileID: caseFileID, pageID, caseID } = useParams();
  const logUserActivity = useActivityLog();
  const [caseFile, refetchCaseFile] = useAsync(
    () => getCaseFile(caseID ?? '', caseFileID ?? ''),
    [caseID, caseFileID],
  );

  const { caseInstance } = useContext(CaseContext);
  const { data: timelineList } = useTimelineList(caseID);

  const [isMergeAllModalOpen, setIsMergeAllModalOpen] = useState(false);
  const [isBuildGroupingsModalOpen, setIsBuildGroupingsModalOpen] = useState(false);
  const navigate = useNavigate();

  const qAorProcessor: 'qa' | 'mdoc' | undefined = useMemo(() => {
    if (caseFile.status !== 'resolved') {
      return undefined;
    }
    if (
      caseFile.data.data?.file_status === 'QA_REQUIRED' ||
      caseFile.data.data?.file_status === 'APPROVED'
    ) {
      return 'qa';
    }
    return 'mdoc';
  }, [caseFile]);

  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  const {
    status,
    pages,
    groupingActionOnClicks,
    getButtonEnabledStatuses,
    depthAt,
    pageIndexById,
    groupIDByPageID,
    attachmentIDByPageID,
    buildGroupings,
    resetEdges,
    canBuildGroupings,
    groupedDocumentTree,
    startPage,
    endPage,
    onChangePageRange,
    documentScrollerCompatiblePages,
  } = useGroupings(caseID ?? '', caseFileID ?? '');

  useEffect(() => {
    if (pages.length > 0 && !pageID) {
      const firstPageID = pages[0].id;
      navigate(`${firstPageID}`);
      const currentPageDiv = document.getElementById(`chip-${firstPageID}`);
      if (currentPageDiv) {
        currentPageDiv.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }
  }, [pages]);

  useEffect(() => {
    if (caseID && caseFileID) {
      logUserActivity({
        activity: 'case:grouping',
        case_id: caseID,
        file_id: caseFileID,
      });
    }
  }, [caseID, caseFileID]);

  const currentPage = useMemo(() => {
    if (!pageIndexById || !pageID) {
      return undefined;
    }

    const currentPageIndex = pageIndexById.get(pageID) ?? 0;
    return pages?.[currentPageIndex];
  }, [pageIndexById, pageID, pages]);

  if (pages == null || pages.length === 0) {
    return <Loading />;
  }

  const onChangePage = (nextIndex: number) => {
    const newPage = pages?.[nextIndex - 1];
    navigate(`${newPage?.id}`);
    const currentPageDiv = document.getElementById(`chip-${newPage?.id}`);
    if (currentPageDiv) {
      currentPageDiv.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  const handleChangePageRange = (start: number, end: number) => {
    const startPageID = onChangePageRange(start, end);
    navigate(`${startPageID}`);
  };

  const resetEdgesOnClick = async () => {
    if (qAorProcessor === undefined || pages.length === 0) {
      return;
    }

    setIsMergeAllModalOpen(false);

    await resetEdges(qAorProcessor, startPage, endPage);
  };

  const buildGroupingsOnClick = async () => {
    if (endPage && startPage && currentPage) {
      setIsBuildGroupingsModalOpen(false);

      const buildUpToPage = currentPage.page_number < endPage ? currentPage.page_number : endPage;

      const { status: buildGroupingsStatus } = await buildGroupings(
        startPage,
        buildUpToPage,
        true, // want to build based on current page
      );
      if (buildGroupingsStatus === 'rejected') {
        return;
      }
      refetchCaseFile();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        height: '100%',
      }}
    >
      <Modal isOpen={isMergeAllModalOpen} onClose={() => setIsMergeAllModalOpen(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '1.25rem',
              fontWeight: 500,
              color: 'var(--color-text-primary)',
              marginBottom: '1rem',
            }}
          >
            Merge All Document Groupings?
          </span>
          <span>
            This action will remove all current groupings and merge your page range into a single
            document. Would you like to proceed?
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <button
              type="button"
              onClick={() => setIsMergeAllModalOpen(false)}
              className="sm-button"
              style={{
                marginRight: '1rem',
              }}
            >
              Cancel
            </button>
            <button
              onClick={resetEdgesOnClick}
              type="button"
              className="sm-button sm-button-primary"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isBuildGroupingsModalOpen} onClose={() => setIsBuildGroupingsModalOpen(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '1.25rem',
              fontWeight: 500,
              color: 'var(--color-text-primary)',
              marginBottom: '1rem',
            }}
          >
            Build Groupings for File?
          </span>
          <span>
            {`Building groupings will change any documents in the review tab up to this point in the
              file to reflect the new groupings. This will build groupings from the start of 
              your page range up to the current grouping. Would you like to proceed?`}
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <button
              type="button"
              onClick={() => setIsBuildGroupingsModalOpen(false)}
              className="sm-button"
              style={{
                marginRight: '1rem',
              }}
            >
              Cancel
            </button>
            <button
              onClick={buildGroupingsOnClick}
              type="button"
              className="sm-button sm-button-primary"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <div className="document-grouping-container" id="document-display">
        <DocumentGroupingContainer
          pageID={pageID ?? ''}
          pages={pages}
          depthAt={depthAt}
          groupingActionOnClicks={groupingActionOnClicks}
          pageIndexById={pageIndexById}
          groupIDByPageID={groupIDByPageID}
          attachmentIDByPageID={attachmentIDByPageID}
          getButtonEnabledStatuses={getButtonEnabledStatuses}
          groupedDocumentTree={groupedDocumentTree}
          startPage={startPage}
          endPage={endPage}
          onChangePageRange={handleChangePageRange}
          documentScrollerCompatiblePages={documentScrollerCompatiblePages}
          onChangePage={onChangePage}
          groupingType="grouping"
        />
      </div>

      <Tooltip title="Reset all document edges for your page range.">
        <div
          style={{
            position: 'fixed',
            top: 63,
            right: 0,
            width: 'fit-content',
            marginRight: '2rem',
            marginBottom: '0.5rem',
          }}
        >
          {!(status === 'staged' || status === 'saving') && (
            <button
              type="button"
              className="sm-button"
              disabled={status === 'loading'}
              onClick={() => setIsMergeAllModalOpen(true)}
              style={{
                backgroundColor: 'var(--color-primary-main)',
                color: 'white',
              }}
            >
              Merge All
            </button>
          )}
        </div>
      </Tooltip>
      <Tooltip title="Finalize file groupings">
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: 'fit-content',
            marginRight: '2rem',
            marginBottom: '0.5rem',
          }}
        >
          {status === 'staged' || status === 'saving' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
              <span
                style={{
                  marginLeft: '0.5rem',
                }}
              >
                {' '}
                Update In Progress
              </span>
            </div>
          ) : (
            <button
              type="button"
              className="sm-button"
              disabled={status === 'loading' || status === 'errored' || !canBuildGroupings(pageID)}
              onClick={() => setIsBuildGroupingsModalOpen(true)}
              style={{
                backgroundColor: 'var(--color-primary-main)',
                color: 'white',
                opacity: !canBuildGroupings(pageID) ? 0.5 : 1,
              }}
            >
              Mark Previous Documents as Done
            </button>
          )}
        </div>
      </Tooltip>
    </div>
  );
}
