import { useCallback, useMemo } from 'react';
import { PaginatedPageFragment, usePaginatedPagesQuery } from '../../../__generated__/graphql';
import { useUser } from '../../../library/contexts/AuthContext';
import { createGetTimelineDocumentsInputFromFilters } from '../../Timeline/gql/utils/timelineGqlUtils';
import { UseTimelineDocumentsParams } from '../../Timeline/types/timelineTypes';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import usePDFViewerStore from '../../Case/usePDFViewerStore';
import config from '../../../config';

const usePages = (params: UseTimelineDocumentsParams, currentPageIndex?: number) => {
  const { userId } = useUser();
  const isFileProcessor = useIsFileProcessor();
  const setDocumentRotation = usePDFViewerStore((state) => state.setDocumentRotation);

  const whereInput = useMemo(
    () => createGetTimelineDocumentsInputFromFilters({ ...params, userId }, isFileProcessor),
    [params, userId],
  );

  const defaultSkip = useMemo(
    () =>
      Math.max(
        (currentPageIndex ?? 0) - config.documents.gql.defaultTake / 2,
        config.documents.gql.defaultSkip,
      ),
    [],
  );
  const paginatedPagesQuery = usePaginatedPagesQuery({
    variables: {
      ...whereInput,
      skip: defaultSkip,
      take: config.documents.gql.defaultTake,
    },
    refetchWritePolicy: 'merge',
  });

  const refetch = useCallback(
    (refetchParams: UseTimelineDocumentsParams) => {
      const newWhereInput = createGetTimelineDocumentsInputFromFilters(
        { ...refetchParams, userId },
        isFileProcessor,
      );
      paginatedPagesQuery.refetch({
        ...newWhereInput,
        skip: defaultSkip,
        take: config.documents.gql.defaultTake,
      });
    },
    [paginatedPagesQuery, userId, isFileProcessor, defaultSkip],
  );

  const fetchMore = useCallback(
    (
      params: UseTimelineDocumentsParams & {
        skip: number;
        take: number;
      },
    ) => {
      paginatedPagesQuery.fetchMore({
        variables: {
          ...whereInput,
          skip: params.skip ?? config.timeline.gql.defaultSkip,
          take: params.take ?? config.timeline.gql.defaultTake,
        },
      });
    },
    [paginatedPagesQuery],
  );
  return useMemo(() => {
    const pageMap = getPageMap(paginatedPagesQuery.data?.paginatedPages ?? []);
    if ((paginatedPagesQuery.data?.paginatedPages?.length ?? 0) > 0) {
      const rotationMap: Record<number, number> = {};
      if (paginatedPagesQuery.data?.paginatedPages) {
        paginatedPagesQuery.data.paginatedPages.forEach((page: PaginatedPageFragment) => {
          if (page.rotationAngle !== 0) {
            rotationMap[page.id] = page.rotationAngle;
          }
        });
        setDocumentRotation(rotationMap);
      }
    }
    return {
      ...paginatedPagesQuery,
      refetch,
      fetchMore,
      pageMap,
    };
  }, [paginatedPagesQuery, paginatedPagesQuery.data?.paginatedPages, refetch, fetchMore]);
};

function getPageMap(pages: PaginatedPageFragment[]): Record<number, PaginatedPageFragment> {
  return pages.reduce((map: any, page) => {
    map[page.id] = page;
    return map;
  }, {});
}

export default usePages;
