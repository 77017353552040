export default function ExpandIndexRow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M5.66675 7.99984H11.3334M15.5834 7.99984C15.5834 11.9119 12.4121 15.0832 8.50008 15.0832C4.58806 15.0832 1.41675 11.9119 1.41675 7.99984C1.41675 4.08782 4.58806 0.916504 8.50008 0.916504C12.4121 0.916504 15.5834 4.08782 15.5834 7.99984Z"
        stroke="#475467"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
