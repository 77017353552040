/* eslint-disable react/jsx-props-no-spreading */
import { Link, useSearchParams } from 'react-router-dom';

export default function LinkWithQuery({
  children,
  to,
  timelineID = null,
  pageID = null,
  documentID = null,
  queryParms = {},
  disableLink = false,
  ...props
}) {
  const [searchParams] = useSearchParams();
  let searchParamsObj = Object.fromEntries(searchParams.entries());

  if (timelineID) {
    searchParamsObj.timelineID = timelineID;
  }
  if (pageID) {
    searchParamsObj.pageID = pageID;
  }
  if (documentID) {
    searchParamsObj.documentID = documentID;
  }
  if (queryParms) {
    searchParamsObj = { ...searchParamsObj, ...queryParms };
  }
  let search = '';
  if (searchParamsObj) {
    search = getQueryParams(searchParamsObj);
  }

  if (disableLink) {
    return <span>{children}</span>;
  }

  return (
    <Link to={search ? to + search : to} {...props}>
      {children}
    </Link>
  );
}

export function getQueryParams(searchParamsObj) {
  let search = '';
  for (const [key, value] of Object.entries(searchParamsObj)) {
    if (search.length > 0 && !search.includes(key)) {
      search += `&${key}=${value}`;
    } else {
      search = `?${key}=${value}`;
    }
  }
  return search;
}
