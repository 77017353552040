import { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import './dropdown-select.css';
import Theme from '../../../../theme';

type MenuPosition = {
  top: number;
  left: number;
} | null;

type Option = {
  label: string;
  value: string;
};

type CustomSelectMenuProps = {
  options: Option[];
  onChange: (option: Option) => void;
  currentOption: Option | null;
  onCommit: () => void;
  className: string;
  dropUp?: boolean;
};

function CustomSelectMenu({
  options,
  onChange,
  currentOption,
  onCommit,
  className = '',
  dropUp = false,
}: CustomSelectMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(currentOption);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>(null);
  const anchorRef = useRef(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const calculateMenuPosition = useCallback(
    (rect: DOMRect): { top: number; left: number } => {
      if (dropUp) {
        return {
          top: rect.top + window.scrollY - 8,
          left: rect.left + window.scrollX,
        };
      }
      return {
        top: rect.top + rect.height + window.scrollY,
        left: rect.left + window.scrollX,
      };
    },
    [dropUp],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        anchorRef.current &&
        !anchorRef.current.contains(event.target as Node) &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const rect = anchorRef.current ? anchorRef.current?.getBoundingClientRect() : null;
      if (rect) {
        setMenuPosition(calculateMenuPosition(rect));
      }
    };

    // Reports should be refactored to not be scrollable, so a second event listener is not needed
    document.getElementById('app-container')?.addEventListener('scroll', handleScroll);
    document
      .getElementById('scrollable-report-container')
      ?.addEventListener('scroll', handleScroll);

    // Remove the event listeners when the component unmounts
    return () => {
      document.getElementById('app-container')?.removeEventListener('scroll', handleScroll);
      document
        .getElementById('scrollable-report-container')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const rect = anchorRef.current ? anchorRef.current.getBoundingClientRect() : null;

    if (isOpen && rect) {
      setMenuPosition(calculateMenuPosition(rect));
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedOption(currentOption);
  }, [currentOption]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  const menu = menuPosition != null && (
    <div
      className="dropdown-list"
      ref={menuRef}
      style={{
        position: 'absolute',
        top: menuPosition.top,
        left: menuPosition.left,
        transform: dropUp ? `translateY(-100%)` : undefined,
      }}
    >
      {options.map((option) => (
        <div
          key={option.value}
          style={{
            backgroundColor:
              selectedOption?.value === option.value
                ? 'var(--color-selected-grey-main)'
                : 'default',
          }}
          className="dropdown-item"
          onClick={() => handleSelect(option)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );

  const iconStyle = {
    width: '20px',
    height: '20px',
    color: Theme.palette.subHeading.main,
  };

  const arrow = isOpen ? (
    <KeyboardArrowUp style={iconStyle} />
  ) : (
    <KeyboardArrowDown style={iconStyle} />
  );

  return (
    <div className={`dropdown ${className}`} ref={anchorRef} onBlur={onCommit}>
      <div className="dropdown-select" onClick={() => setIsOpen(!isOpen)}>
        <span>{selectedOption ? selectedOption.label : 'Select item'}</span>
        {arrow}
      </div>
      {isOpen && ReactDOM.createPortal(menu, document.body)}
    </div>
  );
}

export default CustomSelectMenu;
