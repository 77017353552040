import { getIndexTemplateList } from '../../../../api/index-management/api';
import { useAsync } from '../../../../hooks/useAsync';

export function useGetIndexTemplates() {
  const [indexTemplatesResp, refreshIndexTemplates] = useAsync(() => getIndexTemplateList(), []);

  return {
    indexTemplates: indexTemplatesResp?.status === 'resolved' ? indexTemplatesResp?.data?.data : [],
    loading: indexTemplatesResp.status === 'pending',
  } as { indexTemplates: { id: string; name: string }[]; loading: boolean };
}
