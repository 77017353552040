import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import NewSearchBar from './NewSearchBar';
import useSearchStore from '../../library/utilities/useSearchStore';
import { useDebounceInput, useSearchQuery } from '../../library/utilities/searchUtils';

type SearchNavigationControllerProps = {
  caseID: string;
  type: 'timeline' | 'documents';
  pageList: Array<{
    id: number;
    documentID: string;
    pageNumber: number;
  }>;
};

function SearchBarWithNavigation({
  caseID,
  pageList,
  type = 'timeline',
}: SearchNavigationControllerProps) {
  const navigate = useNavigate();
  const { timelineID, pageID } = useParams();
  const {
    searchStr,
    setSearchStr,
    setSearchResults,
    isSearching,
    setIsSearching,
    currentSearchIndex,
    totalOccurancesFound,
    goToNextMatch,
    goToPreviousMatch,
    getCurrentMatch,
    findClosestMatch,
  } = useSearchStore();

  const [inputValue, setInputValue] = useState(searchStr);

  const debouncedInput = useDebounceInput({
    initialValue: inputValue,
    delay: 500,
    onChange: setInputValue,
  });

  const { data: rawSearchResults, isLoading: searchInProgress } = useSearchQuery({
    caseID,
    searchTerm: searchStr,
  });

  const navigateToMatch = useCallback(
    (match: any) => {
      if (!match) {
        return;
      }

      if (type === 'timeline') {
        if (!match?.entryID || !match?.pageID) {
          return;
        }
        navigate(`/case/${caseID}/timeline/${timelineID}/${match.entryID}/${match.pageID}`);
      } else {
        if (!match?.pageID || !match?.documentID) {
          return;
        }
        navigate(`/case/${caseID}/documents/${match.documentID}/${match.pageID}`);
      }
    },
    [caseID, timelineID, type],
  );

  // Set + compute search results and navigate to closest match
  useEffect(() => {
    if (searchInProgress) {
      setIsSearching(true);
      return;
    }

    setSearchResults(rawSearchResults, pageList);

    // Only handle navigation if we have search results
    if (rawSearchResults) {
      const closestMatch = pageID ? findClosestMatch(Number(pageID), pageList) : getCurrentMatch();

      if (closestMatch) {
        navigateToMatch(closestMatch);
      }

      if (rawSearchResults.totalOccurancesFound >= 5000) {
        toast.warn('Search results limited to 5000 results. Consider refining your search.');
      }
    }

    setIsSearching(false);
  }, [rawSearchResults, pageList?.length, setSearchResults, searchInProgress, navigateToMatch]);

  // Set search string w/ debounce
  useEffect(() => {
    setSearchStr(debouncedInput.value);
  }, [debouncedInput.value, setSearchStr]);

  // Reset search when caseID changes
  useEffect(() => {
    setSearchStr('');
    setInputValue('');
  }, [caseID, setSearchStr]);

  // Handle arrow up and down
  const handleArrowUp = useCallback(() => {
    const previousMatch = goToPreviousMatch();
    navigateToMatch(previousMatch);
  }, [goToPreviousMatch, navigateToMatch]);

  const handleArrowDown = useCallback(() => {
    const nextMatch = goToNextMatch();
    navigateToMatch(nextMatch);
  }, [goToNextMatch, navigateToMatch]);

  return (
    <NewSearchBar
      searchStr={debouncedInput.value}
      handleChange={debouncedInput.onChange}
      searchingInProgress={isSearching}
      totalSearchOccurances={totalOccurancesFound}
      currentSearchMatchIndex={currentSearchIndex}
      handleArrowUp={handleArrowUp}
      handleArrowDown={handleArrowDown}
    />
  );
}

export default SearchBarWithNavigation;
