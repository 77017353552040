import { create } from 'zustand';
import {
  flattenSearchResults,
  sortAndEnrichSearchResults,
  createPageSearchResultsMap,
} from './searchUtils';
import { searchResults, SearchMatch, PageListValue } from './types/search';

interface SearchStore {
  searchStr: string;
  setSearchStr: (searchStr: string) => void;
  setSearchResults: (
    searchResults: searchResults | undefined,
    orderedPageList: PageListValue[],
  ) => SearchMatch | null;

  pageSearchResultsMap: Record<string, SearchMatch[]>;
  searchMatchesList: SearchMatch[];
  totalOccurancesFound: number;

  isSearching: boolean;
  setIsSearching: (isSearching: boolean) => void;

  error: string | null;
  currentSearchIndex: number;

  goToNextMatch: () => SearchMatch | undefined;
  goToPreviousMatch: () => SearchMatch | undefined;

  getCurrentMatch: () => SearchMatch | null;
  findClosestMatch: (pageID: number, orderedPageList: PageListValue[]) => SearchMatch | null;
}

const useSearchStore = create<SearchStore>((set, get) => ({
  searchStr: '',
  setSearchStr: (searchStr: string) => set({ searchStr }),
  pageSearchResultsMap: {},
  searchMatchesList: [],
  isSearching: false,
  setIsSearching: (isSearching: boolean) => set({ isSearching }),
  error: null,
  currentSearchIndex: 0,
  totalOccurancesFound: 0,
  setSearchResults: (
    rawSearchResults: searchResults | undefined,
    orderedPageList: PageListValue[],
  ) => {
    if (!rawSearchResults) {
      set({
        pageSearchResultsMap: {},
        isSearching: false,
        searchMatchesList: [],
        currentSearchIndex: 0,
        totalOccurancesFound: 0,
      });
      return null;
    }
    const searchMatchesList = flattenSearchResults(rawSearchResults);
    const sortedMatchesList = sortAndEnrichSearchResults(searchMatchesList, orderedPageList);
    const pageSearchResultsMap = createPageSearchResultsMap(sortedMatchesList);
    set({
      isSearching: false,
      searchMatchesList: sortedMatchesList,
      pageSearchResultsMap,
      totalOccurancesFound: sortedMatchesList.length,
    });

    return sortedMatchesList[0];
  },

  getCurrentMatch: () => {
    const { searchMatchesList, currentSearchIndex } = get();
    return searchMatchesList[currentSearchIndex];
  },

  goToNextMatch: () => {
    const { searchMatchesList, currentSearchIndex } = get();
    if (!searchMatchesList.length) {
      return undefined;
    }

    const nextIndex = (currentSearchIndex + 1) % searchMatchesList.length;
    set({ currentSearchIndex: nextIndex });
    return searchMatchesList[nextIndex];
  },

  goToPreviousMatch: () => {
    const { searchMatchesList, currentSearchIndex } = get();
    if (!searchMatchesList.length) {
      return undefined;
    }

    const previousIndex =
      (currentSearchIndex - 1 + searchMatchesList.length) % searchMatchesList.length;
    set({ currentSearchIndex: previousIndex });
    return searchMatchesList[previousIndex];
  },

  findClosestMatch: (pageID: number, orderedPageList: PageListValue[]) => {
    const { searchMatchesList } = get();
    const currentPageIndex = orderedPageList.findIndex((page) => page.id === pageID);
    if (currentPageIndex === -1) {
      return null;
    }

    const closestMatch = searchMatchesList.reduce(
      (closest, match, index) => {
        const matchPageIndex = orderedPageList.findIndex((page) => page.id === match.pageID);
        if (matchPageIndex === -1) {
          return closest;
        }

        const currentDistance = Math.abs(currentPageIndex - matchPageIndex);
        const closestDistance = closest
          ? Math.abs(
              currentPageIndex -
                orderedPageList.findIndex((page) => page.id === closest.match.pageID),
            )
          : Infinity;
        return currentDistance < closestDistance ? { match, index } : closest;
      },
      null as { match: SearchMatch; index: number } | null,
    );

    if (closestMatch) {
      set({ currentSearchIndex: closestMatch.index });
      return closestMatch.match;
    }

    return null;
  },
}));

export default useSearchStore;
