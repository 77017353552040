import { Box, debounce } from '@mui/material';
import React, { useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { PaginatedPageFragment } from '../../__generated__/graphql';
import { Note } from '../Notes/types/noteTypes';
import useUpdatePageViewed from '../Page/gql/updatePageViewed';
import useToggleFavouriteMutation from '../Page/gql/useUpdatePageFavourite';
import SmallPageCard, { SmallPageCardSkeleton } from '../Timeline/Components/SmallPageCard';
import useDisplayStore from '../Timeline/useDisplayStore';
import useDocumentSearchStore from '../Timeline/useDocumentSearchStore';
import DocumentCard, { DocumentCardSkeleton } from './Components/DocumentCard';
import DocumentList from './Components/DocumentList';
import { DocumentBasicType } from './gql/types/documentTypes';
import useDocumentDescriptors from './gql/useDocumentDescriptors';
import usePages from '../Page/gql/usePages';
import {
  calculateNumberOfDocumentsBeforeIndex,
  getItemSize,
  getTopOffset,
} from './utils/DocumentListUtils';
import { PageControls } from '../Page/types/pageTypes';
import useCaseFiles from '../Files/useCaseFiles';
import useSources from '../Timeline/gql/useSources';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { DocumentTag } from '../../api';
import useSearchStore from '../../library/utilities/useSearchStore';
import { SearchMatch } from '../../library/utilities/types/search';

export default function DocumentListController({ notes }: { notes: Note[] }) {
  const params = useParams();
  const { pageID, documentID } = params;
  const caseID = params.caseID!;
  const listRef = React.useRef(null);
  const variableListRef: any = React.useRef(null);
  const [scrollOffsetState, setScrollOffsetState] = React.useState({ pageID: -1, topOffset: -1 });
  const [filters] = useDocumentSearchStore((state) => [state.filters], shallow);
  const [timelineWidth] = useDisplayStore((state) => [state.timelineWidth]);
  const [changeFavouriteMark] = useToggleFavouriteMutation();
  const [updatePageToViewed] = useUpdatePageViewed();

  const isFileProcessor = useIsFileProcessor();

  const sources = useSources();

  const pageSearchResultsMap = useSearchStore((state) => state.pageSearchResultsMap);

  const documentDescriptorQuery = useDocumentDescriptors({
    caseId: caseID,
    filters,
  });
  const listDescriptor = documentDescriptorQuery?.flatDocumentList ?? [];

  const currentPageIndex = useMemo(
    () => listDescriptor.findIndex((item: any) => item === Number(pageID)),
    [listDescriptor, pageID],
  );

  const pagesQuery = usePages(
    {
      caseId: caseID,
      filters,
    },
    currentPageIndex,
  );

  const { data: caseFiles } = useCaseFiles(caseID);

  const handleIsItemLoaded = useCallback(
    (index: number) => {
      const key = listDescriptor[index];
      return pagesQuery?.pageMap[key] !== undefined;
    },
    [listDescriptor, pagesQuery?.pageMap],
  );

  // Handle pages fetching with correct block when new page is clicked
  useEffect(() => {
    if (pageID && !pagesQuery?.pageMap[pageID]) {
      const numberOfDocumentsBeforeCurrentPage = calculateNumberOfDocumentsBeforeIndex(
        listDescriptor,
        currentPageIndex,
      );
      const pagesBeforeToLoad = Math.max(currentPageIndex - numberOfDocumentsBeforeCurrentPage, 0);

      handleLoadMoreItems(pagesBeforeToLoad, currentPageIndex + 50);
    }
  }, [pageID, currentPageIndex, pagesQuery.pageMap]);

  const handleLoadMoreItems = useCallback(
    debounce((from, to) => {
      to = Math.max(to, from + 10);

      // Count the number of documents before the 'from' index
      const numberOfDocumentsBeforeFrom = calculateNumberOfDocumentsBeforeIndex(
        listDescriptor,
        from,
      );

      // Adjust 'from' by subtracting the number of documents before it
      const adjustedFrom = Math.max(0, from - numberOfDocumentsBeforeFrom);
      pagesQuery.fetchMore({
        caseId: caseID ?? '',
        filters,
        skip: adjustedFrom,
        take: to - adjustedFrom,
      });
    }, 500),
    [pagesQuery, caseID, filters],
  );

  const calculateItemSize = useCallback(
    (index: number) => getItemSize(listDescriptor[index]),
    [listDescriptor],
  );

  const pageControls = useMemo(() => {
    return {
      handleToggleViewed: updatePageToViewed,
      handleToggleFavourite: changeFavouriteMark,
    };
  }, []);
  const renderComponent = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const key = listDescriptor[index];

      if (Array.isArray(key)) {
        return renderDocument(
          key[0],
          style,
          caseFiles,
          documentDescriptorQuery.data?.documentListDescriptor,
        );
      }

      return renderPage(
        key,
        style,
        pagesQuery?.pageMap,
        Number(pageID),
        notes,
        pageControls,
        sources,
        isFileProcessor,
        pageSearchResultsMap,
      );
    },
    [
      listDescriptor,
      pageID,
      pagesQuery.data?.paginatedPages,
      notes,
      caseFiles,
      pageSearchResultsMap,
    ],
  );

  const onScroll = useCallback((value: any) => {
    setScrollOffsetState((prevState) => ({ ...prevState, topOffset: value.scrollOffset }));
  }, []);

  const listKey = useMemo(() => JSON.stringify(listDescriptor), [listDescriptor]);

  // Handles scrolling to the correct position when the pageID changes
  useScrollEffect(
    pageID,
    documentID,
    listDescriptor,
    scrollOffsetState,
    setScrollOffsetState,
    variableListRef,
  );

  return DocumentList({
    handleIsItemLoaded,
    handleLoadMoreItems,
    calculateItemSize,
    renderComponent,
    onScroll,
    listRef,
    variableListRef,
    scrollOffsetState,
    timelineWidth,
    itemCount: listDescriptor?.length ?? 0,
    listKey,
  });
}

const renderPage = (
  key: number,
  style: any,
  pageMap: Record<number, PaginatedPageFragment>,
  currentPageID: number | undefined,
  notes: Note[] | undefined,
  pageControls: PageControls,
  sources: DocumentTag[],
  isFileProcessor: boolean,
  pageSearchResultsMap: Record<number, SearchMatch[]>,
) => {
  const page = pageMap[key];

  const notesInPage =
    notes?.filter(
      (note) => note.pageNumber === page?.pageNumber && note.documentID === page?.documentID,
    ) ?? [];

  if (!page) {
    return (
      <Box sx={style} id={`page-${key}`}>
        <SmallPageCardSkeleton />
      </Box>
    );
  }
  return (
    <SmallPageCard
      page={page}
      customStyle={style}
      pageControls={pageControls}
      isCurrentPage={page.id === currentPageID}
      noteCount={notesInPage.length}
      isFileProcessor={isFileProcessor}
      sources={sources}
      searchMatches={pageSearchResultsMap[page.id]}
    />
  );
};

const renderDocument = (
  key: string,
  style: any,
  documentsList: Array<DocumentBasicType> | undefined,
  documentDescriptor: any,
) => {
  const item = documentsList?.find((doc) => doc.documentID === key);
  if (!item || !documentDescriptor) {
    return (
      <Box sx={style} id={`document-${key}`}>
        <DocumentCardSkeleton />
      </Box>
    );
  }

  //item.documentID is a string, documentDescriptor.documentID is an array of strings
  //so we need to convert documentDescriptor documentID to a string to compare
  const documentID = item?.documentID;
  const matchingDoc = documentDescriptor.find((doc: any) => String(doc.documentID) === documentID);
  const numberOfPages = matchingDoc?.pages.length ?? item.numberOfPages;
  const firstPageID = matchingDoc ? matchingDoc.pages[0].id : null;

  return (
    <Box sx={style} id={`document-${item.documentID}`}>
      <DocumentCard
        documentID={item.documentID}
        docFileName={item.docFileName}
        numberOfPages={numberOfPages}
        firstPageID={firstPageID}
        documentStatus={item.fileStatus}
      />
    </Box>
  );
};

const useScrollEffect = (
  pageID: string | undefined,
  documentID: string | undefined,
  listDescriptor: any,
  scrollOffsetState: any,
  setScrollOffsetState: any,
  variableListRef: any,
) => {
  useEffect(() => {
    const topOffset = getTopOffset(Number(pageID), listDescriptor);
    if (topOffset === -1 || !documentID || !pageID) {
      return;
    }
    const isSamePosition = scrollOffsetState?.pageID === pageID;

    if (isSamePosition) {
      return;
    }
    setScrollOffsetState({
      topOffset,
      pageID,
    });

    variableListRef.current?.scrollTo({
      top: Math.max(topOffset - 100, 0),
      behavior: 'smooth',
    });
  }, [pageID, listDescriptor, variableListRef]);
};
