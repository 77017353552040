import axios from 'axios';
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { GridSortItem } from '@mui/x-data-grid';
import FeedbackPopup from '../../../components/common/FeedbackPopup';
import Export from '../../../components/icons/Export';
import APIURL from '../../../library/utilities/apiUrl';
import ExportReportDialog from './ExportReportDialog';
import { exportIndex } from '../../../api';
import useFeatureFlags from '../../../config/useFeatureFlags';

type Props = {
  timelineReportId: string;
  caseId: string;
  shouldExcludePreviousEntries: boolean;
  shouldExcludeConfirmedDuplicates: boolean;
  shouldIncludeHiddenPages: boolean;
  shouldIncludeOCR: boolean;
  shouldIncludeTableOfContents: boolean;
  uploadDates?: string[];
  disabled?: boolean;
  disabledMessage?: string;
  reportName?: string;
  areSectionsGrouped: boolean;
};

export default function ExportTimelineReportButton({
  timelineReportId,
  caseId,
  shouldExcludePreviousEntries,
  shouldExcludeConfirmedDuplicates,
  shouldIncludeHiddenPages,
  uploadDates,
  disabled,
  disabledMessage = undefined,
  reportName,
  areSectionsGrouped,
}: Props) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const { data: featureFlags } = useFeatureFlags();

  const queryClient = useQueryClient();

  // TODO: MVP sort order is hardcoded as entryDate descending, but the sort order of the report
  // should be controlled, and passed into the function as an argument
  const onExportTimelineReportClick = async (
    outputTypes: string[],
    shouldIncludeOCR: boolean,
    shouldIncludeTableOfContents: boolean,
    reportName?: string,
  ) => {
    setExportInProgress(true);
    setExportDialogOpen(false);
    outputTypes.forEach(async (outputType: string) => {
      if (outputType === 'docx') {
        await axios
          .post(`${APIURL}case/${caseId}/index-report/${timelineReportId}/docx/download-async`, {
            shouldExcludeConfirmedDuplicates,
            shouldIncludeHiddenPages,
            uploadDates: uploadDates ?? [],
            reportName,
            areSectionsGrouped,
          })
          .then(async (response) => {
            if (response.status === 200) {
              const url = response.data;
              // Only set exportInProgress to false if pdf not also being exported
              if (outputTypes.length === 1 && !outputTypes.includes('pdf')) {
                setExportInProgress(false);
              }
              queryClient.invalidateQueries(['timelineExports', caseId]);
              onDownloadTimeline(url?.download_url);
            }

            if (response.status !== 200) {
              throw new Error('Timeline export failed');
            }
          })
          .catch((error) => {
            setExportInProgress(false);
            toast.error('A problem occurred when trying to export the index report.');
            Sentry.captureException(error);
          });
      } else {
        await exportIndex(
          caseId,
          timelineReportId,
          {
            exportType: outputType,
            shouldExcludePreviousEntries,
            shouldExcludeConfirmedDuplicates,
            shouldIncludeHiddenPages,
            shouldIncludeOCR,
            shouldIncludeTableOfContents,
            uploadDates: uploadDates ?? [],
            reportName,
            areSectionsGrouped,
          },
          featureFlags?.preferOrchestrator,
        )
          .then(async (response) => {
            const isCompleted = false;
            let url = null;
            while (!isCompleted) {
              const { data } = await axios.get(
                `${APIURL}index-report-exports/${response.data.job_id}`,
              );

              if (data.status === 'fulfilled') {
                url = data.download_url;
                break;
              }

              if (data.status === 'failed') {
                throw new Error('Timeline export failed');
              }

              await new Promise((resolve) => setTimeout(resolve, 5000));
            }

            setExportInProgress(false);
            queryClient.invalidateQueries(['timelineExports', caseId]);
            onDownloadTimeline(url);
          })
          .catch((error) => {
            setExportInProgress(false);
            toast.error('A problem occurred when trying to export the index report.');
            Sentry.captureException(error);
          });
      }
    });
  };

  const onDownloadTimeline = async (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <>
      <Tooltip title={disabled && disabledMessage ? disabledMessage : 'Export Index Report'}>
        <span>
          <Button
            id="reports-tab-export-index-report-btn"
            variant="outlined"
            disabled={disabled || exportInProgress}
            onClick={() => setExportDialogOpen(true)}
            sx={{
              ml: 1,
              width: 'fit-content',
              height: 36,
              minWidth: 36,
              padding: 1,
              marginTop: 0.3,
              bgcolor: '#FFFFFF',
            }}
          >
            <Export style={{ marginRight: '0.5rem' }} fill="none" />
            Export
          </Button>
        </span>
      </Tooltip>

      <FeedbackPopup
        text={
          <div>
            <div>Exporting Segment...</div>
          </div>
        }
        severity="info"
        open={isDownloading}
        onClose={() => setIsDownloading(false)}
        verticalLocation="bottom"
        horizontalLocation="center"
      />
      <FeedbackPopup
        text={
          <div>
            <div>Exporting...</div>
            <div style={{ fontSize: '12px' }}>View your downloads in the Exports tab</div>
          </div>
        }
        severity="info"
        icon={<CircularProgress size={24} sx={{ color: 'white', margin: 'auto 15px auto 0px' }} />}
        open={exportInProgress}
        verticalLocation="bottom"
        horizontalLocation="center"
      />

      {exportDialogOpen && (
        <ExportReportDialog
          onClose={() => setExportDialogOpen(false)}
          onSubmit={onExportTimelineReportClick}
          defaultReportName={reportName ?? ''}
        />
      )}
    </>
  );
}
