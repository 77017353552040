import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import apiUrl from '../../library/utilities/apiUrl';
import { useUser } from '../../library/contexts/AuthContext';

export function useIsFileProcessor() {
  const { user } = useUser();

  if (user == null) {
    return null;
  }

  return user.groups.includes('Processors');
}

async function fetchProcessorCases() {
  return (await axios.get(`${apiUrl}fileProcessorCases`)).data;
}
export function useFileProcessorCases() {
  return useQuery(['fileProcessorCases'], fetchProcessorCases);
}

async function fetchFileProcessorUserList() {
  return (await axios.get(`${apiUrl}fileProcessorUserList`)).data;
}

export function useFileProcessorUserList() {
  return useQuery(['fileProcessorUserList'], fetchFileProcessorUserList);
}

function assignProcessor({ caseID, userID }) {
  return axios.post(`${apiUrl}assignProcessorToCase`, {
    caseID,
    userAssigned: userID,
  });
}

export function useAssignProcessor() {
  const queryClient = useQueryClient();

  return useMutation(assignProcessor, {
    onMutate: async ({ caseID, userID }) => {
      await queryClient.cancelQueries(['fileProcessorCases']);
      const previousCases = queryClient.getQueryData(['fileProcessorCases']);
      const newCaseArr = [...previousCases];
      const caseIndex = newCaseArr.findIndex((c) => c.caseID === caseID);
      newCaseArr[caseIndex] = {
        ...newCaseArr[caseIndex],
        processorAssigned: userID,
      };
      queryClient.setQueryData(['fileProcessorCases'], newCaseArr);
    },
    onError: async () => {
      toast.error('A problem occurred while assigning the processor');
      queryClient.invalidateQueries(['fileProcessorCases']);
    },
  });
}
