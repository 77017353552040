import ArrowForwardIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Backdrop, Box, Button, CircularProgress, Collapse } from '@mui/material';
import React, { useRef, useContext, useMemo, useEffect } from 'react';
import { Outlet, useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import ExportProvider from '../../components/ExportProvider';
import WidthCustomizer from '../../components/WidthCustomizer';
import { AppContext } from '../../library/contexts/AppContext';
import useToggle from '../../library/utilities/useToggle';
import useNotesStore from '../Notes/useNotesStore';
import useReports from '../ReportEditor/useReports';
import CaseHeader from '../Timeline/CaseHeader';
import NotesView from '../Timeline/NotesView';
import useDisplayStore from '../Timeline/useDisplayStore';
import { useIsTimelineLoading, useTimelineList } from '../Timeline/useTimeline';
import PDFDisplaySelector from './PDFDisplaySelector';
import FilterController from '../Timeline/FilterController';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useUpdateCaseUserLocationMutation } from '../../__generated__/graphql';
import { useUser } from '../../library/contexts/AuthContext';
import useCase from './useCase';
import { useActivityLog } from '../../components/ActivityTracker/ActivityTracker';

/**
 * Component that controls the mobile or desktop view for the case page
 */
function DocumentReview() {
  const { caseID, pageID } = useParams();
  const [searchParams] = useSearchParams();
  const viewingSingleDocument = searchParams.get('documentID');
  const { data: caseInstance, isLoading: loading, refetch } = useCase({ caseID });

  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline) => timeline.isDefault).id;

  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useUser();
  const logUserActivity = useActivityLog();

  const [updateCaseUserLocation] = useUpdateCaseUserLocationMutation();

  //Return to previously saved case location
  useEffect(() => {
    const previousCaseLocation = caseInstance?.caseUserLocation;
    if (
      previousCaseLocation.pageID &&
      previousCaseLocation.view &&
      !pageID &&
      timelineID &&
      !viewingSingleDocument
    ) {
      const url =
        previousCaseLocation.view === 'timeline'
          ? `timeline/${timelineID}/${previousCaseLocation.timelineEntryID}/${previousCaseLocation.pageID}`
          : `documents/${previousCaseLocation.documentID}/${previousCaseLocation.pageID}`;
      navigate(url);
    }
  }, [caseInstance, timelineID]);

  const pageIdRef = useRef(pageID);
  const documentView = useRef(location.pathname.indexOf('/documents') > -1);

  useEffect(() => {
    pageIdRef.current = pageID;
    documentView.current = location.pathname.indexOf('/documents') > -1;
  }, [pageID, location.pathname.indexOf('/documents')]);

  //On unmount review tab, update case user location
  useEffect(
    () => () => {
      const currentPage = pageIdRef.current;
      const isInDocumentView = documentView.current;
      if (currentPage && userId) {
        updateCaseUserLocation({
          variables: {
            data: {
              caseId: caseID,
              userId,
              pageId: +currentPage,
              view: isInDocumentView ? 'document' : 'timeline',
            },
          },
        }).then(() => {
          refetch();
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (caseID) {
      logUserActivity({
        activity: 'case:review',
        case_id: caseID,
        file_id: searchParams.get('documentID') ?? undefined,
      });
    }
  }, [caseID, searchParams.get('documentID') ?? undefined]);

  const { reportEditorOpen } = useContext(AppContext);
  const timelineWidth = useDisplayStore((state) => state.timelineWidth);
  const notesWidth = useDisplayStore((state) => state.notesWidth);
  const setTimelineWidth = useDisplayStore((state) => state.setTimelineWidth);
  const setNotesWidth = useDisplayStore((state) => state.setNotesWidth);

  const isFileProcessor = useIsFileProcessor();

  const timelineSidebarRef = useRef();
  const notesSidebarRef = useRef();

  const [timelineOpen, toggleTimelineOpen] = useToggle(true);
  const [notesOpen, toggleNotesDrawerOpen] = useToggle(true);

  const notesDrawerOpen = useMemo(
    () => (isFileProcessor ? false : notesOpen),
    [notesOpen, isFileProcessor],
  );

  const containerRef = React.useRef(null);
  const globalLoadingIndicator = useIsTimelineLoading();
  const { isLoading: reportsLoading, isFetching: reportsFetching } = useReports(caseID);

  const notesInsertLoading = useNotesStore((state) => state.notesInsertLoading);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <Backdrop
        open={
          globalLoadingIndicator ||
          notesInsertLoading ||
          reportsLoading ||
          reportsFetching ||
          loading
        }
        style={{ zIndex: '1000', position: 'absolute', opacity: 0.9 }}
      >
        <CircularProgress size="15rem" color="secondary" thickness={4} sx={{ padding: '3.5rem' }} />
      </Backdrop>
      <Box
        id="doc-review-container"
        sx={{
          display: 'flex',
          height: '92.5vh',
          overflowX: !notesDrawerOpen && 'hidden',
          overflowY: !notesDrawerOpen && 'hidden',
        }}
      >
        <FilterController>
          <Box>
            <Collapse in={timelineOpen && !reportEditorOpen} orientation="horizontal">
              <Box
                ref={timelineSidebarRef}
                sx={{
                  width: timelineWidth,
                  display: 'flex',
                  height: '92.5vh',
                }}
              >
                <Box
                  id="timeline-outer-container"
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                  }}
                >
                  <ExportProvider caseID={caseID}>
                    {(onExportTimelineClick, handleIsSegmentDownloading) => (
                      <Box
                        ref={containerRef}
                        id="timeline-list-container"
                        sx={{
                          padding: 0,
                          flex: 1,
                          minHeight: 0,
                          height: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <Outlet
                          context={{
                            handleIsSegmentDownloading,
                            onExportTimelineClick,
                          }}
                        />
                      </Box>
                    )}
                  </ExportProvider>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </FilterController>

        <WidthCustomizer containerRef={timelineSidebarRef} setWidth={setTimelineWidth} />

        <Box sx={{ position: 'relative' }}>
          <Button
            aria-label="Hide Timelines and Documents"
            className="collapse-drawer"
            onClick={toggleTimelineOpen}
            draweropen={!timelineOpen}
            sx={{ borderRadius: 3 }}
          >
            <ArrowForwardIosNewRoundedIcon
              sx={{ color: '#667085', fontSize: 20, rotate: '180deg' }}
            />
          </Button>
        </Box>

        <PDFDisplaySelector />

        <Box sx={{ position: 'relative' }}>
          <Button
            className="collapse-drawer"
            draweropen={notesDrawerOpen}
            aria-label="Hide Notes"
            onClick={toggleNotesDrawerOpen}
            sx={{ borderRadius: 3 }}
          >
            <ArrowForwardIosNewRoundedIcon
              sx={{ color: '#667085', fontSize: 20, rotate: '180deg' }}
            />
          </Button>
        </Box>

        <Box>
          <Collapse in={notesDrawerOpen && !reportEditorOpen} orientation="horizontal">
            <Box
              ref={notesSidebarRef}
              sx={{
                width: notesWidth,
                display: 'flex',
                height: '100vh',
                flex: 1,
                paddingBottom: '5rem',
                backgroundColor: 'selectedGrey.main',
              }}
            >
              <WidthCustomizer
                containerRef={notesSidebarRef}
                dividerPosition="left"
                setWidth={setNotesWidth}
                storageName="notesWidth"
              />
              <Box
                ref={containerRef}
                id="notes-container"
                sx={{
                  padding: 0,
                  flex: 1,
                  width: notesWidth,
                  minHeight: 0,
                  height: '100%',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                }}
              >
                <NotesView containerName="review" />
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

export default DocumentReview;
