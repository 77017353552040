import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetIndexTemplates } from '../hooks/useGetIndexTemplates';
import Loading from '../../../../components/common/Loading';

export default function IndexTemplateList() {
  const { indexTemplates: rows, loading } = useGetIndexTemplates();
  const navigate = useNavigate();
  if (loading) {
    return <Loading />;
  }
  return (
    <table className="sm-table" style={{ marginBottom: '2rem' }}>
      <thead>
        <tr>
          <th>Template Name</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id} onClick={() => navigate(`update/${row.id}`)}>
            <td>{row.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
