import { Dialog } from '@mui/material';
import { useState } from 'react';
import CaseDetails from './CaseDetails';
import CaseDetailsHeader from './CaseDetailsHeader';
import useFeatureFlags from '../../config/useFeatureFlags';
import useCreateCase from '../Case/gql/useCreateCase';
import { useUser } from '../../library/contexts/AuthContext';
/**
 * Component for handling the creation of a new case
 */
function CreateCase({ open, onClose }) {
  const { data: { FileProcessingEnabled = false } = {} } = useFeatureFlags();
  const [createCase] = useCreateCase();
  const { user } = useUser();
  const [fileUploading, setFileUploading] = useState(false);

  const userEmail = user?.email;

  async function handleCaseCreation(caseDetails) {
    setFileUploading(true);
    const newCase = await createCase({
      case_name: caseDetails.caseName,
      claimReason: caseDetails.claimReason || null,
      fullName: caseDetails.patientName || null,
      dateOfBirth: caseDetails.DOB || null,
      dateOfInjury: caseDetails.DOI || null,
      dueDate: caseDetails.dueDate || null,
      userIds: caseDetails.userIds,
      archived_at: null,
      archived_by: null,
      ref_id: caseDetails.ref_id || null,
      version: caseDetails?.version ?? 1,
      case_type_id: caseDetails.case_type_id ?? null,
    });
    setFileUploading(false);
    onClose(newCase);
  }

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-labelledby="dialogTitle"
      open={open}
      maxWidth="sm"
      fullWidth={true}
      sx={{ justifyContent: 'center' }}
      PaperProps={{
        sx: { height: '95%', maxHeight: '40rem', width: '60%' },
      }}
    >
      <CaseDetailsHeader onClose={onClose} />
      <CaseDetails
        onSubmit={handleCaseCreation}
        fileUploading={fileUploading}
        fileProcessingEnabled={FileProcessingEnabled}
        userEmail={userEmail}
      />
    </Dialog>
  );
}

export default CreateCase;
