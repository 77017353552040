import { Box, Skeleton } from '@mui/material';
import { green } from '@mui/material/colors';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import useDisplayStore from '../../containers/Timeline/useDisplayStore';
import useThumbnails from './useThumbnails';
import LinkWithQuery from '../../containers/Timeline/LinkWithQuery';

export default function Thumbnail({ page, caseID, highlighted, isCurrentPage, searchMatches }) {
  const { isLoading, data: thumbnails } = useThumbnails(caseID);
  const thumbnailSize = useDisplayStore((state) => state.thumbnailSize);
  const { documentID, pageNumber } = page;
  let { pageID: currentPageID, timelineID } = useParams();
  const location = useLocation();

  currentPageID = +currentPageID;
  const [searchParams] = useSearchParams();
  const currentTimelineID = searchParams.get('timelineID') || timelineID;
  const currentDocumentID = searchParams.get('documentID') ?? null;
  return (
    <Box
      sx={[
        { width: 3 * thumbnailSize, height: 4 * thumbnailSize },
        styles.page,
        searchMatches && styles.searchMatch,
        isCurrentPage && styles.selected,
        highlighted && styles.highlighted,
      ]}
    >
      <LinkWithQuery
        timelineID={currentTimelineID}
        documentID={currentDocumentID}
        to={
          location.pathname.indexOf('/timeline') > -1
            ? `${page.entryID}/${page.id}`
            : `${currentPageID ? '../' : ''}${page.documentID}/${page.id}`
        }
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          display: 'block',
          width: '100%',
          height: '100%',
        }}
      >
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={3 * thumbnailSize - 2}
            height={4 * thumbnailSize - 2}
          />
        ) : (
          <img
            style={{ width: '100%', height: '100%' }}
            draggable="false"
            src={thumbnails?.[documentID]?.[pageNumber - 1]}
            alt="thumbnail"
          />
        )}
      </LinkWithQuery>
    </Box>
  );
}

const styles = {
  page: {
    border: 'thin solid #f1f1f1',
    borderRadius: 1,
    overflow: 'hidden',
    '&:hover': {
      border: 'thin solid #e3e3e3',
    },
    cursor: 'pointer',
  },

  bgGreen: {
    backgroundColor: '#d6f5d6',
  },
  selected: {
    border: '2px solid',
    borderColor: 'primary.light',
    '&:hover': {
      border: '2px solid',
      borderColor: 'primary.light',
    },
  },
  highlighted: {
    borderColor: green[300],
    '&:hover': {
      borderColor: green[300],
    },
  },
  searchMatch: {
    border: '2px solid #00DFA2',
  },
};
