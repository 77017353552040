export const isEmpty = (value: unknown) => {
  if (value && typeof value === 'object') {
    return !Object.keys(value).length;
  }

  if (Array.isArray(value)) {
    return !value.length;
  }

  if (typeof value === 'string') {
    return !value.trim();
  }

  return !value;
};

export const isTimelineView = () => window.location.pathname.includes('/timeline');
export const isDocumentsView = () => window.location.pathname.includes('/documents');
