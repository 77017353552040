import React, { useEffect } from 'react';
import { Menu, MenuItem, Box, CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import useSources from '../gql/useSources';
import useUpdateEntryDetails from '../gql/useUpdateEntryDetails';
import { TimelineEntry as TimelineEntryType, Page } from '../types/timelineTypes';
import OverflowText from '../../../components/common/OverflowText';
import { TagDisplayObject } from '../../../__generated__/graphql';
import Theme from '../../../theme';

export default function CategorySelector({ entry, caseID }: Props) {
  const { id: entryID, sourceID } = entry;
  const sources = useSources();
  const { updateEntrySource, sourceState } = useUpdateEntryDetails();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const queryClient = useQueryClient();

  const onItemClick = (source: TagDisplayObject) => {
    updateEntrySource({
      source,
      caseID,
      entryID,
    }).then(() => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === 'getCaseFileDocumentIDs' &&
            query.queryKey[1] === entry.documentID &&
            query.queryKey[2] === false
          );
        },
      });
    });
    setAnchorEl(null);
  };

  const renderSourceList = () => {
    if (!sources || !anchorEl) {
      return null;
    }
    return sources
      .filter((source) => !source.deprecated)
      .map((source) => (
        <MenuItem key={source.tag_id} onClick={() => onItemClick(source)}>
          {source.name}
        </MenuItem>
      ));
  };

  const pageSourceObj = sources && sources.find((source) => source.tag_id === sourceID);

  const openSourceMenu = (e: any) => {
    if (sourceState.loading) {
      return;
    }
    setAnchorEl(e.target);
  };

  return (
    <Box
      onClick={(e) => e.preventDefault()}
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        verticalAlign: 'top',
      }}
    >
      <Box
        onClick={openSourceMenu}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: sourceState.loading ? 'initial' : 'pointer',
        }}
      >
        <OverflowText
          sx={{
            fontSize: '0.85rem',
            fontWeight: 400,
            maxWidth: '75%',
            display: 'flex',
            border: '1px solid',
            borderColor: Theme.palette.borderGrey.main,
            borderRadius: '8px',
            padding: '0.05rem 0.4rem',
            '&:hover': {
              color: sourceState.loading ? 'inherit' : 'primary.light',
            },
          }}
          className="unmask"
        >
          {pageSourceObj?.name}
        </OverflowText>
        {sourceState.loading && <CircularProgress size={12} sx={{ ml: 0.5 }} />}
        {/* {isFileProcessor && ( TODO: Display confidence scores with document tags
          <>
            <Divider
              orientation="vertical"
              sx={{
                width: '8px',
                height: '15px',
                marginTop: '0.2rem',
                marginRight: '0.5rem',
              }}
            />
            <Typography
              color="#98A2B3"
              sx={{
                fontSize: '0.75rem',
                fontWeight: 500,
                pt: '0.1rem',
                display: 'flex',
              }}
            >
              {highestScore}
            </Typography>
          </>
        )} */}
      </Box>
      {anchorEl && (
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          {renderSourceList()}
        </Menu>
      )}
    </Box>
  );
}

type Props = {
  entry: TimelineEntryType;
  caseID: string;
};
