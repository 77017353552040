import { confirmUserAttribute, sendUserAttributeVerificationCode } from '@aws-amplify/auth';
import { Box, Button, Typography, Alert } from '@mui/material';
import { useState, JSX } from 'react';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';
import Loading from '../../../components/common/Loading';
import EnterVerificationCode from '../../Auth/MFASetup/EnterVerificationCode';
import { CognitoUser, updatePhoneNumber } from '../../../api';
import BackButton from '../../../components/common/BackButton';

export default function ChangePhoneNumber({
  user,
  back,
  onVerified,
  onComplete,
}: {
  user: CognitoUser;
  back: () => void;
  onVerified: () => void;
  onComplete: () => void;
}): JSX.Element {
  const [errorMessage, setError] = useState('');
  const [page, setPage] = useState(
    !user?.phone_number
      ? 'setPhoneNumber'
      : !user?.phone_number_verified
      ? 'EnterVerificationCode'
      : 'Complete',
  );

  const onAlertClose = () => {
    setError('');
  };
  const goBack = () => {
    if (page === 'EnterVerificationCode') {
      setPage('setPhoneNumber');
    } else {
      back();
    }
  };

  const sendSMSVerificationCode = async (phoneNumber: string) => {
    if (!isValidPhoneNumber(phoneNumber)) {
      setError('Invalid phone number');
      return;
    }

    try {
      await updatePhoneNumber(phoneNumber);
      await sendUserAttributeVerificationCode({ userAttributeKey: 'phone_number' });
      setPage('EnterVerificationCode');
    } catch (err) {
      setError('Error sending verification code');
    }
    return;
  };
  const submitSMSVerificationCode = async (code: string) => {
    try {
      await confirmUserAttribute({ userAttributeKey: 'phone_number', confirmationCode: code });

      if (onVerified) {
        return onVerified();
      }
      setPage('Complete');
    } catch (err) {
      setError('Error verifying phone number');
    }
    return;
  };

  if (page === 'Loading') {
    return <Loading />;
  }
  if (page === 'Complete') {
    return (
      <Box style={{ textAlign: 'center' }}>
        <div style={{ marginBottom: '1rem' }}>Phone number has been successfully changed.</div>
        <Button fullWidth variant="contained" size="large" onClick={onComplete}>
          Back to Settings
        </Button>
      </Box>
    );
  }
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      {page === 'setPhoneNumber' && (
        <SetPhoneNumber back={back} sendSMSVerificationCode={sendSMSVerificationCode} />
      )}
      {page === 'EnterVerificationCode' && (
        <EnterVerificationCode
          back={goBack}
          onSubmit={submitSMSVerificationCode}
          title="Enter the verification code sent to your phone below"
        />
      )}
      {errorMessage !== '' && (
        <Alert severity="error" onClose={onAlertClose} style={{ marginTop: '.5rem' }}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
}

export function SetPhoneNumber({
  back,
  sendSMSVerificationCode,
}: {
  back: () => void;
  sendSMSVerificationCode: (phoneNumber: string) => Promise<void>;
}): JSX.Element {
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  return (
    <Box sx={{ height: '100%' }}>
      {back && <BackButton onClick={back} style={{ float: 'left', marginTop: '-2px' }} />}

      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          marginBottom: '2rem',
        }}
      >
        Enter your phone number
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Input
          country="CA"
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value || '')}
          placeholder="(123) 456-7890"
        />
      </Box>
      <Box sx={{ my: 3 }}>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: 'rgba(0,0,0,0.75)',
          }}
        >
          Tap Next to verify your account with your phone number.
        </Typography>
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => sendSMSVerificationCode(phoneNumber)}
      >
        Next
      </Button>
    </Box>
  );
}
