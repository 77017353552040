import { useQuery } from '@tanstack/react-query';
import { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { toast } from 'react-toastify';
import { getTagInsights } from '../../../api';
import { useTimelineList } from '../../Timeline/useTimeline';
import CaseContext from '../CaseContext';
import './case-insights-container.css';
import useUpdateCaseDetails from '../gql/updateCaseDetails';
import { validateCaseDetails } from '../../MyCases/utils/caseDetailsValidation';
import { formatUTCDateToDateString } from '../../../library/utilities/useDates';
import useDocumentSearchStore, {
  initialDocumentSearchStoreState,
} from '../../Timeline/useDocumentSearchStore';
import useCaseDocumentTagFilters from '../../Timeline/api-queries/useCaseDocumentTagFilters';
import useCaseFiles from '../../Files/useCaseFiles';

type CaseDetails = {
  caseName: string;
  patientName: string;
  dueDate: string;
  caseType: string;
  caseStatus: string;
  dateOfBirth: string;
  dateOfInjury: string;
  claimReason: string;
};

export type CaseDetailsFields =
  | 'caseName'
  | 'patientName'
  | 'dueDate'
  | 'caseType'
  | 'dateOfBirth'
  | 'dateOfInjury'
  | 'claimReason';

export default function useInsights(caseID: string) {
  const { caseInstance } = useContext(CaseContext);
  const navigate = useNavigate();
  const {
    caseName,
    fullName: patientName,
    dueDate,
    caseStatus,
    case_type_name: caseType,
    dateOfBirth,
    dateOfInjury,
    claimReason,
  } = caseInstance;

  const [filters, setFilters] = useDocumentSearchStore(
    (state) => [state.filters, state.setFilters],
    shallow,
  );
  const { data: caseTags } = useCaseDocumentTagFilters({ caseID: caseID! });
  const sourceList = caseTags?.sourceTags!;
  const contentList = caseTags?.contentTags!;

  const { data: caseFiles } = useCaseFiles(caseID ?? '');
  const fileList =
    caseFiles?.map((file) => ({ value: file.documentID, label: file.docFileName })) || [];

  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = useMemo(
    () => timelineList?.find((timeline: any) => timeline.isDefault)?.id,
    [timelineList],
  );

  const [caseDetails, setCaseDetails] = useState<CaseDetails>({
    caseName,
    patientName,
    dueDate: dueDate ? formatUTCDateToDateString(new Date(dueDate)) : '',
    caseStatus,
    caseType,
    dateOfBirth: dateOfBirth ? formatUTCDateToDateString(new Date(dateOfBirth)) : '',
    dateOfInjury: dateOfInjury ? formatUTCDateToDateString(new Date(dateOfInjury)) : '',
    claimReason,
  });
  const [currentEditField, setCurrentEditField] = useState<CaseDetailsFields | null>(null);

  useEffect(() => {
    setCaseDetails({
      caseName,
      patientName,
      dueDate: dueDate ? formatUTCDateToDateString(new Date(dueDate)) : '',
      caseStatus,
      caseType,
      dateOfBirth: dateOfBirth ? formatUTCDateToDateString(new Date(dateOfBirth)) : '',
      dateOfInjury: dateOfInjury ? formatUTCDateToDateString(new Date(dateOfInjury)) : '',
      claimReason,
    });
  }, [caseInstance]);

  const updateCase = useUpdateCaseDetails();

  const handleUpdateCaseDetails = async (field: CaseDetailsFields, value: string) => {
    const updatedDetails = { ...caseDetails, [field]: value };
    if (updatedDetails[field] === caseDetails[field]) {
      setCurrentEditField(null);
      return;
    }
    const [isValid, errorMessage] = validateCaseDetails(
      false,
      updatedDetails.caseName,
      updatedDetails.dueDate,
      updatedDetails.dateOfBirth,
      updatedDetails.dateOfInjury,
    );
    if (!isValid) {
      toast.error(errorMessage);
      setCurrentEditField(null);
      return;
    }
    try {
      await updateCase(caseID, updatedDetails);
      setCaseDetails(updatedDetails);
      toast.success('Case details updated successfully');
    } catch (e) {
      console.error(e);
      toast.error('Error updating case details');
    }
    setCurrentEditField(null);
  };

  const { tagCounts: imeReportSpecialtyInsights, documents: imeReportDocuments } =
    useTagInsightsByCategory(caseID, 175, 3);

  const filterAndViewDocuments = (tagID: number) => {
    setFilters({
      ...filters,
      ...initialDocumentSearchStoreState.filters,
      contentTypes: contentList.filter((content) => content.value === tagID),
      subContentTypes: [],
      sources: sourceList,
      documentID: fileList.map((file) => file.value),
    });
    navigate(
      `/case/${caseID}/timeline/${timelineID}/${imeReportDocuments[0].documentID}/${imeReportDocuments[0].firstPage}`,
    );
  };

  return {
    timelineID,
    handleUpdateCaseDetails,
    caseDetails,
    currentEditField,
    setCurrentEditField,
    imeReportSpecialtyInsights,
    filterAndViewDocuments,
  };
}

function useTagInsightsByCategory(
  caseID: string,
  tagID: number,
  resultCategoryID: number,
): {
  tagCounts: any;
  documents: any;
  isLoading: boolean;
  isError: boolean;
} {
  const { data, isLoading, isError } = useQuery(
    ['tagInsights', caseID, tagID],
    () => getTagInsights(caseID, tagID, resultCategoryID ?? null),
    {
      enabled: !!caseID && !!tagID,
    },
  );
  const tagCounts = data?.data?.tagCounts;
  const documents = data?.data?.documentIDs;
  if (tagCounts) {
    const sortedTags = Object.keys(tagCounts)
      .sort((a, b) => {
        return b === 'Other' ? -1 : tagCounts[b] - tagCounts[a];
      })
      .map((tag) => ({ tag, count: tagCounts[tag] }));
    return { tagCounts: sortedTags, documents, isLoading, isError };
  }

  return { tagCounts: [], documents: [], isLoading, isError };
}
