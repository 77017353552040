import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { useSearchParams } from 'react-router-dom';
import { authenticatedRequest } from '../../../api';
import useDocumentSearchStore from '../../Timeline/useDocumentSearchStore';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import usePDFViewerStore from '../../Case/usePDFViewerStore';
import useTimelineEntries from '../../Timeline/gql/useTimelineEntries';

function updateDocumentRotation(
  caseID: string,
  documentId: bigint,
  rotation: 'clockwise' | 'counterclockwise',
): Promise<AxiosResponse> {
  const res = authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateDocumentRotation/${caseID}/${documentId}`,
    data: { rotation: rotation },
  });
  return res;
}

export function useUpdateDocumentRotation(
  timelineID: string,
  caseID: string,
): (
  timelineID: string,
  caseID: string,
  documentId: bigint,
  pageIDs: number[],
  rotation: 'clockwise' | 'counterclockwise',
) => Promise<void> {
  const [searchParams] = useSearchParams();
  const documentRotation = usePDFViewerStore((state) => state.documentRotation);
  const setDocumentRotation = usePDFViewerStore((state) => state.setDocumentRotation);
  const isFileProcessor = useIsFileProcessor();
  const activeDocumentID = searchParams.get('documentID') ?? undefined;
  const startPageNumber = searchParams.get('startPage') ?? undefined;
  const endPageNumber = searchParams.get('endPage') ?? undefined;

  const [filters, areFiltersUpdating, currentCaseID] = useDocumentSearchStore(
    (state) => [state.filters, state.areFiltersUpdating, state.currentCaseID],
    shallow,
  );

  const timelineEntriesQuery = useTimelineEntries({
    timelineId: timelineID,
    filters,
    shouldSkip: areFiltersUpdating || currentCaseID !== caseID,
    documentID: activeDocumentID ? [activeDocumentID] : undefined,
    startPageNumber,
    endPageNumber,
  });

  return async (timelineID, caseID, documentId, pageIDs, rotation) => {
    try {
      const newRotations = { ...documentRotation };

      pageIDs.forEach((pageID) => {
        const currentRotation = newRotations[pageID] || 0;
        if (rotation === 'clockwise') {
          newRotations[pageID] = (currentRotation + 90) % 360;
        } else {
          newRotations[pageID] = (currentRotation - 90 + 360) % 360;
        }
      });

      setDocumentRotation(newRotations);

      await updateDocumentRotation(caseID, documentId, rotation);

      timelineEntriesQuery.refetch({
        timelineId: timelineID,
        filters,
        shouldSkip: areFiltersUpdating || currentCaseID !== caseID,
        documentID: activeDocumentID ? [activeDocumentID] : undefined,
        startPageNumber,
        endPageNumber,
      });

      if (!isFileProcessor) {
        toast.success('Document rotation saved');
      }
    } catch (error) {
      toast.error('Failed to save document rotation');
    }
  };
}
