import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { authenticatedRequest } from '../../../api';

async function bulkUpdateDocumentHiddenStatus(
  entryIDs: string[],
  isHidden: boolean,
): Promise<void> {
  const res = await authenticatedRequest({
    method: 'POST',
    url: '/timeline-entry/bulkUpdateHiddenStatus',
    data: { entryIDs, hidden: isHidden },
  });
  if (res.status === 201) {
    toast.success('Document hidden status updated successfully');
  } else {
    toast.error('Failed to update document hidden status');
  }
}

export function useBulkUpdateDocumentHiddenStatus(): (
  entryIDs: string[],
  isHidden: boolean,
) => Promise<void> {
  const queryClient = useQueryClient();
  return async (entryIDs, isHidden) => {
    await bulkUpdateDocumentHiddenStatus(entryIDs, isHidden);
    queryClient.invalidateQueries(['pages']);
  };
}
