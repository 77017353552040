import { useQuery } from '@tanstack/react-query';
import { getAnnotations, GetAnnotationsInput } from '../../api';

export const useAnnotations = (params: GetAnnotationsInput, enabled: boolean = true) => {
  return useQuery<Extraction[], Error>(
    ['annotations', params.caseID, params.documentId, params.type],
    async () => {
      const response = await getAnnotations(params);
      return response.data as Extraction[];
    },
    {
      enabled,
    },
  );
};

export type Extraction = {
  created: string;
  extraction_id: number;
  field: string;
  height: null;
  left: null;
  selection_id: number;
  selection_status: string;
  top: null;
  value: string;
  width: null;
  page_id: number;
};
