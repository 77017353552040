import { updatePassword } from '@aws-amplify/auth';
import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import * as Sentry from '@sentry/react';
import Loading from '../../../components/common/Loading';

const changePasswordStyles = {
  passwordText: {
    display: 'inline',
  },
  success: {
    color: 'green',
  },
  error: {
    color: 'red',
  },
  resize: {
    fontSize: 12,
  },
};

export default function ChangePassword({ back }) {
  const numberOfCharactersRequired = Number(process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH) || 8;
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPass2, setNewPass2] = useState('');
  const [error, setError] = useState('');
  const [page, setPage] = useState('Display');
  const [passRestrictions, setPassRestrictions] = useState([false, false, false]);
  async function submitNewPassword() {
    if ((newPass2 !== '' && newPass2 !== newPass) || passRestrictions.includes(false)) {
      return;
    }
    setPage('Loading');

    updatePassword({ oldPassword: oldPass, newPassword: newPass })
      .then(() => {
        setPage('Complete');
      })
      .catch((err) => {
        console.log(err);
        if (err === 'NotAuthorizedException') {
          setError('Old Password is incorrect.');
        } else {
          setError(err.message);
        }
        Sentry.captureException(err);
        setPage('Display');
      });
  }

  const enterNewPassword = (pass) => {
    const restrictions = [false, false, false];
    let uppercase = false;
    let lowercase = false;
    for (let i = 0; i < pass.length; i++) {
      const character = pass.charAt(i);
      if (!isNaN(character)) {
        restrictions[1] = true;
      } else if (character === character.toUpperCase() && character !== character.toLowerCase()) {
        uppercase = true;
      } else if (character === character.toLowerCase() && character !== character.toUpperCase()) {
        lowercase = true;
      }
    }
    if (pass.length >= numberOfCharactersRequired) {
      restrictions[0] = true;
    }
    if (uppercase && lowercase) {
      restrictions[2] = true;
    }
    setPassRestrictions(restrictions);
    setNewPass(pass);
  };
  if (page === 'Loading') {
    return <Loading />;
  }
  if (page === 'Complete') {
    return (
      <Box style={{ textAlign: 'center' }}>
        <div style={{ marginBottom: '1rem' }}>Password has been successfully changed.</div>
        <Button fullWidth variant="contained" size="large" classes="password" onClick={back}>
          Back to Settings
        </Button>
      </Box>
    );
  }
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <form
        noValidate
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: '90%',
        }}
      >
        <TextField
          value={oldPass}
          autoComplete="off"
          style={{ marginBottom: 8, marginTop: 8 }}
          onChange={(event) => setOldPass(event.target.value)}
          label="Current Password"
          type="password"
          className="fs-exclude"
        />
        <TextField
          value={newPass}
          style={{ marginBottom: 8 }}
          autoComplete="new-password"
          onChange={(event) => enterNewPassword(event.target.value)}
          label="New Password"
          type="password"
          className="fs-exclude"
        />
        <TextField
          value={newPass2}
          type="password"
          style={{ marginBottom: 8 }}
          autoComplete="new-password"
          onChange={(event) => setNewPass2(event.target.value)}
          label="Confirm New Password"
          className="fs-exclude"
        />
      </form>
      <Button
        onClick={submitNewPassword}
        style={{ float: 'right', flex: '10%' }}
        classes="password"
        size="large"
        variant="contained"
        disabled={
          newPass === '' ||
          newPass2 === '' ||
          newPass2 !== newPass ||
          passRestrictions.includes(false)
        }
      >
        Submit New Password
      </Button>

      <Box style={{ fontSize: 12, marginTop: 4 }}>
        <Box sx={changePasswordStyles.passwordText}>Your new password must be&nbsp;</Box>
        <Box
          sx={[
            changePasswordStyles.passwordText,
            passRestrictions[0] ? changePasswordStyles.success : changePasswordStyles.error,
          ]}
        >
          at least {numberOfCharactersRequired} characters long
        </Box>
        <Box sx={changePasswordStyles.passwordText}>,&nbsp;</Box>
        <Box
          sx={[
            changePasswordStyles.passwordText,
            passRestrictions[1] ? changePasswordStyles.success : changePasswordStyles.error,
          ]}
        >
          include numbers
        </Box>
        <Box sx={changePasswordStyles.passwordText}>, and&nbsp;</Box>
        <Box
          sx={[
            changePasswordStyles.passwordText,
            passRestrictions[2] ? changePasswordStyles.success : changePasswordStyles.error,
          ]}
        >
          include both uppercase and lowercase letters
        </Box>
        <Box sx={changePasswordStyles.passwordText}>.</Box>
      </Box>
      <Box>
        {newPass !== '' || newPass2 !== '' || newPass2 !== newPass ? (
          <Box sx={changePasswordStyles.error} style={{ fontSize: 12, marginTop: 4 }}>
            New passwords must match
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box>
        {error !== '' ? (
          <Box sx={changePasswordStyles.error} style={{ fontSize: 12, marginTop: 4 }}>
            {error}
          </Box>
        ) : (
          ''
        )}
      </Box>
      <div
        onClick={back}
        role="presentation"
        style={{
          cursor: 'pointer',
          marginTop: '1rem',
          width: 'fit-content',
          margin: '1rem auto 0px auto',
          fontSize: '12px',
          color: '#2B60DE',
        }}
      >
        <ArrowBackIosIcon style={{ fontSize: '16px' }} />
        Back to Settings
      </div>
    </Box>
  );
}
