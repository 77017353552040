import { Button, Box } from '@mui/material';
import {
  useGridApiContext,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import ExpandIndexRow from '../../../../components/icons/ExpandIndexRow';
import CollapseIndexRow from '../../../../components/icons/CollapseIndexRow';

export default function ExpandRowsCell(
  props: GridRenderCellParams & {
    closedRowIds: string[];
    setClosedRowIds: (ids: string[]) => void;
  },
) {
  const { id, field, rowNode, closedRowIds, setClosedRowIds } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    if (!rowNode.childrenExpanded) {
      setClosedRowIds(closedRowIds.filter((rowId) => rowId !== id));
    } else {
      setClosedRowIds([...closedRowIds, id]);
    }
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const isGroupOpen = rowNode.type === 'group' ? rowNode.childrenExpanded : false;
  const ExpandedIcon = isGroupOpen ? ExpandIndexRow : CollapseIndexRow;

  return (
    <Box>
      <div>
        {filteredDescendantCount > 0 && (
          <Button onClick={handleClick} size="small" sx={{ minWidth: 0 }}>
            <ExpandedIcon />
          </Button>
        )}
      </div>
    </Box>
  );
}
