import { useContext, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material';
import { ErrorBoundary, captureException } from '@sentry/react';
import IdleTimer from './components/IdleTimer';
import { UserSessionTracker } from './components/ActivityTracker/ActivityTracker';
import NewSidebar from './components/common/NewSidebar';
import ForgotPassword from './containers/Auth/ForgotPassword';
import ContactRequest from './containers/Auth/ContactRequest';
import Login from './containers/Auth/Login';
import CaseContainer from './containers/Case/CaseContainer';
import MyCases from './containers/MyCases';
import TemplateContainer from './containers/Templates/TemplateContainer';
import AdminContainer from './containers/Admin/AdminContainer';
import { AppContextProvider } from './library/contexts/AppContext';
import { AuthContext } from './library/contexts/AuthContext';
import './resources/styles/App.css';
import Theme from './theme';
import ErrorScreen from './containers/ErrorBundary/ErrorScreen';
import './global.css';
import { SetupFullStory } from './fullstory';
import { useIsFileProcessor } from './containers/AccountSettings/useFileProcessing';

function App() {
  const contentWrap = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f8fafb',
  };
  const isFileProcessor = useIsFileProcessor();

  const onError = (error) => {
    captureException(error);
  };

  return (
    <div style={contentWrap} id="app-content-wrap">
      <ThemeProvider theme={Theme}>
        <ErrorBoundary fallback={<ErrorScreen />} onError={onError}>
          <ToastContainer className="unmask" />
          <Routes>
            <Route path="/login/*" element={<Login />} />
            <Route path="/forgot-password/*" element={<ForgotPassword />} />
            <Route path="/request-demo/*" element={<ContactRequest />} />
            <Route path="/contact-us/*" element={<ContactRequest />} />
            <Route element={<MainLayout />}>
              <Route path="/cases/*" element={<MyCases />} />
              {!isFileProcessor && <Route path="/templates/*" element={<TemplateContainer />} />}
              <Route path="/admin/*" element={<AdminContainer />} />
              <Route path="/case/:caseID/*" element={<CaseContainer />} />
              <Route index element={<MyCases />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
}

function Authenticator({ children }) {
  const { user, getSession } = useContext(AuthContext);
  const [page, setPage] = useState('Loading');
  const location = useLocation();
  if (user) {
    return children;
  }
  getSession()
    .then(() => {
      setPage('main');
    })
    .catch(() => {
      setPage('NoAuth');
    });

  if (page === 'Main') {
    return children;
  }
  if (page === 'NoAuth') {
    return (
      <Navigate
        to={location.pathname !== `/` ? `/login?redirect=${location.pathname}` : '/login'}
      />
    );
  }
  return <div />;
}

function MainLayout() {
  return (
    <Authenticator>
      <UserSessionTracker>
        <CookiesProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <SetupFullStory />
          <IdleTimer />
          <AppContextProvider>
            <DndProvider backend={HTML5Backend}>
              <div id="sidebar-container" style={{ display: 'flex', height: '100%' }}>
                <NewSidebar />
                <div
                  id="app-container"
                  style={{
                    width: '100%',
                    position: 'relative',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    backgroundColor: '#F2F5FC',
                  }}
                >
                  <Outlet />
                </div>
              </div>
            </DndProvider>
          </AppContextProvider>
        </CookiesProvider>
      </UserSessionTracker>
    </Authenticator>
  );
}

export default App;
