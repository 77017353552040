import { Box, Stack, Chip, Divider, Tooltip } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CloseOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import EditContentButton from '../../containers/Timeline/Components/EditContentButton';
import useBulkUpdatePageTags from '../../containers/Timeline/gql/useBulkUpdatePageTags';
import useContentTypesAndSpecialities from '../../containers/Timeline/gql/useContentTypesAndSpecialities';
import { useDocumentTags, useUpdateDocumentTags } from './useTimelineEntryTags';
import Theme from '../../theme';
import { useIsFileProcessor } from '../../containers/AccountSettings/useFileProcessing';
import { useCachedTimelineEntry } from './hooks/useCachedTimelineEntry';
import useCaseFiles from '../../containers/Files/useCaseFiles';

export default function PageContentToolbar({ page, containerName }) {
  const contentTags = useContentTypesAndSpecialities();
  const [bulkUpdatePageTags] = useBulkUpdatePageTags();
  const { data: documentTags } = useDocumentTags(page.entryID);
  const isFileProcessor = useIsFileProcessor();
  const { refreshTimelineEntry } = useCachedTimelineEntry();
  const queryClient = useQueryClient();

  const container = document.getElementById('document-tags-container');
  const availableWidth = (container?.getBoundingClientRect().width ?? 275) - 50;
  const [pageTagsOpen, setPageTagsOpen] = useState(false);
  const [documentTagsOpen, setDocumentTagsOpen] = useState(false);

  const contentAndSpecialitiesLabels = contentTags?.pageTagMap;

  const sortedDocumentTagsForPage = useMemo(() => {
    if (!documentTags) {
      return [];
    }
    const documentTagsList = documentTags.documentTypes
      .concat(documentTags.specialties)
      .map((tag) => {
        return {
          id: tag.tag_id,
          label: tag.name,
          category: tag.category,
          category_id: tag.category_id,
        };
      });
    return [...documentTagsList].sort((a, b) =>
      a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1,
    );
  }, [documentTags]);

  const sortedTagsOfPage = useMemo(() => {
    if (!page.tags) {
      return [];
    }
    const tagList = [...page.tags].sort((a, b) =>
      a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
    );
    return tagList;
  }, [page.tags]);

  const widthForEachDocumentTag = Math.floor(
    availableWidth / (sortedDocumentTagsForPage.length ?? 1),
  );
  const widthForEachPageTag = Math.floor(availableWidth / (sortedTagsOfPage.length ?? 1));

  const pageTagsToShow =
    widthForEachPageTag > 110 ? sortedTagsOfPage.length : Math.floor(availableWidth / 110);
  const documentTagsToShow =
    widthForEachDocumentTag > 110
      ? sortedDocumentTagsForPage.length
      : Math.floor(availableWidth / 110);

  const { mutateAsync: updateDocumentTags } = useUpdateDocumentTags();

  const handleChangeLabel = useCallback(
    (tag, add) => {
      const typeID = tag.id;
      let currentTags = [...page.tags];

      const newTag = contentAndSpecialitiesLabels[typeID];

      if (add) {
        currentTags.push(newTag);
      } else {
        currentTags = currentTags.filter((option) => option.id !== typeID);
      }
      const validTags = currentTags.filter(Boolean);

      bulkUpdatePageTags({
        where: { id: page.id },
        data: {
          tagsIds: validTags.map((option) => option.id),
          shouldInvalidateUnspecifiedTags: !add,
        },
        newPagesState: {
          __typename: 'PageObject',
          id: page.id,
          tags: validTags,
        },
        onCompleted: () => {
          toast.success(
            'Page tags updated successfully.',
            isFileProcessor
              ? { position: 'bottom-right', autoClose: 500 }
              : {
                  autoClose: 500,
                  hideProgressBar: true,
                },
          );
          refreshTimelineEntry();
        },
        onError: () => {
          toast.error('There was a problem updating page tags.');
        },
      });
    },
    [contentAndSpecialitiesLabels, page, bulkUpdatePageTags, refreshTimelineEntry],
  );

  const handleChangeDocumentTypeLabel = useCallback(
    async (tagID, add) => {
      let currentTags = sortedDocumentTagsForPage;
      if (add) {
        currentTags.push(tagID);
      } else {
        currentTags = currentTags.filter((option) => option.id !== tagID.id);
      }
      await updateDocumentTags({
        entryID: page.entryID,
        tags: currentTags.map((tag) => {
          return {
            tag_id: tag.id,
            name: tag.label,
            category: tag.category,
            category_id: tag.category_id,
          };
        }),
        tagType: ['Content', 'Specialist'],
      });
      refreshTimelineEntry();
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === 'getCaseFileDocumentIDs' &&
            query.queryKey[1] === page.documentID &&
            query.queryKey[2] === false
          );
        }});
    },
    [page.entryID, documentTags, refreshTimelineEntry],
  );

  const filteredPageTags = useMemo(
    () => contentTags.pageTagList.filter((tag) => tag.origin !== 'deprecated'),
    [contentTags],
  );

  const filteredDocumentTags = useMemo(() => {
    const activeTags = contentTags.documentTagList.filter((tag) => !tag.deprecated);
    return activeTags.map((tag) => {
      return {
        id: tag.tag_id,
        label: tag.name,
        category: tag.category,
        category_id: tag.category_id,
      };
    });
  }, [contentTags]);

  const currentlySelectedOptions = useMemo(() => {
    if (sortedTagsOfPage == null || contentAndSpecialitiesLabels == null) {
      return [];
    }

    return sortedTagsOfPage.map((item) => contentAndSpecialitiesLabels?.[item.id]);
  }, [sortedTagsOfPage, contentAndSpecialitiesLabels]);

  return (
    <Stack direction="row" sx={{ backgroundColor: 'selectedGrey.main', width: '100%' }}>
      <Box
        id="document-tags-container"
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '45%',
        }}
      >
        <Box
          id={`${containerName}-open-document-tags-btn`}
          sx={{
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => {
            setDocumentTagsOpen(true);
          }}
        >
          <span
            style={{
              backgroundColor: Theme.palette.selectedGrey.main,
              borderColor: Theme.palette.selectedGrey.main,
              padding: '0',
              fontSize: '11px',
              marginLeft: '3px',
            }}
          >
            Document Tags
          </span>
          <Box
            id="pageContentToolbarContainer"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              justifyContent: 'left',
              backgroundColor: 'selectedGrey.main',
              marginLeft: '.3rem',
              lineHeight: '28px',
              position: 'relative',
              width: '100%',
            }}
          >
            {sortedDocumentTagsForPage.slice(0, documentTagsToShow).map((item) => (
              <Tooltip title={item.label}>
                <Chip
                  label={item.label}
                  key={item.id}
                  variant="outlined"
                  classes="pdf-viewer"
                  className="unmask"
                  sx={{
                    borderColor: 'borderGrey.main',
                    maxWidth: `${widthForEachDocumentTag}px`,
                  }}
                  deleteIcon={<CloseOutlined sx={labelStyle.deleteContentIcon} />}
                  onDelete={() => handleChangeDocumentTypeLabel(item, false)}
                />
              </Tooltip>
            ))}
            {sortedDocumentTagsForPage.length > documentTagsToShow && (
              <Chip
                label={`+${sortedDocumentTagsForPage.length - documentTagsToShow}`}
                key={0}
                variant="outlined"
                classes="pdf-viewer"
                className="unmask"
                sx={{
                  borderColor: 'borderGrey.main',
                }}
              />
            )}
            <EditContentButton
              type="Document Type"
              autoCompleteOptions={filteredDocumentTags}
              currentlySelectedOptions={sortedDocumentTagsForPage}
              handleChangeLabel={handleChangeDocumentTypeLabel}
              menuOpen={documentTagsOpen}
              setMenuOpen={setDocumentTagsOpen}
            />
          </Box>
        </Box>
      </Box>
      <div style={{ display: 'flex' }}>
        <Divider orientation="vertical" sx={{ height: '90%', margin: '5px', color: 'red' }} />
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '45%',
        }}
      >
        <Box
          id={`${containerName}-open-page-tags-btn`}
          sx={{
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => setPageTagsOpen(true)}
        >
          <span
            style={{
              backgroundColor: Theme.palette.selectedGrey.main,
              borderColor: Theme.palette.selectedGrey.main,
              padding: '0',
              fontSize: '11px',
              marginLeft: '3px',
            }}
          >
            Page Tags
          </span>
          <Box
            id="pageContentToolbarContainer"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              justifyContent: 'left',
              backgroundColor: 'selectedGrey.main',
              marginLeft: '.3rem',
              lineHeight: '28px',
              position: 'relative',
              width: '100%',
            }}
          >
            {sortedTagsOfPage.slice(0, pageTagsToShow).map((item) => (
              <Tooltip title={item.label}>
                <Chip
                  label={item.label}
                  key={item.id}
                  variant="outlined"
                  classes="pdf-viewer"
                  className="unmask"
                  sx={{
                    borderColor: 'borderGrey.main',
                    maxWidth: `${widthForEachPageTag}px`,
                  }}
                  deleteIcon={<CloseOutlined sx={labelStyle.deleteContentIcon} />}
                  onDelete={() => handleChangeLabel(item, false)}
                />
              </Tooltip>
            ))}
            {sortedTagsOfPage.length > pageTagsToShow && (
              <Chip
                label={`+${sortedTagsOfPage.length - pageTagsToShow}`}
                key={0}
                variant="outlined"
                classes="pdf-viewer"
                className="unmask"
                sx={{
                  borderColor: 'borderGrey.main',
                }}
              />
            )}
            <EditContentButton
              type="Page Tag"
              autoCompleteOptions={filteredPageTags}
              currentlySelectedOptions={currentlySelectedOptions}
              handleChangeLabel={handleChangeLabel}
              menuOpen={pageTagsOpen}
              setMenuOpen={setPageTagsOpen}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}

const labelStyle = {
  deleteContentIcon: {
    '&&': {
      color: '#667085 !important',
      fontSize: '0.6rem !important',
      marginLeft: '-0.2rem',
      fontWeight: 800,
    },
  },
};
