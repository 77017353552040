import { useMemo, useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import ReportEditor from '../ReportEditor';
import DocumentView from '../Documents/DocumentView';
import FilesTab from '../Files/FilesTab';
import Timeline from '../Timeline/index';
import CaseContext from './CaseContext';
import DocumentReview from './DocumentReview';
import Exports from '../Exports';
import useDisplayStore from '../Timeline/useDisplayStore';
import NoCaseFound from './CaseDisplays/NoCaseFound';
import CaseLoading from './CaseDisplays/CaseLoading';
import useCase from './useCase';
import EntitiesTab from '../../components/EntitiesTab';
import GroupingTab from '../DocumentGrouping/GroupingTab';
import DocumentImagesContainer from '../DocumentGrouping/DocumentImagesContainer';
import Tagging from '../DocumentTagging';
import AccessProtected from '../../components/UtilComponents/AccessProtected';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useUserGroup } from '../MyCases/useCases';
import DuplicatesByCaseVersion from '../Routing/DuplicatesByCaseVersion';
import CaseInsightsDashboard from './CaseDetails/CaseInsightsDashboard';
import { useUser } from '../../library/contexts/AuthContext';

function CaseContainer() {
  const params = useParams();
  const { caseID } = params;
  const { isLoading: caseLoading, error, data: caseInstance } = useCase({ caseID });
  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const { user } = useUser();
  const processorOrLabeller = isFileProcessor || userGroup === 'Labeller';
  const isSupportAccount = user?.email === 'support@siftmed.ca';

  const state = useMemo(
    () => ({
      caseInstance,
    }),
    [caseInstance],
  );

  const setWindowSize = useDisplayStore((displayState) => displayState.setWindowSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (caseLoading) {
    return <CaseLoading />;
  }
  if (error || !caseInstance) {
    return <NoCaseFound />;
  }
  return (
    <CaseContext.Provider value={state}>
      <Routes>
        <Route path="duplicates/*" element={<DuplicatesByCaseVersion />} />
        <Route path="reports" element={<ReportEditor />} />
        <Route path="entities" element={<EntitiesTab />} />
        <Route path="exports" element={<Exports />} />
        <Route path="files" element={<FilesTab />} />
        <Route path="files/tagging" element={<Tagging />}>
          <Route path=":documentID" element={<Tagging />} />
        </Route>
        <Route
          path="files/:fileID/grouping"
          element={
            <AccessProtected canAccessResource={processorOrLabeller} redirectPath="../files">
              <GroupingTab />
            </AccessProtected>
          }
        >
          <Route
            path=":pageID"
            element={
              <AccessProtected canAccessResource={processorOrLabeller} redirectPath="../files">
                <GroupingTab />
              </AccessProtected>
            }
          />
        </Route>
        <Route
          path="/images"
          element={
            <AccessProtected canAccessResource={processorOrLabeller} redirectPath="../files">
              <DocumentImagesContainer />
            </AccessProtected>
          }
        />
        <Route
          path="/details"
          element={
            <AccessProtected canAccessResource={isSupportAccount} redirectPath="../files">
              <CaseInsightsDashboard />
            </AccessProtected>
          }
        />
        <Route element={<DocumentReview />}>
          <Route path="timeline" element={<Timeline />} />
          <Route path="timeline/:timelineID" element={<Timeline />}>
            <Route path=":entryID/:pageID" element={<Timeline />} />
          </Route>
          <Route path="documents">
            <Route path=":documentID/:pageID" element={<DocumentView />} />
            <Route path=":documentID" element={<Navigate to="1" />} />
            <Route path="" element={<DocumentView />} />
          </Route>
        </Route>
        <Route index element={<Navigate to="timeline" />} />
      </Routes>
    </CaseContext.Provider>
  );
}

export default CaseContainer;
