import { useState, JSX } from 'react';
import { updateMFAPreference } from '@aws-amplify/auth';
import { Box, Alert, Typography, Button } from '@mui/material';
import MFAVerificationSuccessful from './MFAVerificationSuccessful';
import BackButton from '../../../components/common/BackButton';
import ChangePhoneNumber from '../../AccountSettings/Settings/ChangePhoneNumber';
import { CognitoUser } from '../../../api';

interface SetupMFAUsingSMSProps {
  user: CognitoUser;
  back: () => void;
  onComplete: () => void;
  nextText: string;
}

type PageState = 'changePhoneNumber' | 'MFAsuccess' | 'enableMFA';

export default function SetupMFAUsingSMS({
  back,
  user,
  onComplete,
  nextText,
}: SetupMFAUsingSMSProps): JSX.Element {
  const [page, setPage] = useState<PageState>(
    user.phone_number && user.phone_number_verified ? 'enableMFA' : 'changePhoneNumber',
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  async function setSMSAsMFA(): Promise<void> {
    try {
      updateMFAPreference({ sms: 'ENABLED' });
      setPage('MFAsuccess');
    } catch (err) {
      setErrorMessage('Problem setting phone number as MFA');
      return;
    }
  }
  const onAlertClose = (): void => {
    setErrorMessage('');
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: '80%' }}>
        {page === 'changePhoneNumber' && (
          <ChangePhoneNumber user={user} back={back} onVerified={setSMSAsMFA} />
        )}

        {page === 'enableMFA' && (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Enable MFA for phone number {user.phone_number}?</Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={setSMSAsMFA}
              sx={{ mt: 2 }}
            >
              Confirm
            </Button>
          </Box>
        )}

        {page === 'MFAsuccess' && (
          <MFAVerificationSuccessful nextText={nextText} next={onComplete} />
        )}
      </Box>

      {errorMessage !== '' && (
        <Alert severity="error" onClose={onAlertClose} sx={{ marginTop: '.5rem' }}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
}
