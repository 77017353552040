import { SetStateAction, Dispatch } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import apiUrl from '../../library/utilities/apiUrl';
import { CognitoUser } from '../../api';

type ProgressEvent = {
  lengthComputable: boolean;
  total: number;
  loaded: number;
  target: {
    getResponseHeader: (header: string) => number;
  };
};

async function calcAndSetUploadProgress(
  progressEvent: ProgressEvent,
  fileName: string,
  setUploadProgress: Dispatch<SetStateAction<{ [key: string]: number }>>,
  percentageProgress: number[], // percentageProgress[0] + percentageProgress[1] = total progress when done
) {
  const totalLength = progressEvent.lengthComputable
    ? progressEvent.total
    : progressEvent.target.getResponseHeader('content-length') ||
      progressEvent.target.getResponseHeader('x-decompressed-content-length');

  if (totalLength) {
    setUploadProgress((prev) => ({
      ...prev,
      [fileName]:
        percentageProgress[0] +
        Math.round(
          (progressEvent.loaded * (percentageProgress[1] - percentageProgress[0])) / totalLength,
        ),
    }));
  }
}

async function createTemplate(
  user: CognitoUser,
  templateName: string,
  isPrivate: boolean,
  setUploadProgress?: Dispatch<SetStateAction<{ [key: string]: number }>>,
  fileName?: string,
): Promise<void> {
  return axios
    .post(
      `${apiUrl}createTemplate`,
      {
        templateName: templateName,
        userID: user.sub as string,
        private: isPrivate,
      },
      {
        onUploadProgress: (progressEvent: ProgressEvent) =>
          setUploadProgress !== undefined &&
          fileName !== undefined &&
          calcAndSetUploadProgress(progressEvent, fileName, setUploadProgress, [50, 70]),
      },
    )
    .catch((error: any) => {
      if (error.response?.status === 405) {
        toast.error('Failed to create template. A template already exists with this name.', {
          toastId: 'create-templates',
        });
        if (setUploadProgress !== undefined) {
          setUploadProgress({});
        }
      }
    })
    .then((response) => response?.data);
}

function generateSFDTForm(SFDTString: string, fileName: string, presignedData: any) {
  const form = new FormData();
  Object.keys(presignedData.fields).forEach((key) => {
    form.append(key, presignedData.fields[key]);
  });
  form.append(
    'file',
    new File(
      [new Blob([JSON.stringify(SFDTString)])],
      (fileName.split('.').at(0) || '').concat('.sfdt'),
      {
        lastModified: new Date().getTime(),
        type: 'Sfdt',
      },
    ),
  );
  return form;
}

async function uploadSFDTForm(
  form: FormData,
  presignedData: any,
  fileName: string,
  setUploadProgress?: Dispatch<SetStateAction<{ [key: string]: number }>>,
) {
  return axios.post(presignedData.url, form, {
    transformRequest: (data: any, headers?: any) => {
      // eslint-disable-next-line no-param-reassign
      delete headers?.common?.Authorization;
      return data;
    },

    onUploadProgress: (progressEvent: ProgressEvent) =>
      setUploadProgress !== undefined &&
      calcAndSetUploadProgress(progressEvent, fileName, setUploadProgress, [70, 100]),
  });
}

export async function handleFileUpload(
  user: CognitoUser,
  files: FileList,
  setUploadProgress: Dispatch<SetStateAction<{ [key: string]: number }>>,
  isPrivate: boolean,
  templateName: string,
): Promise<void> {
  const fileName = files[0].name;

  const sfdt = await axios.post(
    `${apiUrl}syncfusionController/Import`,
    {
      fileExtension: fileName.split('.').at(-1)?.toLowerCase(),
      fileBlob: await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }),
    },
    {
      onUploadProgress: (progressEvent: ProgressEvent) =>
        calcAndSetUploadProgress(progressEvent, fileName, setUploadProgress, [0, 50]),
    },
  );

  const presignedData = await createTemplate(
    user,
    templateName,
    isPrivate,
    setUploadProgress,
    fileName,
  );

  const form = generateSFDTForm(sfdt.data, fileName, presignedData);

  await uploadSFDTForm(form, presignedData, fileName, setUploadProgress);
}

export async function handleBlankTemplateUpload(
  user: CognitoUser,
  isPrivate: boolean,
  templateName: string,
): Promise<void> {
  const presignedData = await createTemplate(user, templateName, isPrivate);

  // eslint-disable-next-line global-require
  const form = generateSFDTForm(
    //Blank SFDT base64 string
    JSON.parse(
      `{"sfdt":"UEsDBBQAAAAIAPSNPFc5j09RlwIAAFsIAAAEAAAAc2ZkdLxVTW/bMAz9K4F2DQo7SVPUtw1D0cuKYt1OXQ+yLcVaZFmQlGZpkP8+kpK9Lh8bUmDTQXw2KZKi+KQt62xQrXoRD7IOrAhuJcbMi4oVj1tW0mwlK7bMB8MK9qGrN2zMSsWKbDdmIB6fdk9j1pBNg1NclDRgIw9/7iiEdbSmZsV0fpGBIaDZ9GKS4biaT66n17PLMbNrVszzCVpYCHB1TVC3AAm5AYUBlQOqpYVUQXYiAkodVllD0voohYE8J5AEStSrXopotzAe9v/e8VJVWAGoELsT63u+EGyHJaj6jWZUF5hXNPsQ43IS0r+wIs9ov7CAfVGt8CNwNPrctdyAZ4me32VpYKiUskoSXCDqnSA+5kbyE/+loI0orkdfjaq6Wow+PZDGkOpwDa8ocOWxhBjVVxgfIJxkbA+tTNK5AckBlT4B3gOf6uF5lBoUOR1sqlenSYSoXq7jMS111K4xAfBbO0/RA82AJ3Nwse6DNDFGn3aDZ4g5u8V59sGeZ1+emc/zeebNOebItpL2S411kwZDEoYN8TLWFPl917mWazhy8+P1V+zt2LtEq74H6UPV2A3TKSLsoB7HLp1cQgYUI48xPgrJVzqM7rnjC8dtM7rpTGC/G91urHDQVEskwOlFfWpAtbwPk7aSriraSMLnUDQfKHor9LMIquLAiZXYYygU8jRD81cMPUo4ftT9Xxl6sGSPoG9m5RtZSK8B3oF1WA+3eRttQkzNhl/XLF7HTWijax6ci0WTnrxJQ8/N8GDAmWrYFvu2gnJP2Y7em+qEftbrzd4rJP6J16prbeqgTShTrZo2kqVKgppRc2rlln+XPv7X5AuVmgeF12w4PFpsaTQR/M96yPy4HrNslfnfQWH8BAAA//8DAFBLAQItABQAAAAIAPSNPFc5j09RlwIAAFsIAAAEAAAAAAAAAAAAIAAAAAAAAABzZmR0UEsFBgAAAAABAAEAMgAAALkCAAAAAA=="}`,
    ),
    templateName,
    presignedData,
  );

  await uploadSFDTForm(form, presignedData, templateName);
}
