import { useCallback, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import './document-grouping-controls-toolbar.css';
import IconAttachment from '../icons/IconAttachment';
import IconMerge from '../icons/IconMerge';
import { PageObject } from '../../api';
import {
  GroupingActionOnClicks,
  GroupingActionStatuses,
} from '../../containers/DocumentGrouping/utils/documentState';
import IconSplit from '../icons/IconSplit';

type Props = {
  groupingActionOnClicks: GroupingActionOnClicks;
  page: PageObject; // this is wrong
  getGroupingButtonDisabledStatuses: GroupingActionStatuses;
  pageDepthByPageID: (pageID: string) => number;
};

export default function DocumentGroupingControlsToolbar({
  groupingActionOnClicks,
  page,
  getGroupingButtonDisabledStatuses,
  pageDepthByPageID,
}: Props) {
  const stringPageID = String(page.id);
  const { canSplitOnPage, canMergeOnPage, canAttachOnPage, canDetachOnPage, pageDepthOnPage } =
    useMemo(() => {
      return {
        canSplitOnPage: getGroupingButtonDisabledStatuses.canSplitAt(stringPageID),
        canMergeOnPage: getGroupingButtonDisabledStatuses.canMergeAt(stringPageID),
        canAttachOnPage: getGroupingButtonDisabledStatuses.canAttachAt(stringPageID),
        canDetachOnPage: getGroupingButtonDisabledStatuses.canDetachAt(stringPageID),
        pageDepthOnPage: pageDepthByPageID(stringPageID),
      };
    }, [getGroupingButtonDisabledStatuses, stringPageID]);

  const areAllButtonsInactive = !(
    canAttachOnPage ||
    canDetachOnPage ||
    canMergeOnPage ||
    canSplitOnPage
  );

  const isMergeSplitDisabled = !canSplitOnPage && !canMergeOnPage;
  const isAttachDetachDisabled = !canAttachOnPage && !canDetachOnPage;

  const mergeSplitOnClick = useCallback(() => {
    if (canSplitOnPage) {
      console.log('triggering split');
      groupingActionOnClicks.splitAt(stringPageID);
    } else if (canMergeOnPage) {
      groupingActionOnClicks.mergeAt(stringPageID);
    }
  }, [canMergeOnPage, canSplitOnPage, groupingActionOnClicks, stringPageID]);

  const attachDetachOnClick = useCallback(() => {
    if (canDetachOnPage) {
      groupingActionOnClicks.detachAt(stringPageID);
    } else if (canAttachOnPage) {
      groupingActionOnClicks.attachAt(stringPageID);
    }
  }, [canAttachOnPage, canDetachOnPage, groupingActionOnClicks, stringPageID]);

  return (
    <div className={`sm-grouping-toolbar-container ${areAllButtonsInactive && 'sm-translucent'}`}>
      <Tooltip title={canSplitOnPage ? 'Dismiss Edges' : 'Create Edges'}>
        <span>
          {canMergeOnPage ? (
            <button
              className="sm-button sm-button-primary"
              type="button"
              onClick={mergeSplitOnClick}
              style={{
                width: '300px',
              }}
            >
              <IconMerge className="sm-button-icon" style={{ color: 'white' }} />
              <span>Merge</span>
            </button>
          ) : (
            <button
              className={`sm-button ${isMergeSplitDisabled && 'sm-translucent'}`}
              type="button"
              disabled={isMergeSplitDisabled}
              onClick={mergeSplitOnClick}
              style={{
                width: '300px',
              }}
            >
              <IconSplit className="sm-button-icon" />
              <span>Split</span>
            </button>
          )}
        </span>
      </Tooltip>
      <Tooltip title={canAttachOnPage ? 'Dismiss Edges' : 'Create Edges'}>
        <span>
          {canDetachOnPage ? (
            <button
              className="sm-button sm-button-primary"
              type="button"
              onClick={attachDetachOnClick}
              style={{
                width: '300px',
              }}
            >
              <IconAttachment
                className="sm-button-icon"
                style={{ color: 'white' }}
                transform="rotate(45)"
              />
              <span>End Attachment</span>
            </button>
          ) : (
            <button
              className={`sm-button ${isAttachDetachDisabled && 'sm-translucent'}`}
              type="button"
              disabled={isAttachDetachDisabled}
              onClick={attachDetachOnClick}
              style={{
                width: '300px',
              }}
            >
              <IconAttachment className="sm-button-icon" />
              <span>Start Attachment</span>
            </button>
          )}
        </span>
      </Tooltip>
    </div>
  );
}
