import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Divider,
  Typography,
} from '@mui/material';
import { useContext, useState, KeyboardEvent } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useCookies } from 'react-cookie';
import { getCurrentUser, fetchMFAPreference, SignInOutput } from '@aws-amplify/auth';
import { AuthContext } from '../../library/contexts/AuthContext';
import LoginContainer from './LoginContainer';
import MFASetup from './MFASetup';
import EnterVerificationCode from './MFASetup/EnterVerificationCode';
import SetNewPassword from './SetNewPassword';
import MicrosoftLogo from '../../components/icons/MicrosoftLogo';
import useFeatureFlags from '../../config/useFeatureFlags';
import { updateUserDetails } from '../../api';

type SignInChallenge =
  | SignInOutput['nextStep']['signInStep']
  | 'CUSTOM_CONTINUE_SIGN_IN_WITH_MFA_SELECTION' // User needs to set up MFA
  | null;

const nextStepMFARequired = (
  isSignedIn: boolean,
  nextStep: SignInOutput['nextStep'],
  email: string,
) => {
  return (
    isSignedIn &&
    nextStep.signInStep === 'DONE' &&
    (process.env.REACT_APP_MFA_REQUIRED === 'true' ||
      (process.env.REACT_APP_IS_PRODUCTION === 'true' && email.split('@')[1] === 'siftmed.ca'))
  );
};

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { authenticate, forceChangePassword, confirmSignIn, SSOLogin, setUser } =
    useContext(AuthContext);
  const [nextPage, setNextPage] = useState('');
  const [challenge, setChallenge] = useState<SignInChallenge>(null);
  const [cookies, setCookie] = useCookies(['returning-user']);
  const [searchParams] = useSearchParams();

  const { data: featureFlags } = useFeatureFlags();

  const microsoft_oidc_enabled = featureFlags?.MicrosoftOIDCEnabled;
  function togglePassVisible() {
    setIsPassVisible(!isPassVisible);
  }

  const redirect =
    searchParams.get('redirect')?.charAt(0) === '/'
      ? searchParams.get('redirect')?.slice(1) ?? 'cases'
      : searchParams.get('redirect') ?? 'cases';

  async function onLogin() {
    setCookie('returning-user', 'returningUser', { path: '/' });
    setLoading(true);
    const caseInsensitiveEmail = email.toLowerCase().trim();
    try {
      const { isSignedIn, nextStep }: SignInOutput = await authenticate(
        caseInsensitiveEmail,
        password,
      );

      if (nextStepMFARequired(isSignedIn, nextStep, email)) {
        setChallenge('CUSTOM_CONTINUE_SIGN_IN_WITH_MFA_SELECTION');
        setLoading(false);
        return;
      }
      if (isSignedIn) {
        setNextPage(redirect);
      }
      setChallenge(nextStep.signInStep);
      setLoading(false);
    } catch (error: any) {
      console.log('error signing in', error);
      setErrorMessage('Invalid email or password');
      setLoading(false);
    }
  }

  const setNewPassword = async (password: string) => {
    try {
      const signInResponse = await forceChangePassword(password);
      const { isSignedIn, nextStep }: SignInOutput = signInResponse;
      if (nextStepMFARequired(isSignedIn, nextStep, email)) {
        setChallenge('CUSTOM_CONTINUE_SIGN_IN_WITH_MFA_SELECTION');
        setLoading(false);
        return;
      }

      if (nextStep.signInStep !== 'DONE') {
        setChallenge(signInResponse.signInStep);
        return;
      }
      await updateUserDetails();
      setNextPage(redirect);
    } catch (err) {
      console.error('Error during password reset:', err);
      if (err instanceof Error) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage('An unknown error occurred');
      }
      Sentry.captureException(err);
    }
  };

  const enterVerificationCode = async (code: string) => {
    await confirmSignIn(code, challenge);
    try {
      await updateUserDetails();
    } catch (error) {
      console.error('Failed to update user details:', error);
    }
    try {
      await setNextPage(redirect);
    } catch (error) {
      setErrorMessage('Invalid code entered');
    }
  };

  if (nextPage !== '') {
    return <Navigate to={`/${nextPage}`} />;
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && email.trim() !== '' && password !== '') {
      onLogin();
    } else {
      return false;
    }
  };

  if (challenge === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
    return (
      <LoginContainer>
        <SetNewPassword onSubmit={setNewPassword} />
        <ErrorAlert errorMessage={errorMessage} onClose={() => setErrorMessage('')} />
      </LoginContainer>
    );
  }

  if (
    challenge === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE' ||
    challenge === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP' ||
    challenge === 'CONFIRM_SIGN_IN_WITH_SMS_CODE' ||
    challenge === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE'
  ) {
    return (
      <LoginContainer>
        <EnterVerificationCode
          onSubmit={enterVerificationCode}
          title={
            challenge === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE' ||
            challenge === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP'
              ? 'Enter the code on your authenticator app below'
              : 'Enter the code sent to your phone below'
          }
          length={6}
          back={() => setChallenge(null)}
        />
        <ErrorAlert errorMessage={errorMessage} onClose={() => setErrorMessage('')} />
      </LoginContainer>
    );
  }
  if (challenge === 'CUSTOM_CONTINUE_SIGN_IN_WITH_MFA_SELECTION') {
    return (
      <LoginContainer>
        <MFASetup
          onComplete={() => setChallenge(null)}
          location="login"
          back={() => setChallenge(null)}
          email={email.trim()}
        />
      </LoginContainer>
    );
  }

  return (
    <LoginContainer>
      {!loading ? (
        <>
          <Box
            sx={{
              textAlign: 'center',
              marginBottom: '.5rem',
            }}
          >
            <Typography sx={{ fontWeight: 700 }}>
              {cookies['returning-user'] ? 'Welcome back!' : "We're so glad to have you on board!"}
            </Typography>
            <Typography variant="loginScreenBody">Sign in below to get started</Typography>
          </Box>
          <br />
          <TextField
            id="email"
            name="login"
            value={email || ''}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Email"
            inputProps={{ 'data-testid': 'email' }}
            onKeyPress={handleKeyPress}
            className="fs-exclude"
          />
          <Box
            sx={{
              position: 'relative',
              display: 'inline-block',
              margin: '12',
              padding: '0',
            }}
          >
            <TextField
              id="password"
              name="login"
              value={password || ''}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
              inputProps={{ role: 'textbox', 'data-testid': 'password' }}
              type={isPassVisible ? 'text' : 'password'}
              sx={{
                width: '100%',
                mt: '1.7em',
                mb: 1,
              }}
              onKeyPress={handleKeyPress}
              className="fs-exclude"
            />
            {!isPassVisible ? (
              <Visibility
                sx={{
                  position: 'absolute',
                  top: 32,
                  right: '13px',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                }}
                onClick={togglePassVisible}
              />
            ) : (
              <VisibilityOff
                sx={{
                  position: 'absolute',
                  top: 39,
                  right: '13px',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                }}
                onClick={togglePassVisible}
              />
            )}
          </Box>
          <Box sx={{ width: '100%', textAlign: 'end' }}>
            <Link
              style={{
                textDecoration: 'none',
                fontSize: '0.65rem',
                color: '#254580',
                display: 'inline-block',
                fontWeight: 600,
              }}
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </Box>

          <Button
            id="login-button"
            name="Login"
            size="large"
            variant="contained"
            sx={{ mt: '1rem' }}
            onClick={onLogin}
          >
            Login
          </Button>
          <ErrorAlert errorMessage={errorMessage} onClose={() => setErrorMessage('')} />
          {microsoft_oidc_enabled && (
            <Divider sx={{ mt: '1rem' }}>
              <Typography variant="body2">OR</Typography>
            </Divider>
          )}

          {/* <>
            <Button
              id="sso-button"
              name="Sign in with Google"
              size="small"
              variant="outlined"
              sx={{ mt: '1rem', fontSize: '14px' }}
              startIcon={<GoogleLogo />}
              onClick={() => {
                SSOLogin('Google');
              }}
            >
              Sign in with Google
            </Button>
          </> */}
          {microsoft_oidc_enabled && (
            <>
              <Button
                id="sso-button"
                name="Sign in with Microsoft"
                size="small"
                variant="outlined"
                sx={{ mt: '1rem', fontSize: '14px' }}
                startIcon={<MicrosoftLogo />}
                onClick={() => {
                  SSOLogin('Microsoft');
                }}
              >
                Sign in with Microsoft
              </Button>
            </>
          )}
        </>
      ) : (
        <Box sx={{ margin: 'auto', textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </LoginContainer>
  );
}

function ErrorAlert({ errorMessage, onClose }: { errorMessage: string; onClose: () => void }) {
  return (
    <div style={{ textAlign: 'center', marginTop: errorMessage ? '1rem' : '0' }}>
      {errorMessage !== '' && (
        <Alert severity="error" onClose={onClose}>
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}

export default Login;
