import { useEffect, useMemo, useState, useCallback } from 'react';
import { useFragment_experimental } from '@apollo/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NavBar from '../../components/common/PdfNavigation/NavBar';
import { pageIndexFromListByPageID } from '../../library/utilities/useDocs';
import PageFragment from '../Documents/gql/types/PageFragment';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useFileStatus } from '../Documents/useFileStatus';

export default function DocumentPDFHeader({ documentsList, containerName = '' }) {
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const params = useParams();
  const { documentID, timelineID: currentTimeline } = params;
  const pageID = +params.pageID;
  const isFileProcessor = useIsFileProcessor();

  const [searchParams] = useSearchParams();

  const { data: page } = useFragment_experimental({
    from: { id: pageID, __typename: 'PageObject' },
    fragment: PageFragment,
  });

  const currentDocument = useMemo(() => {
    if (!documentsList || !documentsList.length) {
      return null;
    }
    const docID = String(documentID);
    return documentsList.find((doc) => doc.documentID.includes(docID));
  }, [documentsList, documentID]);

  const allPages = useMemo(() => {
    return documentsList.flatMap((doc) =>
      doc.pages.map((page) => ({ ...page, documentID: doc.documentID })),
    );
  }, [documentsList]);

  const currentFileStatus = useFileStatus();
  const showContentToolbar = currentFileStatus === 'PROCESSED' || isFileProcessor;

  const navigate = useNavigate();
  const timelineID = searchParams.get('timelineID');
  const currentDocumentID = searchParams.get('documentID');

  const currentPageIndex = useMemo(
    () => pageIndexFromListByPageID(allPages ?? [], pageID),
    [allPages, pageID],
  );

  const getTimelineAndDocumentQueryParams = (timelineID, documentID) => {
    if (!timelineID && !documentID) {
      return '';
    }
    if (timelineID && documentID) {
      return `?timelineID=${timelineID}&documentID=${documentID}`;
    }
    return timelineID ? `?timelineID=${timelineID}` : `?documentID=${documentID}`;
  };

  const onChangePage = async (index, isCustom = false) => {
    if (isCustom) {
      const targetPage = allPages[index - 1];
      if (currentTimeline) {
        navigate(
          `../timeline/${currentTimeline}/${targetPage.entryID}/${targetPage.id}${
            currentDocumentID ? `?documentID=${currentDocumentID}` : ''
          }`,
        );
      } else {
        navigate(
          `../documents/${targetPage.documentID}/${
            targetPage.id
          }/${getTimelineAndDocumentQueryParams(timelineID, currentDocumentID)}`,
        );
      }
    } else {
      const p = allPages[index > currentPageIndex ? currentPageIndex + 1 : currentPageIndex - 1];
      if (currentTimeline) {
        navigate(
          `../timeline/${currentTimeline}/${p.entryID}/${p.id}${
            currentDocumentID ? `?documentID=${currentDocumentID}` : ''
          }`,
        );
      } else {
        navigate(
          `../documents/${p.documentID}/${p.id}/${getTimelineAndDocumentQueryParams(
            timelineID,
            currentDocumentID,
          )}`,
        );
      }
    }
  };

  useEffect(() => {
    if (!currentDocument || !documentsList.length) {
      return;
    }

    const currentDocumentIndex = documentsList.findIndex((doc) =>
      doc.documentID.includes(String(currentDocument.documentID)),
    );

    setCurrentDocumentIndex(currentDocumentIndex);
  }, [currentDocument, documentsList]);

  function changeDocument(next) {
    if (next && documentsList?.length && currentDocumentIndex < documentsList.length - 1) {
      const nextDocument = documentsList[currentDocumentIndex + 1];
      const nextDocumentFirstPage = nextDocument.pages[0];
      navigate(
        `../documents/${nextDocument.documentID}/${
          nextDocumentFirstPage.id
        }/${getTimelineAndDocumentQueryParams(timelineID, currentDocumentID)}`,
      );
    } else if (!next && currentDocumentIndex > 0) {
      const previousDocument = documentsList[currentDocumentIndex - 1];
      const previousDocumentFirstPage = previousDocument.pages[0];
      navigate(
        `../documents/${previousDocument.documentID}/${
          previousDocumentFirstPage.id
        }/${getTimelineAndDocumentQueryParams(timelineID, currentDocumentID)}`,
      );
    }
  }

  return (
    <NavBar
      currentPage={
        currentPageIndex >= 0 ? currentPageIndex + 1 : allPages[currentPageIndex]?.pageNumber
      }
      page={page}
      numberOfPages={allPages.length}
      onChangePage={onChangePage}
      onPreviousButtonClick={() => changeDocument(false)}
      onNextButtonClick={() => changeDocument(true)}
      nextButtonDisabled={documentsList && currentDocumentIndex === documentsList.length - 1}
      previousButtonDisabled={currentDocumentIndex === 0}
      showContentToolbar={showContentToolbar}
      showFileProcessorToolbar={false}
      showZoomRotateToolbar={true}
      containerName={containerName}
    />
  );
}
