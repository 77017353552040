import { blue } from '@mui/material/colors';
import { createTheme, darken } from '@mui/material/styles';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';

declare module '@mui/material/styles' {
  interface Palette {
    darkgrey: Palette['primary'];
    navigationGrey: Palette['primary'];
    selectedGrey: Palette['primary'];
    mediumGrey: Palette['primary'];
    newSuccess: Palette['primary'];
    lightSuccess: Palette['primary'];
    darkSecondary: Palette['primary'];
    themePurple: Palette['primary'];
    themeOrange: Palette['primary'];
    themeTeal: Palette['primary'];
    themeDuplicates: Palette['primary'];
    themeDuplicatesDark: Palette['primary'];
    themeNotes: Palette['primary'];
    headerError: Palette['primary'];
    primaryError: Palette['primary'];
    secondaryError: Palette['primary'];
    buttonError: Palette['primary'];
    pageBackground: Palette['primary'];
    borderGrey: Palette['primary'];
    subHeading: Palette['primary'];
    duplicatesByDocumentOrange: Palette['primary'];
    duplicatesByDocumentBlue: Palette['primary'];
    duplicatesByDocumentRed: Palette['primary'];
  }

  interface PaletteOptions {
    darkgrey?: PaletteOptions['primary'];
    navigationGrey?: PaletteOptions['primary'];
    selectedGrey?: PaletteOptions['primary'];
    mediumGrey?: PaletteOptions['primary'];
    newSuccess?: PaletteOptions['primary'];
    lightSuccess?: PaletteOptions['primary'];
    darkSecondary?: PaletteOptions['primary'];
    themePurple?: PaletteOptions['primary'];
    themeTeal?: PaletteOptions['primary'];
    themeDuplicates?: PaletteOptions['primary'];
    themeDuplicatesDark?: PaletteOptions['primary'];
    themeNotes?: PaletteOptions['primary'];
    headerError?: PaletteOptions['primary'];
    primaryError?: PaletteOptions['primary'];
    secondaryError?: PaletteOptions['primary'];
    buttonError?: PaletteOptions['primary'];
    pageBackground?: PaletteOptions['primary'];
    borderGrey?: PaletteOptions['primary'];
    subHeading?: PaletteOptions['primary'];
  }
}

const Theme = createTheme({
  zIndex: { tooltip: 99999 },
  props: {
    MuiLink: {
      underline: 'none',
    },
  },
  palette: {
    text: {
      primary: '#00214A',
      secondary: '#032c54',
    },
    primary: {
      contrastText: '#fff',
      light: '#385D9F',
      main: '#102449',
      dark: '#1E407D',

      subHeading: '#475467',
      border: '#00214733',
      column: '#E5EAF840',

      columnHeader: '#E5EAF840',
    },
    secondary: {
      contrastText: '#fff',
      main: '#00DFA2',
    },
    subHeading: {
      main: '#475467',
    },
    background: {
      default: '#f8fafb',
    },
    pageBackground: {
      main: '#f2f5fc',
    },
    darkgrey: {
      main: 'rgba(0, 33, 71, 0.5)',
      contrastText: '#fff',
    },
    warning: {
      contrastText: '#fff',
      light: '#ffac66',
      main: '#F05353',
      dark: '#cc7934',
    },
    navigationGrey: {
      main: '#E5EAF8',
      contrastText: '#002147',
    },
    info: {
      contrastText: '#fff',
      light: '#67ced4',
      main: '#6687D8',
      dark: '#018b93',
    },
    selectedGrey: {
      light: '#F9FAFB',
      main: '#F9FAFB',
      dark: '#F9FAFB',
      contrastText: '#344054',
    },
    mediumGrey: {
      light: '#AEBBCA',
      main: '#AEBBCA',
      dark: '#AEBBCA',
      contrastText: 'black',
    },
    modernGrey: {
      default: '#697586',
      light: '#697586',
      main: '#697586',
      dark: '#697586',
      contrastText: '#FFF',
    },
    newSuccess: {
      light: '#12B76A',
      main: '#12B76A',
      dark: '#12B76A',
      contrastText: 'white',
    },
    lightSuccess: {
      light: '#A6F4C5',
      main: '#A6F4C5',
      dark: '#A6F4C5',
      contrastText: '#364152',
    },
    status: {
      error: '#D92D20',
      warning: '#F79009',
      success: '#12B76A',
    },
    borderGrey: {
      main: '#D0D5DD',
    },
    //case table status colours
    darkSecondary: {
      contrastText: '#fff',
      main: '#00B886',
    },
    themePurple: {
      main: '#B079D0',
      light: '#D8B7FB',
      dark: '#925FB0',
    },

    //file status
    themeOrange: {
      main: '#DC6803',
      light: '#F79009',
      dark: '#93370D',
      lighter: '#FEFCF5',
    },

    //duplicates
    themeTeal: {
      main: '#6EE4E4',
      light: '#92f7f7',
      dark: '#2A9C9B',
      contrastText: '#00214A',
    },
    themeDuplicates: {
      main: '#f5baba',
      light: '#FCDDDD',
      dark: '#F05353',
      contrastText: '#FFFFFF',
    },
    themeDuplicatesDark: {
      main: '#F05353',
      contrastText: '#FFFFFF',
    },
    themeNotes: {
      main: '#c8d3ea',
      light: '#d4dcec',
      dark: '#254580',
    },
    dropdown: {
      subgroupHeader: '#98A2B3',
    },

    //error boundary colours
    headerError: {
      main: '#101828',
      light: '#101828',
      dark: '#101828',
    },
    primaryError: {
      main: '#344054',
      light: '#344054',
      dark: '#344054',
    },
    secondaryError: {
      main: '#475467',
      light: '#475467',
      dark: '#475467',
    },
    buttonError: {
      main: '#1D2939',
      light: '#1D2939',
      dark: '#1D2939',
    },
    caseStatus: {
      markAsReadyProcessing: '#33DC9C',
      markAsReadyNotProcessing: '#B079D0',
      processorActionButtonBackground: '#e5edf5',
      processorActionButtonText: '#1e407d',
    },
    duplicatesByDocumentOrange: {
      light: '#FFFCF5',
      main: '#B54708',
    },
    duplicatesByDocumentBlue: {
      light: '#E5EDF5',
      main: '#1E407D',
    },
    duplicatesByDocumentRed: {
      light: '#FEF3F2',
      main: '#B42318',
    },
  },
  typography: {
    fontFamily: 'Inter',
    htmlFontSize: 16,
    fontSize: 16,
    color: '#002147',
    fontColor: '#002147',
    fontWeightRegular: 500,
    h1: {
      fontSize: '1.4rem',
      color: '#385D9F',
      display: 'inline-block',
      fontWeight: 700,
      marginTop: '0.5rem',
    },
    subtitle: {
      opacity: '80%',
      fontSize: '0.825rem',
      fontWeight: 350,
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 700,
      marginTop: '0.5rem',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 700,
      color: '#032c54',
    },
    h4: {
      fontSize: '1.05em',
      fontWeight: 700,
      color: '#1d3b61',
    },
    h5: {
      fontSize: '0.85em',
      fontWeight: 700,
      color: '#1d3b61',
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h7: {
      fontSize: '0.775rem',
      fontWeight: 600,
      color: '#98A2B3',
    },
    filterMenuHead: {
      fontSize: '16px',
      lineHeight: '17px',
      fontWeight: 600,
      color: '#101828',
    },
    filterMenuSubHead: {
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#475467',
    },
    body2: {
      fontSize: '0.7rem',
    },
    body3: {
      fontWeight: 600,
      fontSize: '0.9rem',
    },
    pageCardDocName: {
      fontSize: '0.85rem',
      color: '#667085',
    },
    pageCardDocNameSelected: {
      fontSize: '0.85rem',
      color: '#2F5394',
      fontWeight: 600,
    },
    loginScreenBody: {
      fontSize: '0.75rem',
      fontWeight: 500,
      opacity: '65%',
    },
    caseDialogHeader: {
      opacity: '60%',
      fontSize: '0.6rem',
      fontWeight: 500,
    },
    error: {
      color: '#F05353',
      fontSize: '0.8rem',
      textAlign: 'left',
    }, //To do: Add to case creation, update and other components
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 17,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.85rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Plus Jakarta Sans',
          textTransform: 'capitalize',
          boxShadow: 'none',
          borderRadius: 8,
          '&.Mui-disabled': {
            color: 'rgba(0, 33, 71, 0.55)',
            backgroundColor: '#EAECF0',
            borderColor: '#D0D5DD',
          },
          '&:focus': {
            border: 'none !important',
            outline: '0 !important',
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0) !important',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderColor: '#D0D5DD',
          color: '#344054',
          '&:hover': {
            borderColor: '#D0D5DD',
            backgroundColor: '#F9FAFB',
          },
          '&:focus': {
            backgroundColor: '#FFFFFF',
            borderColor: '#D0D5DD',
            color: '#344054',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined', size: 'small' },
          style: {
            fontSize: '0.7rem',
            height: '2.2rem',
            padding: '1rem',
            fontWeight: 100,
            marginRight: '1rem',
          },
        },
        {
          props: { variant: 'contained', size: 'small' },
          style: {
            fontSize: '0.7rem',
            height: '2.2rem',
            padding: '1rem',
            fontWeight: 100,
            backgroundColor: '#2F5394',
          },
        },
        {
          props: { variant: 'outlined', classes: 'primary' },
          style: {
            fontSize: '0.8rem',
          },
        },
        {
          props: { variant: 'contained', classes: 'primary' },
          style: {
            backgroundColor: '#2F5394',
            fontSize: '0.8rem',
          },
        },
        {
          props: { variant: 'contained', size: 'large' },
          style: {
            backgroundColor: '#2F5394',
            fontSize: '0.85rem',
            padding: '0.5rem',
          },
        },
        {
          props: { variant: 'contained', classes: 'duplicates' },
          style: {
            whiteSpace: 'nowrap',
            fontSize: '0.8rem',
          },
        },
        {
          //also used for remove/change user settings
          props: { variant: 'outlined', className: 'confirm-delete' },
          style: {
            borderColor: '#FDA29B',
            color: '#912018',
            fontSize: 14,
            '&:hover': {
              borderColor: '#FDA29B',
              backgroundColor: '#FEF3F2',
            },
          },
        },
        {
          props: { variant: 'contained', size: 'large', classes: 'password' },
          style: {
            fontSize: 16,
            height: '2.4rem',
          },
        },
        {
          props: { className: 'collapse-drawer', draweropen: true },
          style: {
            width: '1.8em',
            minWidth: '3.2em',
            paddingLeft: '2.2em',
            height: '3.2em',
            border: '2px solid #D0D5DD',
            backgroundColor: '#F9FAFB',
            position: 'absolute',
            top: '89.5%',
            clipPath: 'inset(0 0 0 55%)',
            zIndex: 100,
            '&:focus': {
              outline: 'none',
              backgroundColor: '#BBC7E7',
            },
            left: '-1.8em',
            mt: {
              xl: '2.3em',
            },
          },
        },
        {
          props: { className: 'collapse-drawer', draweropen: false },
          style: {
            width: '1.8em',
            minWidth: '3.2em',
            paddingLeft: '2.2em',
            height: '3.2em',
            border: '2px solid #D0D5DD',
            backgroundColor: '#F9FAFB',
            position: 'absolute',
            top: '89.5%',
            clipPath: 'inset(0 0 0 55%)',
            zIndex: 100,
            '&:focus': {
              outline: 'none',
              backgroundColor: '#BBC7E7',
            },
            left: '-1.5rem',
            mt: {
              md: '0.7em',
              lg: '0.7em',
            },
            transform: 'scaleX(-1)',
          },
        },
        {
          props: { classes: 'pre-merge-split' },
          style: {
            fontSize: '0.8rem',
            paddingTop: '0.8rem',
            height: '1.8rem',
            background: 'none',
            color: '#FFFFFF',
            textDecoration: 'underline',
            '&:hover': {
              background: 'none',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            border: 'none !important',
            outline: '0 !important',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          '&:focus': {
            border: 'none !important',
            outline: '0 !important',
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0) !important',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 3,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: '0.3em',
          paddingBottom: '0em',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: '.5em',
          paddingBottom: '.5em',
          fontSize: '0.875rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
        },
      },
      variants: [
        {
          props: { classes: 'case-dialog' },
          style: {
            marginTop: '-0.1rem',
            fontWeight: 700,
            fontSize: '0.6rem',
            marginBottom: 3.5,
            '&.MuiInputLabel-root': {
              color: '#385D9F',
            },
            '&.Mui-focused': {
              marginTop: '-0.3rem',
              marginLeft: '-0.8rem',
              color: '#385D9F',
              fontWeight: 700,
            },
            '&.MuiFormLabel-filled': {
              marginTop: '-0.3rem',
              marginLeft: '-0.8rem',
              color: '#385D9F',
              fontWeight: 700,
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '0.82rem',
          fontColor: '#101828',
          fontWeight: 400,
          borderRadius: '8px',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD !important',
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD !important',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD !important',
          },
          '& .MuiInputLabel-formControl': {
            marginTop: '-6px',
          },
          '& .MuiInputLabel-shrink': {
            display: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline legend': { display: 'none' },
          "input[type='date']::-webkit-calendar-picker-indicator": {
            fontSize: '1.2rem',
            filter:
              'invert(22%) sepia(20%) saturate(830%) hue-rotate(179deg) brightness(92%) contrast(88%)',
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          sx: {
            marginTop: -0.5,
          },
        },
      },
      variants: [
        {
          props: { type: 'date', size: 'small' },
          style: {
            '& .MuiOutlinedInput-root': {
              height: '2.2rem',
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            '& .MuiInputLabel-formControl': {
              marginTop: '0',
            },
          },
        },
        {
          props: { name: 'login' },
          style: {
            '& .MuiOutlinedInput-input': {
              height: '.8rem',
              marginBottom: 6,
            },
          },
        },
        {
          props: { name: 'search' },
          style: {
            width: '100%',
            flex: 85,
            fontColor: '#002147',
            border: 'none',
            '& .MuiInputBase-root': {
              borderRadius: '8px',
            },
            '& .MuiOutlinedInput-notchedOutline legend': { display: 'none' },
            '& .MuiOutlinedInput-root': { backgroundColor: 'white !important' },
            '& .MuiOutlinedInput-notchedOutline': {
              marginTop: 4,
              border: '0.5px solid #D0D5DD !important',
            },
            '& .MuiOutlinedInput-input': {
              height: '.55rem',
              borderRadius: '8px',
            },
            input: {
              backgroundColor: 'white !important',
              '&::placeholder': {
                color: '#002147',
              },
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                backgroundColor: 'white !important',
              },
            },
          },
        },
      ],
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginRight: 12,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '0.82rem',
          fontColor: '#101828',
          fontWeight: 400,
          borderRadius: '8px',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD !important',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD !important',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D0D5DD !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: '2.55rem',
          fontWeight: 500,
          fontSize: '0.9rem',
          paddingRight: 0.5,
          background: 'fff',
        },
        notchedOutline: {
          borderWidth: '0.75px',
          borderColor: '#102449 !important',
        },
        legend: {
          display: 'none',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          strokeLinecap: 'round',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#385D9F',
            fill: '#385D9F',
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
          fill: '#385D9F',
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { classes: 'small-page-card' },
          style: {
            fontSize: '0.75rem',
            height: '1.6rem',
            marginBottom: '0.2rem',
            marginRight: '0.3rem',
            '& .MuiChip-label': {
              paddingLeft: '0.4rem',
              paddingRight: '0.4rem',
            },
          },
        },
        {
          props: { classes: 'pdf-viewer' },
          style: {
            fontSize: '0.65rem',
            height: '1.3rem',
            marginBottom: '0.2rem',
            marginRight: '0.3rem',
            boxSizing: 'border-box',
            '& .MuiChip-label': {
              paddingLeft: '0.4rem',
              paddingRight: '0.4rem',
            },
          },
        },
        {
          props: { classes: 'pdf-viewer', highlighted: true },
          style: {
            borderColor: darken(blue[700], 0.3),
            color: darken(blue[700], 0.3),
            fontWeight: 700,
          },
        },
        {
          props: { isSelected: true },
          style: {
            border: '2px solid',
            fontWeight: 700,
            height: '1.6rem',
            fontSize: '0.75rem',
          },
        },
      ],
      styleOverrides: {
        root: {
          marginTop: '0!important',
          borderRadius: 8,
          fontWeight: 400,
        },
        outlined: {
          marginTop: 0,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        enterDelay: 500,
        enterNextDelay: 500,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
        label: {
          fontSize: '1rem',
          marginLeft: '0.5rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          color: '#E5EAF8',
          '&.Mui-checked': {
            color: '#385D9F',
          },
          '&:hover': {
            color: '#385D9F',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.33rem',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          overflow: 'hidden',
          border: '1px solid #EAECF0',
          color: '#344054',
          fontWeight: 400,
          fontSize: '0.875rem',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)',
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
        },
        columnHeader: {
          backgroundColor: '#E5EAF840',
        },
        columnHeaderTitle: {
          fontWeight: 700,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        // Override styles for the select label
        selectLabel: {
          margin: 'auto',
        },
        displayedRows: {
          margin: 'auto',
        },
      },
    },
  },
});

export default Theme;

export type ThemeType = typeof Theme;
