import { authenticatedRequest } from '../../api';
import { CreateIndexTemplateBody, UpdateIndexTemplateBody } from './types';

export const getIndexTemplateList = async () =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/index-management/template`,
  });

export const getIndexTemplateDetails = async ({ templateID }: { templateID: string }) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/index-management/template/${templateID}`,
  });

export const createNewIndexTemplate = async (
  body: CreateIndexTemplateBody & { shouldGenerateReports: boolean },
) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/index-management/template`,
    data: body,
  });

export const updateIndexTemplate = async (
  templateID: string,
  body: UpdateIndexTemplateBody & { shouldSyncReports: boolean },
) =>
  authenticatedRequest({
    method: 'PATCH',
    url: `/api/v1/index-management/template/${templateID}`,
    data: body,
  });
