import { FullStory, init } from '@fullstory/browser';
import { useUser } from './library/contexts/AuthContext';

export const identifyUser = async (username, givenName, familyName, email) => {
  FullStory('setIdentity', {
    uid: username,
    properties: {
      displayName: `${givenName ?? 'Unknown'} ${familyName ?? 'Unknown'}`,
      email: email,
    },
  });
};

export const initializeFullStory = () => {
  console.log('initializing fullstory');
  init({
    orgId: '165C8J',
  });
};

export const SetupFullStory = ({ children }) => {
  const { user } = useUser();
  if (user) {
    initializeFullStory();
    identifyUser(user.sub, user.given_name, user.family_name, user.email);
  }

  return children;
};
