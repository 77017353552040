import { useEffect, useMemo, useRef, useState } from 'react';
import { DataGridPro, GridSortItem, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, Tooltip } from '@mui/material';
import InlineEditableText from '../../../components/common/InlineEditableText';
import { CustomDataGridProProps } from '../../../components/common/Table/CustomDataGridTypes';
import IconHidden from '../../../components/icons/IconHidden';
import IconVisible from '../../../components/icons/IconVisible';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';

export interface TimelineEntriesSectionProps extends CustomDataGridProProps {
  section: any;
  timelineID: bigint;
  caseID: string;
  rows: any[];
  sortModel: GridSortItem[];
  handleSortChange: (sortField: GridSortItem[], sectionID: string) => void;
  updateIndexReportSectionName: (sectionId: string, newName: string) => void;
  bulkHideDocuments: (selected: any) => void;
}

export default function TimelineEntriesTableSection({
  columns,
  section,
  timelineID,
  caseID,
  rows,
  sortModel,
  handleSortChange,
  updateIndexReportSectionName,
  bulkHideDocuments,
  ...rest
}: TimelineEntriesSectionProps) {
  const isInitialLoad = useRef(true);

  const apiRef = useGridApiRef();
  const [selectedDocuments, setSelectedDocuments] = useState<GridValidRowModel>([]);
  const allSelectedDocumentsHidden = useMemo(
    () => selectedDocuments.every((doc) => doc.is_hidden),
    [selectedDocuments],
  );

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    }
  }, []);

  useEffect(() => {
    const newSelectedDocuments = Array.from(apiRef?.current?.getSelectedRows().values() ?? {});
    setSelectedDocuments(newSelectedDocuments);
  }, [rows]);

  return (
    <Box
      sx={{
        mt: '1rem',
        mb: '2rem',
      }}
    >
      <TimelineEntryTableSectionHeader
        onSubmitUpdatedSectionName={(newName) => updateIndexReportSectionName(section.id, newName)}
        onSubmitHideSelectedDocuments={() => bulkHideDocuments(selectedDocuments)}
        allSelectedDocumentsHidden={allSelectedDocumentsHidden}
        selectedDocuments={selectedDocuments.length}
        sectionName={section?.name}
      />
      <DataGridPro
        columns={columns ?? []}
        {...rest}
        rows={rows}
        apiRef={apiRef}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => handleSortChange(newSortModel, section.id)}
        onRowSelectionModelChange={() => {
          const newSelectedDocuments = Array.from(
            apiRef?.current?.getSelectedRows().values() ?? {},
          );
          setSelectedDocuments(newSelectedDocuments);
        }}
      />
    </Box>
  );
}

type TimelineEntryTableSectionHeaderProps = {
  onSubmitUpdatedSectionName: (newName: string) => void;
  onSubmitHideSelectedDocuments: () => void;
  allSelectedDocumentsHidden: boolean;
  selectedDocuments: number;
  sectionName: string;
};

function TimelineEntryTableSectionHeader({
  onSubmitUpdatedSectionName,
  onSubmitHideSelectedDocuments,
  allSelectedDocumentsHidden,
  selectedDocuments,
  sectionName,
}: TimelineEntryTableSectionHeaderProps) {
  const HideIcon = allSelectedDocumentsHidden ? IconHidden : IconVisible;
  const isFileProcessor = useIsFileProcessor();
  const tooltipTitle = allSelectedDocumentsHidden
    ? 'Show Selected Documents'
    : 'Hide Selected Documents';
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        mx: '1rem',
      }}
    >
      <InlineEditableText
        value={sectionName ?? ''}
        onSubmit={onSubmitUpdatedSectionName}
        typographyProps={{
          fontSize: '12px',
          fontWeight: 600,
        }}
        boxProps={{
          sx: {
            backgroundColor: '#E5EDF5',
            borderRadius: '8px 8px 0 0 ',
          },
        }}
        typographyBoxProps={{
          sx: {
            borderRadius: '8px 8px 0 0 ',
            px: '0.5rem',
          },
        }}
        textFieldProps={{
          sx: {
            input: {
              fontSize: '12px',
              fontWeight: 600,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            paddingY: '0px',
            pl: '1rem',
          },
        }}
      />
      {!isFileProcessor && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {selectedDocuments > 0 && (
            <>
              <Box
                sx={{
                  paddingRight: '2rem',
                  fontSize: '12px',
                  fontWeight: 600,
                  color: '#385D9F',
                }}
              >
                {`${selectedDocuments} Document${selectedDocuments > 1 ? 's' : ''} Selected`}
              </Box>
              <Box sx={{ paddingRight: '2.5rem', cursor: 'pointer' }}>
                <Tooltip title={tooltipTitle} placement="top">
                  <span style={{ height: 'fit-content' }}>
                    <HideIcon
                      width="1.2rem"
                      height="1.2rem"
                      onClick={onSubmitHideSelectedDocuments}
                    />
                  </span>
                </Tooltip>
              </Box>
            </>
          )}
        </div>
      )}
    </Box>
  );
}
