import { Box, Card, Stack, Typography, Tooltip, Badge } from '@mui/material';
import { useCallback, useMemo, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import IconButtonContainer from '../../components/common/IconButtonContainer';
import PageGridView from './Components/PageGridView';
import PageListView from './Components/PageListView';
import SourceSelector from './Components/SourceSelector';
import DocumentNameDisplay from './Components/DocumentNameDisplay';
import LinkWithQuery from './LinkWithQuery';
import CardMenu from './TimelineViewCardMenu';
import { TimelineEntry as TimelineEntryType } from './types/timelineTypes';
import useDisplayStore from './useDisplayStore';
import downloadSegment from './useSegment';
import { PageControls } from '../Page/types/pageTypes';
import { Note } from '../Notes/types/noteTypes';
import MDocRules from './MDocRules';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import useSources from './gql/useSources';
import DateRangeSelector from './Components/DateRangeSelector';
import DuplicatesByDocument from '../../components/icons/DuplicatesByDocument';
import CaseContext from '../Case/CaseContext';
import Grouping from '../../components/icons/Grouping';
import useCaseFiles from '../Files/useCaseFiles';
import { SearchMatch } from '../../library/utilities/types/search';
import { useUpdateDocumentRotation } from '../ReportEditor/api-queries/useUpdateDocumentRotation';

export default function TimelineEntry({
  entry,
  caseID,
  handleIsSegmentDownloading,
  setShowTimelineUpdated,
  updateTimelineEntry,
  showGroupingButton,
  handleOpenGroupingModal,
  pageControls,
  notes,
  currentDocumentID,
  pageSearchResultsMap,
}: EntryProps) {
  const {
    id: entryID,
    timeline_id: timelineID,
    pages,
    startDate,
    endDate,
    sourceID,
    documentTags,
  } = entry;

  const showThumbnails = useDisplayStore((state) => state.showThumbnails);
  const updateDocumentRotation = useUpdateDocumentRotation();

  const params = useParams();
  const { caseInstance } = useContext(CaseContext);
  const pageID = params.pageID ? +params.pageID : null;
  const currentEntryID = params.entryID ? +params.entryID : null;
  const currentPageID = params.pageID ? Number(params.pageID) : null;

  const isFileProcessor = useIsFileProcessor();
  const caseVersion = caseInstance?.version ?? 1;

  const isDocumentDuplicate = caseVersion === 2 && pages.every((page) => page.isDuplicate);

  const sources = useSources();

  const [disableLink, setDisableLink] = useState(false);

  const { data: caseFiles, isLoading: caseFilesLoading } = useCaseFiles(caseID);
  const getCaseFileIDFromFileName = (fileName: string) => {
    if (!caseFilesLoading) {
      return String(caseFiles?.find((file) => file.docFileName === fileName)?.documentID);
    }
    return '';
  };

  const { count: missingInfoCount, fields: missingFields } = MDocRules({
    entry,
    pages,
    documentTags,
  });

  const { border, borderColor } = getBorderStyles(isFileProcessor, missingInfoCount);

  const tooltipContent = missingFields.join('\n ');

  const handleDownload = useCallback(async () => {
    handleIsSegmentDownloading(true);
    try {
      await downloadSegment(caseID, entryID);
    } catch (err) {
      console.error(err);
      toast.error('Error Downloading Segment...', {
        toastId: 'segment-download',
      });
    }
    handleIsSegmentDownloading(false);
  }, [handleIsSegmentDownloading, caseID, entryID]);

  const handleChangeTimelineEntry = useCallback(
    async (
      newSource: string,
      author: object,
      subject: object,
      organization: object,
      currentPageID: BigInt,
    ) => {
      const input = {
        caseID,
        entryID: entryID,
        sourceID: newSource,
        timelineID: Number(timelineID),
        author,
        subject,
        organization,
        current_page_id: currentPageID,
      };
      try {
        await updateTimelineEntry(input);
        setShowTimelineUpdated(true);
      } catch (e) {
        console.error(e);
        toast.error('Error updating timeline ...', {
          toastId: 'timeline-source-date-change',
        });
      }
    },
    [setShowTimelineUpdated, caseID, entryID, timelineID, updateTimelineEntry],
  );

  const PageListComponent = useMemo(() => (showThumbnails ? PageGridView : PageListView), []);

  const openGroupingPageID = useMemo(() => {
    if (pageID) {
      // If a page is currently open, find it in the entry's pages
      const currentPage = entry.pages.find((page) => page.id === pageID);

      if (currentPage) {
        return currentPage.id;
      }
    }
    return entry.pages[0].id;
  }, [currentPageID, entry.pages, caseID]);

  const rotateDocument = useCallback(
    async (direction: 'clockwise' | 'counterclockwise') => {
      const pageIDs = entry.pages.map((page) => Number(page.id));
      await updateDocumentRotation(
        timelineID?.toString(),
        caseID,
        BigInt(entryID),
        pageIDs,
        direction,
      );
    },
    [entryID, caseID, updateDocumentRotation, timelineID],
  );

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '95%',
        marginLeft: '0.25rem',
        px: '0.75rem',
        py: '0.25rem',
        marginTop: '0.5rem',
        width: '98%',
        border,
        borderColor,
        '&:hover': {
          border,
          borderColor,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          paddingLeft: '0.2rem',
          right: 13,
          top: 13,
          backgroundColor: 'white',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {isDocumentDuplicate ? <DuplicatesByDocument /> : <Box style={{ height: '9.5px' }} />}
        {showGroupingButton && (
          <Tooltip title="Grouping" placement="top">
            <IconButtonContainer
              tooltipText="Grouping"
              tooltipPlacement="top"
              disableClick={false}
              size="small"
              icon={<Grouping />}
              customStyles={{ padding: '4px' }}
              onClick={() => {
                handleOpenGroupingModal(openGroupingPageID);
              }}
            />
          </Tooltip>
        )}
        <Typography
          component="div"
          color="#98A2B3"
          sx={{
            fontSize: '0.75rem',
            fontWeight: 500,
            display: 'inline-block',
          }}
        >
          {pages.length === 1 ? `${pages.length} Page` : `${pages.length} Pages`}
        </Typography>
        <CardMenu
          startDate={startDate}
          endDate={endDate}
          handleDownload={handleDownload}
          entry={entry}
          currentSource={sourceID}
          entryID={entryID}
          handleChangeTimelineEntry={handleChangeTimelineEntry}
          rotateDocument={rotateDocument}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          paddingBottom: 0,
        }}
      >
        <Box>
          <LinkWithQuery
            style={{ textDecoration: 'none', color: '#344054' }}
            documentID={currentDocumentID}
            to={`../timeline/${timelineID}/${entryID}/${pages[0]?.id}`}
            disableLink={disableLink}
          >
            <DateRangeSelector
              entryID={entryID}
              entryStartDate={startDate}
              entryEndDate={endDate ?? ''}
              orientation="vertical"
              isPopUp={true}
              shouldOpenByDefault={false}
            />
          </LinkWithQuery>
        </Box>
      </Box>
      <Stack direction="row" sx={{ zIndex: 1001 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <DocumentNameDisplay
            caseID={caseID}
            fileID={getCaseFileIDFromFileName(pages[0]?.documentFileName)}
            entry={entry}
            disableLink={disableLink}
            setDisableLink={setDisableLink}
          />
          <SourceSelector entry={entry} caseID={caseID} />
        </Box>
        {isFileProcessor && missingInfoCount > 0 && (
          <Tooltip title={tooltipContent}>
            <Badge
              badgeContent={missingInfoCount}
              color="warning"
              sx={{
                cursor: 'pointer',
                marginTop: '0.7rem',
                marginRight: '0.2rem',
                fontSize: '4px',
                padding: '0 4px',
                zIndex: 1002,
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          marginLeft: '-1rem',
          paddingBottom: '0.5rem',
          gap: showThumbnails ? '1rem' : '0px',
          paddingX: showThumbnails ? '1rem' : '0rem',
          mt: '.5rem',
        }}
      >
        <PageListComponent
          pages={pages}
          count={pages?.length}
          pageSearchResultsMap={pageSearchResultsMap}
          currentPageID={pageID}
          isCurrentlyFocused={entryID === currentEntryID}
          isInTimelineView={true}
          pageControls={pageControls}
          notes={notes}
          currentDocumentID={currentDocumentID}
          isFileProcessor={isFileProcessor}
          sources={sources}
        />
      </Box>
      {!isDocumentDuplicate && <Box style={{ height: '12.5px' }} />}
    </Card>
  );
}

type EntryProps = {
  entry: TimelineEntryType;
  caseID: string;
  handleIsSegmentDownloading: Function;
  showGroupingButton: boolean;
  handleOpenGroupingModal: (value: any) => void;
  setShowTimelineUpdated: Function;
  updateTimelineEntry: Function;
  pageControls: PageControls;
  notes: Note[];
  currentDocumentID?: string;
  pageSearchResultsMap: Record<string, SearchMatch[]>;
};

export function getBorderStyles(isFileProcessor: boolean, missingInfoCount: number) {
  if (isFileProcessor && missingInfoCount > 0) {
    return {
      border: '1px solid',
      borderColor: 'status.warning',
    };
  }
  return {
    border: 'none',
    borderColor: 'none',
  };
}
