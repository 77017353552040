import Box from '@mui/material/Box';
import NewSearchbar from '../../../components/Searchbar/NewSearchBar';
import ConfigurableViewFilter from '../Components/ConfigurableViewFilter';
import { ListPagesSortByEnum } from '../types/timelineTypes';
import SearchBarWithNavigation from '../../../components/Searchbar/SearchBarWithNavigation';
import { PageListValue } from '../../../library/utilities/types/search';

export default function SearchAndFilterBar({
  caseID,
  pageList,
  handleFiltersChange,
  isFileProcessor,
}: SearchAndFilterBarProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '.5rem',
        paddingBottom: '1rem',
      }}
    >
      <SearchBarWithNavigation caseID={caseID} pageList={pageList} type="timeline" />

      {!isFileProcessor && (
        <ConfigurableViewFilter
          onSubmit={handleFiltersChange}
          sortingOptions={[
            {
              value: ListPagesSortByEnum.DateAscending,
              label: 'Date Ascending',
            },
            {
              value: ListPagesSortByEnum.DateDescending,
              label: 'Date Descending',
            },
            { value: ListPagesSortByEnum.Document, label: 'Document' },
          ]}
          defaultSortOrder={ListPagesSortByEnum.DateAscending}
        />
      )}
    </Box>
  );
}
type SearchAndFilterBarProps = {
  caseID: string;
  pageList: PageListValue[];
  handleFiltersChange: (filters: any) => void;
  isFileProcessor: boolean;
};
