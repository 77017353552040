import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function Grouping(props: Props) {
  return (
    <svg width="22" height="23" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7718_2929)">
        <path
          d="M16.7143 4.2169C16.7143 4.2169 16.7143 4.03149 16.4573 3.49126C16.2529 3.06137 15.8708 2.6297 15.4272 2.38757C14.923 2.1123 14.2629 2.1123 12.9428 2.1123H7.91425C6.59413 2.1123 5.93407 2.1123 5.42984 2.38757C4.98632 2.6297 4.62572 3.01605 4.39974 3.49126C4.14282 4.03149 4.14282 4.7387 4.14282 6.15312V14.9082C4.14282 16.3226 4.14282 17.0298 4.39974 17.5701C4.62572 18.0453 5.32139 19.37 6.49997 19.37"
          stroke="rgba(0, 0, 0, 0.54)"
          strokeWidth="1.57143"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0714 6.74229C19.0714 6.74229 19.0714 6.55688 18.8145 6.01665C18.6101 5.58676 18.2279 5.15509 17.7844 4.91296C17.2802 4.6377 16.6201 4.6377 15.3 4.6377H10.2714C8.9513 4.6377 8.29124 4.6377 7.78702 4.91296C7.3435 5.15509 6.9829 5.54144 6.75691 6.01665C6.5 6.55688 6.5 7.26409 6.5 8.67851V17.4336C6.5 18.848 6.5 19.5552 6.75691 20.0955C6.9829 20.5707 7.67857 21.8953 8.85714 21.8953"
          stroke="rgba(0, 0, 0, 0.54)"
          strokeWidth="1.57143"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.7143 14.7396H12M13.5715 18.107H12M18.2857 11.3723H12M21.4286 11.2039V19.959C21.4286 21.3734 21.4286 22.0806 21.1717 22.6209C20.9457 23.0961 20.5851 23.4824 20.1416 23.7246C19.6374 23.9998 18.9773 23.9998 17.6572 23.9998H12.6286C11.3085 23.9998 10.6484 23.9998 10.1442 23.7246C9.70068 23.4824 9.34008 23.0961 9.11409 22.6209C8.85718 22.0806 8.85718 21.3734 8.85718 19.959V11.2039C8.85718 9.78948 8.85718 9.08227 9.11409 8.54204C9.34008 8.06683 9.70068 7.68048 10.1442 7.43835C10.6484 7.16309 11.3085 7.16309 12.6286 7.16309H17.6572C18.9773 7.16309 19.6374 7.16309 20.1416 7.43835C20.5851 7.68048 20.9457 8.06683 21.1717 8.54204C21.4286 9.08227 21.4286 9.78948 21.4286 11.2039Z"
          stroke="rgba(0, 0, 0, 0.54)"
          strokeWidth="1.57143"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7718_2929">
          <rect width="23" height="25.1429" fill="white" transform="translate(0 0.428711)" />
        </clipPath>
      </defs>
    </svg>
  );
}
