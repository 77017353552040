import { Button, TextField, Box, Divider, Typography } from '@mui/material';
import { resetPassword, confirmResetPassword } from '@aws-amplify/auth';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginContainer from './LoginContainer';
import RecoverPassword from './RecoverPassword';

function ForgotPassword() {
  const [action, setAction] = useState('EMAIL'); //EMAIL,CODE,COMPLETE
  const [email, setEmail] = useState('');

  async function sendCode(event) {
    event.preventDefault();
    try {
      const response = await resetPassword({ username: email });
      setAction('CODE');
    } catch (e) {
      console.log(e);
    }
  }

  const tryCodeAgain = () => {
    setEmail('');
    setAction('EMAIL');
  };

  return (
    <LoginContainer>
      {action === 'EMAIL' && (
        <form
          onSubmit={sendCode}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontWeight: 700, marginTop: '1.5rem' }}>
              Forgot Your Password?
            </Typography>
            <Typography variant="loginScreenBody">
              Not to worry, we got you! Let's get you a new password.
            </Typography>
          </Box>
          <TextField
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            label="Email"
            sx={{ mt: '3rem' }}
            className="fs-exclude"
          />
          <Button
            name="Send-Recovery-Code"
            sx={{ mt: '1rem' }}
            type="submit"
            variant="contained"
            size="large"
          >
            Send Recovery Code
          </Button>
        </form>
      )}
      {action === 'CODE' && (
        <RecoverPassword email={email} setAction={setAction} tryAgain={tryCodeAgain} />
      )}
      {action === 'COMPLETE' && (
        <div style={{ margin: 'auto', textAlign: 'center' }}>
          <div>Your Password has been successfully changed.</div>
        </div>
      )}
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Divider sx={{ marginTop: '2rem' }} />
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button variant="outlined" size="small" sx={{ mt: '2rem' }}>
            <ArrowBackIosIcon
              style={{
                fontSize: '0.7rem',
                color: 'primary.light',
                fontWeight: 700,
                '&:hover': {
                  color: 'primary.dark',
                },
              }}
            />
            Back to Login
          </Button>
        </Link>
      </Box>
    </LoginContainer>
  );
}

export default ForgotPassword;
