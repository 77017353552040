import _ from 'lodash';

export function calculateObjectDifferences<T extends Object>(
  obj1: T,
  obj2: T,
): Partial<Record<keyof T, any>> {
  const updatedFields: Partial<Record<keyof T, any>> = {};

  Object.entries(obj1).forEach(([key, value]) => {
    const compareValue = obj2[key as keyof T];

    if (!_.isEqual(value, compareValue)) {
      updatedFields[key as keyof T] = compareValue;
    }
  });

  return updatedFields;
}
