/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Divider, debounce } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import FillAndCenter from '../../components/common/Base/FillAndCenter';
import Loading from '../../components/common/Loading';
import ErrorPlaceholder from '../../components/common/Placeholders/ErrorPlaceholder';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import { useUserGroup } from '../MyCases/useCases';
import ActionFooter from './TimelineList/ActionFooter';
import SearchAndFilterBar from './TimelineList/SearchAndFilterBar';
import TimelineEntryList, { getHeight } from './TimelineList/TimelineEntryList';
import TimelineHeader from './TimelineList/TimelineHeader';
import useTimelineEntries from './gql/useTimelineEntries';
import { useTimelineEntryDescriptors } from './gql/useTimelineEntryDescriptors';
import useDisplayStore from './useDisplayStore';
import useDocumentSearchStore from './useDocumentSearchStore';
import useUpdateTimelineEntry from './gql/updateTimelineEntry';
import { getQueryParams } from './LinkWithQuery';
import useSearchStore from '../../library/utilities/useSearchStore';

type TimelineListProps = {
  caseID: string;
  handleIsSegmentDownloading: Function;
};

export default function TimelineList({ caseID, handleIsSegmentDownloading }: TimelineListProps) {
  const params = useParams();
  const timelineID = params.timelineID || '-1';
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const showThumbnails = useDisplayStore((state) => state.showThumbnails);
  const thumbnailHeight = useDisplayStore((state) => state.getThumbnailHeight());
  const windowSize = useDisplayStore((state) => state.windowSize);

  const pageSearchResultsMap = useSearchStore((state) => state.pageSearchResultsMap);
  const getThumbnailGridListHeight = useDisplayStore((state) => state.getThumbnailGridListHeight);
  const [scrollOffset, setScrollOffset] = useScrollOffset({
    entryID: null,
    pageID: null,
    topOffset: -1,
  });

  const isFileProcessor = useIsFileProcessor() ?? false;
  const { data: userGroup } = useUserGroup();
  const isLabeller = userGroup === 'Labeller';

  const [updateTimelineEntry, updateEntryLoading] = useUpdateTimelineEntry();

  const listHeight = useMemo(
    () => window.innerHeight - 230 + (isFileProcessor ? 70 : 0),
    [window.innerHeight, isFileProcessor],
  );
  const [filters, setFilters, areFiltersEqual, areFiltersUpdating, currentCaseID] =
    useDocumentSearchStore(
      (state) => [
        state.filters,
        state.setFilters,
        state.areFiltersEqual,
        state.areFiltersUpdating,
        state.currentCaseID,
      ],
      shallow,
    );
  const { documentOrder: segmentOrder } = filters;

  const activeDocumentID = searchParams.get('documentID') ?? undefined;
  const startPageNumber = searchParams.get('startPage') ?? undefined;
  const endPageNumber = searchParams.get('endPage') ?? undefined;

  const timelineEntriesQuery = useTimelineEntries({
    timelineId: timelineID,
    filters,
    shouldSkip: areFiltersUpdating || currentCaseID !== caseID,
    documentID: activeDocumentID ? [activeDocumentID] : undefined,
    startPageNumber,
    endPageNumber,
  });

  const timelineEntryDescriptorsQuery = useTimelineEntryDescriptors({
    timelineID,
    caseID,
    documentID: activeDocumentID ? [activeDocumentID] : undefined,
    startPageNumber: Number(startPageNumber) ?? undefined,
    endPageNumber: Number(endPageNumber) ?? undefined,
  });

  const timelineEntryDescriptors =
    timelineEntryDescriptorsQuery.data?.timelineEntryDescriptors ?? [];
  const timelineEntriesTotalCount = timelineEntryDescriptors.length ?? 0;
  const timelineEntriesDisplayed = timelineEntriesQuery.data?.entriesByOrderInDataset ?? [];

  const handleFiltersChange = useCallback(
    async (formFilters: any) => {
      if (await areFiltersEqual(formFilters)) {
        return;
      }
      timelineEntriesQuery.refetch({
        timelineId: timelineID,
        filters: formFilters,
        documentID: activeDocumentID ? [activeDocumentID] : undefined,
      });
      setFilters(formFilters);
    },
    [timelineEntriesQuery, timelineID, setFilters],
  );

  const handlePaginationChange = useCallback(
    debounce((from, to) => {
      // eslint-disable-next-line no-param-reassign
      to = Math.max(to, from + 10);

      timelineEntriesQuery.fetchMore({
        timelineId: timelineID,
        filters,
        documentID: activeDocumentID ? [activeDocumentID] : undefined,
        startPageNumber: startPageNumber ?? undefined,
        endPageNumber: endPageNumber ?? undefined,
        skip: from,
        take: to - from,
      });
    }, 100),
    [timelineEntriesQuery, timelineID, filters],
  );

  const pageID = params.pageID ? +params.pageID : null;
  const entryID = params.entryID ? +params.entryID : null;

  const timelineEntryIndex = timelineEntriesDisplayed.findIndex(
    (timelineEntry) => timelineEntry?.id === entryID,
  );
  const currentTimelineEntry = timelineEntriesDisplayed[timelineEntryIndex];

  // Navigate to first page if none selected
  useEffect(() => {
    if (!pageID && timelineID && timelineEntriesQuery?.data?.entriesByOrderInDataset) {
      const firstEntryPage = timelineEntriesQuery.data.entriesByOrderInDataset[0]?.pages[0];
      const searchParamsObj = Object.fromEntries(searchParams.entries());
      const searchParamString = getQueryParams(searchParamsObj);
      if (firstEntryPage && firstEntryPage.entryID && firstEntryPage.id) {
        navigate(`${firstEntryPage.entryID}/${firstEntryPage.id}${searchParamString}`);
      }
    }
  }, [pageID, timelineID, timelineEntriesQuery]);

  const [showTimelineUpdated, setShowTimelineUpdated] = useState(false);
  const [bulkDialog, setbulkDialog] = useState(false);

  const [targetEntryID, setTargetEntryID] = useState<number | null>(null);
  const [targetPageID, setTargetPageID] = useState<number | null>(null);

  const outerListRef = useRef<HTMLElement>(null);

  const getTopOffset = useCallback(
    (entryID: number, pageID: number) => {
      const entryIndex = timelineEntryDescriptors.findIndex((entry) =>
        entry.pages.some((page) => page.id === pageID && entry.id === entryID),
      );

      if (entryIndex < 0) {
        return -1;
      }

      return Array.from({ length: entryIndex }).reduce(
        (accumulatedHeight: number, _, currentEntryIndex) =>
          accumulatedHeight +
          getHeight({
            index: currentEntryIndex,
            items: timelineEntryDescriptors,
            showThumbnails,
            getThumbnailGridListHeight,
          }),
        0,
      );
    },
    [getHeight, timelineEntryDescriptors, showThumbnails],
  );

  useEffect(() => {
    if (targetEntryID && targetPageID) {
      const topOffset = getTopOffset(targetEntryID, targetPageID);
      if (topOffset === -1) {
        return;
      }

      setScrollOffset({
        topOffset,
        entryID: targetEntryID,
        pageID: targetPageID,
      });

      outerListRef.current?.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
      setTargetEntryID(null);
      setTargetPageID(null);
    }
  }, [targetEntryID, targetPageID, getTopOffset, setScrollOffset]);

  useEffect(() => {
    if (!entryID || !pageID) {
      return;
    }
    const topOffset = getTopOffset(entryID, pageID);
    if (topOffset === -1) {
      return;
    }
    const isSamePosition = scrollOffset?.topOffset === topOffset;

    if (isSamePosition) {
      return;
    }

    setScrollOffset({
      topOffset,
      entryID,
      pageID,
    });

    outerListRef.current?.scrollTo({
      top: topOffset,
      behavior: 'smooth',
    });
  }, [timelineEntryDescriptors, pageID, entryID, showThumbnails, thumbnailHeight]);

  const handleVariableListLengthScroll = useCallback((value: any) => {
    scrollOffset.topOffset = value.scrollOffset;
  }, []);

  const { onExportTimelineClick }: { onExportTimelineClick: Function } = useOutletContext();

  const exportFilteredSortedTimeline = useCallback(() => {
    const pageIDsArray: number[] = [];

    timelineEntryDescriptors.forEach(
      (entry) => entry.pages?.forEach((page) => pageIDsArray.push(page.id)),
    );

    onExportTimelineClick(timelineID, timelineEntriesDisplayed.length, segmentOrder, pageIDsArray);
  }, [
    timelineEntryDescriptors,
    timelineID,
    timelineEntriesDisplayed,
    segmentOrder,
    onExportTimelineClick,
  ]);

  const listKey = useMemo(
    () =>
      JSON.stringify(timelineEntryDescriptors.length) +
      JSON.stringify(pageSearchResultsMap) +
      JSON.stringify(filters) +
      JSON.stringify(filters?.contentTypes) +
      JSON.stringify(filters?.documentID) +
      JSON.stringify(filters?.sources) +
      JSON.stringify(filters?.subContentTypes) +
      JSON.stringify(windowSize.height) +
      JSON.stringify(startPageNumber) +
      JSON.stringify(endPageNumber),
    [
      timelineEntryDescriptors.length,
      pageSearchResultsMap,
      filters,
      filters?.contentTypes,
      filters?.documentID,
      filters?.sources,
      filters?.subContentTypes,
      windowSize,
      startPageNumber,
      endPageNumber,
    ],
  );
  const showGroupingButton = (isFileProcessor || isLabeller) && activeDocumentID;
  const showError = timelineEntriesQuery.error != null;
  const showLoading =
    timelineEntryDescriptorsQuery.data === undefined ||
    timelineEntriesQuery.data === undefined ||
    (timelineEntriesQuery.loading === true && timelineEntriesDisplayed?.length < 1) ||
    updateEntryLoading?.loading;
  const showNoResults = timelineEntriesDisplayed?.length === 0;

  const timelinePageList = useMemo(
    () =>
      timelineEntryDescriptors.flatMap((entry) =>
        entry.pages.map((page) => ({
          id: page.id as number,
          documentID: page.documentID,
          pageNumber: page.pageNumber as number,
          entryID: entry.id as string,
        })),
      ),
    [timelineEntryDescriptors],
  );

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
        backgroundColor: 'selectedGrey.main',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '.5rem',
          paddingTop: '1rem',
          paddingBottom: '0.2rem',
        }}
      >
        <TimelineHeader
          currentTimelineEntry={currentTimelineEntry}
          timelineEntries={timelineEntriesDisplayed}
          caseID={caseID}
          exportFilteredSortedTimeline={exportFilteredSortedTimeline}
          bulkDialog={bulkDialog}
          setbulkDialog={setbulkDialog}
        />

        <Divider sx={{ marginBottom: '0.5rem', marginTop: '0.8rem' }} />
        <>
          <SearchAndFilterBar
            caseID={caseID}
            pageList={timelinePageList}
            handleFiltersChange={handleFiltersChange}
            isFileProcessor={isFileProcessor}
          />

          <Divider />
        </>
      </Box>
      {showError ? (
        <ErrorPlaceholder text="Failed to load timeline entries :(" />
      ) : showLoading ? (
        <FillAndCenter>
          <Loading text="Loading Timeline..." />
        </FillAndCenter>
      ) : showNoResults ? (
        <FillAndCenter>
          <div>No Entries to display.</div>
          <div>Consider adjusting your filters or search query.</div>
        </FillAndCenter>
      ) : (
        <Box position="relative">
          <MemoizedTimelineEntriesList
            listKey={listKey}
            items={timelineEntriesDisplayed}
            itemDescriptors={timelineEntryDescriptors}
            count={timelineEntriesTotalCount}
            pageSearchResultsMap={pageSearchResultsMap}
            outerRef={outerListRef}
            height={listHeight}
            initialScrollOffset={scrollOffset?.topOffset}
            onScroll={handleVariableListLengthScroll}
            onLoadMoreItems={handlePaginationChange}
            handleIsSegmentDownloading={handleIsSegmentDownloading}
            setShowTimelineUpdated={setShowTimelineUpdated}
            updateTimelineEntry={updateTimelineEntry}
            caseID={caseID}
            currentDocumentID={activeDocumentID}
            showGroupingButton={showGroupingButton}
          />
        </Box>
      )}
      <ActionFooter
        setShowTimelineUpdated={setShowTimelineUpdated}
        showTimelineUpdated={showTimelineUpdated}
      />
    </Box>
  );
}

const MemoizedTimelineEntriesList = memo(
  TimelineEntryList,
  (prevProps, nextProps) =>
    prevProps.listKey === nextProps.listKey && prevProps.items === nextProps.items,
);

type ScrollOffset = {
  entryID: number | null;
  pageID: number | null;
  topOffset: number;
};
const useScrollOffset = (initial: ScrollOffset) => useState<ScrollOffset>(initial);
