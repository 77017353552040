import React from 'react';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import IndexTemplateList from './components/IndexTemplateList';
import AdminHeader from '../AdminHeader';
import PageHeader from '../../../components/common/PageHeader';

export default function IndexManagementContainer() {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: '2rem',
      }}
    >
      <AdminHeader />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '2rem',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      >
        <PageHeader title="Index Template Management" />
        <span style={{ marginTop: '0.5rem' }}>
          <button
            className="sm-button-primary"
            type="button"
            onClick={() => navigate('/admin/index/create')}
          >
            Create New Template
          </button>
        </span>
      </div>
      <IndexTemplateList />
    </Container>
  );
}
