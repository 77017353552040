import { Switch } from '@mui/material';

type Props = {
  indexDetails: {
    name: string;
    sections: string | null;
    exclude_pages: string | null;
    exclude_documents: string | null;
    collapse_documents: string | null;
    should_autogenerate: 0 | 1;
    show_empty_sections: 0 | 1;
    group_attachments: 0 | 1;
  };
  onChanges: {
    name: (arg: string) => void;
    sections: (arg: string) => void;
    exclude_pages: (arg: string) => void;
    exclude_documents: (arg: string) => void;
    collapse_documents: (arg: string) => void;
    should_autogenerate: (arg: 0 | 1) => void;
    group_attachments: (arg: 0 | 1) => void;
    show_empty_sections: (arg: 0 | 1) => void;
  };
  errors: {
    sections: string[] | null;
    exclude_pages: string[] | null;
    exclude_documents: string[] | null;
    collapse_documents: string[] | null;
    name: string[] | null;
  };
};

export default function IndexSchemaForm({ indexDetails, onChanges, errors }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2rem',
        paddingBottom: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <label htmlFor="name" className="sm-inputlabel">
          Name
        </label>
        {errors.name && errors.name.map((error) => <p style={{ color: 'red' }}>{error}</p>)}

        <input
          id="name"
          className="sm-textfield"
          value={indexDetails.name ?? ''}
          onChange={(e) => onChanges.name(e.target.value)}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
        <label className="sm-inputlabel" htmlFor="sections">
          Sections
        </label>
        {errors.sections && errors.sections.map((error) => <p style={{ color: 'red' }}>{error}</p>)}
        <textarea
          className="sm-textfield"
          id="sections"
          rows={10}
          value={indexDetails.sections ?? ''}
          onChange={(e) => onChanges.sections(e.target.value)}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
        <label className="sm-inputlabel" htmlFor="excluded-pages">
          Exclude Pages
        </label>
        {errors.exclude_pages &&
          errors.exclude_pages.map((error) => <p style={{ color: 'red' }}>{error}</p>)}

        <textarea
          className="sm-textfield"
          id="excluded-pages"
          rows={1}
          value={indexDetails.exclude_pages ?? ''}
          onChange={(e) => onChanges.exclude_pages(e.target.value)}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
        <label className="sm-inputlabel" htmlFor="excluded-docs">
          Exclude Documents
        </label>
        {errors.exclude_documents &&
          errors.exclude_documents.map((error) => <p style={{ color: 'red' }}>{error}</p>)}
        <textarea
          className="sm-textfield"
          id="excluded-docs"
          rows={1}
          onChange={(e) => onChanges.exclude_documents(e.target.value)}
          value={indexDetails.exclude_documents ?? ''}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
        <label className="sm-inputlabel" htmlFor="collapsed-docs">
          Collapsed Documents
        </label>
        {errors.collapse_documents &&
          errors.collapse_documents.map((error) => <p style={{ color: 'red' }}>{error}</p>)}
        <textarea
          className="sm-textfield"
          id="collapsed-docs"
          rows={1}
          value={indexDetails.collapse_documents ?? ''}
          onChange={(e) => onChanges.collapse_documents(e.target.value)}
        />
      </div>

      <div
        style={{
          display: 'flex',
          marginTop: '1rem',
          justifyContent: 'space-between',
          width: '50%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <label htmlFor="autogenerate" className="sm-inputlabel">
            Autogenerate Index for New Cases
          </label>
          <Switch
            id="autogenerate"
            checked={indexDetails.should_autogenerate === 1}
            onChange={(e) => onChanges.should_autogenerate(e.target.checked ? 1 : 0)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <label htmlFor="show_empty_sections" className="sm-inputlabel">
            Show Empty Sections
          </label>
          <Switch
            id="show_empty_sections"
            checked={indexDetails.show_empty_sections === 1}
            onChange={(e) => onChanges.show_empty_sections(e.target.checked ? 1 : 0)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <label htmlFor="group_attachments" className="sm-inputlabel">
            Group Attachments
          </label>
          <Switch
            id="group_attachments"
            checked={indexDetails.group_attachments === 1}
            onChange={(e) => onChanges.group_attachments(e.target.checked ? 1 : 0)}
          />
        </div>
      </div>
    </div>
  );
}
