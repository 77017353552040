import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getIndexTemplateDetails, updateIndexTemplate } from '../../../../api/index-management/api';
import { useAsync } from '../../../../hooks/useAsync';
import { UpdateIndexTemplateBody } from '../../../../api/index-management/types';

export function useUpdateIndexTemplate(templateID: string) {
  const [shouldSyncReports, setShouldSyncReports] = useState<boolean>(true);
  const [sectionsError, setSectionsError] = useState<string[] | null>(null);
  const [excludePagesError, setExcludePagesError] = useState<string[] | null>(null);
  const [excludeDocumentsError, setExcludeDocumentsError] = useState<string[] | null>(null);
  const [collapseDocumentsError, setCollapseDocumentsError] = useState<string[] | null>(null);
  const [nameError, setNameError] = useState<string[] | null>(null);

  const [templateDetailsResp, refreshTemplateDetails] = useAsync(
    () => getIndexTemplateDetails({ templateID }),
    [templateID],
  );
  const navigate = useNavigate();

  const templateFormFields = useMemo(() => {
    if (templateDetailsResp.status !== 'resolved') {
      return null;
    }
    return {
      name: templateDetailsResp?.data?.data?.name,
      show_empty_sections: templateDetailsResp?.data?.data?.show_empty_sections,
      exclude_pages: templateDetailsResp?.data?.data?.exclude_pages,
      exclude_documents: templateDetailsResp?.data?.data?.exclude_documents,
      collapse_documents: templateDetailsResp?.data?.data?.collapse_documents,
      group_attachments: templateDetailsResp?.data?.data?.group_attachments,
      should_autogenerate: templateDetailsResp?.data?.data?.should_autogenerate,
      sections: templateDetailsResp?.data?.data?.sections,
    };
  }, [templateDetailsResp]);

  const updateIndexTemplateCallback = useCallback(
    async (values: UpdateIndexTemplateBody) => {
      try {
        await updateIndexTemplate(templateID, {
          ...values,
          shouldSyncReports,
        });
        setSectionsError(null);
        setExcludePagesError(null);
        setExcludeDocumentsError(null);
        setCollapseDocumentsError(null);
        setNameError(null);

        toast.success('Successfully Updated Index Template');
        navigate('/admin/index');
      } catch (error) {
        toast.error('Failed to update index template. Please check error messages and try again.');

        const errorFields = error?.response?.data;

        if (errorFields == null) {
          return;
        }

        if (errorFields.sections) {
          setSectionsError(errorFields.sections);
        }
        if (errorFields.exclude_pages) {
          setExcludePagesError(errorFields.exclude_pages);
        }
        if (errorFields.exclude_documents) {
          setExcludeDocumentsError(errorFields.exclude_documents);
        }
        if (errorFields.collapse_documents) {
          setCollapseDocumentsError(errorFields.collapse_documents);
        }
        if (errorFields.name) {
          setNameError(errorFields.name);
        }
      }
    },
    [shouldSyncReports],
  );

  const errors = useMemo(
    () => ({
      sections: sectionsError,
      exclude_pages: excludePagesError,
      exclude_documents: excludeDocumentsError,
      collapse_documents: collapseDocumentsError,
      name: nameError,
    }),
    [sectionsError, excludePagesError, excludeDocumentsError, collapseDocumentsError, nameError],
  );

  const clearErrors = useCallback(() => {
    setSectionsError(null);
    setExcludePagesError(null);
    setExcludeDocumentsError(null);
    setCollapseDocumentsError(null);
    setNameError(null);
  }, [
    setSectionsError,
    setExcludePagesError,
    setExcludeDocumentsError,
    setCollapseDocumentsError,
    nameError,
  ]);

  return {
    loading: templateDetailsResp.status === 'pending',
    templateDetails: templateFormFields,
    shouldSyncReports,
    setShouldSyncReports,
    updateIndexTemplate: updateIndexTemplateCallback,
    errors,
    clearErrors,
  };
}
