import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { getBulkDocumentPreview, PagePreview } from '../../../../api';
import { useAsync } from '../../../../hooks/useAsync';

type PreviewPagesParams = {
  caseId: string;
  documentIds: string[];
  hideBlanks: boolean;
};

export default function usePreviewPagesNewState({
  caseId,
  documentIds,
  hideBlanks,
}: PreviewPagesParams) {
  const [previewPages, setPreviewPages] = useState<PagePreview[]>([]);
  const [previewPagesMap, setPreviewPagesMap] = useState<
    Record<string, PagePreview['pageDetails']>
  >({});
  const [firstPageByDocument, setFirstPageByDocument] = useState<Record<string, string>>({});
  const [previewPagesResp, refreshPreviewPages] = useAsync(
    () =>
      getBulkDocumentPreview({
        caseId,
        documentIds,
        hideBlanks,
      }),
    [caseId, documentIds.toString(), hideBlanks],
  );

  useEffect(() => {
    if (previewPagesResp.status !== 'resolved') {
      return;
    }
    setPreviewPages(previewPagesResp.data?.data as any);

    const previewMap: Record<string, PagePreview['pageDetails']> = {};
    const firstPageByDocumentMap: Record<string, string> = {};
    previewPagesResp?.data?.data.forEach((page) => {
      previewMap[page.id] = page.pageDetails;
      if (firstPageByDocumentMap[page.pageDetails.document_id] == null) {
        firstPageByDocumentMap[page.pageDetails.document_id] = page.id;
      }
    });
    setPreviewPagesMap(previewMap);
    setFirstPageByDocument(firstPageByDocumentMap);
  }, [previewPagesResp.status]);

  const updatePagePreviewCache = useCallback(
    (pageId: string, pageDetails: any) => {
      const detailsToUpdate = previewPagesMap[pageId];
      const updatedDetails = { ...detailsToUpdate, ...pageDetails };
      previewPagesMap[pageId] = updatedDetails;
      const updatedPages = previewPages.map((page) => {
        if (String(page.id) !== pageId) {
          return page;
        }
        return {
          ...page,
          pageDetails: updatedDetails,
        };
      });

      setPreviewPages(updatedPages as any);
      setPreviewPagesMap(previewPagesMap);
    },
    [previewPages, previewPagesMap],
  );

  return {
    previewPages,
    previewPagesMap,
    isLoading: previewPagesResp.status === 'pending',
    updatePagePreviewCache,
    status: previewPagesResp.status,
    firstPageByDocument,
    refreshPreviewPages,
  };
}

export type PreviewPagesState = ReturnType<typeof usePreviewPagesNewState>;

const PreviewPagesContext = createContext<PreviewPagesState>({} as any);

export function PreviewPagesWrapper({
  documentIds,
  caseId,
  hideBlanks,
  children,
}: React.PropsWithChildren<PreviewPagesParams>) {
  const state = usePreviewPagesNewState({
    caseId,
    documentIds,
    hideBlanks,
  });

  return <PreviewPagesContext.Provider value={state}>{children}</PreviewPagesContext.Provider>;
}

export function usePreviewPagesNew() {
  return useContext(PreviewPagesContext);
}
