import { Box } from '@mui/material';
import { DescriptionOutlined, People } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import Cases from '../../components/icons/Cases';

function AdminHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box
      width="100%"
      height="4rem"
      sx={{
        display: 'flex',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        borderBottom: '0.5px solid #00214733',
        marginBottom: '1rem',
      }}
    >
      <Box
        name="Files"
        onClick={() => {
          navigate(`../cases`);
        }}
        sx={{
          ...navStyles.navButtons,
          position:
            location.pathname.indexOf('/cases') > -1 && navStyles.styledGreenUnderline.position,
          '::after': location.pathname.indexOf('/cases') > -1 && {
            ...navStyles.styledGreenUnderline['::after'],
          },
        }}
      >
        <Cases
          sx={{ ...navStyles.navIcons, fontSize: '2rem', paddingRight: 0.8, marginTop: '-0.5rem' }}
          color={navStyles.navIcons.color}
        />
        Case Management
      </Box>
      <Box
        name="Users"
        onClick={() => {
          if (location.pathname.indexOf('/users') === -1) {
            navigate(`../users`);
          }
        }}
        sx={{
          ...navStyles.navButtons,
          color: navStyles.navIcons.color,
          position:
            location.pathname.indexOf('/users') > -1 && navStyles.styledGreenUnderline.position,
          '::after': location.pathname.indexOf('/users') > -1 && {
            ...navStyles.styledGreenUnderline['::after'],
          },
        }}
      >
        <People
          sx={{
            ...navStyles.navIcons,
            color: navStyles.navIcons.color,
          }}
        />
        User Management
      </Box>
      <Box
        name="Index"
        onClick={() => {
          if (location.pathname.indexOf('/index') === -1) {
            navigate(`../index`);
          }
        }}
        sx={{
          ...navStyles.navButtons,
          color: navStyles.navIcons.color,
          position:
            location.pathname.indexOf('/index') > -1 && navStyles.styledGreenUnderline.position,
          '::after': location.pathname.indexOf('/index') > -1 && {
            ...navStyles.styledGreenUnderline['::after'],
          },
        }}
      >
        <DescriptionOutlined
          sx={{
            ...navStyles.navIcons,
            color: navStyles.navIcons.color,
          }}
        />
        Index Templates
      </Box>
    </Box>
  );
}

const navStyles = {
  navIcons: {
    fontSize: '1.3rem',
    marginRight: '0.3rem',
    color: '#002147',
    verticalAlign: 'top',
  },
  navButtons: {
    marginLeft: '1.2rem',
    color: '#002147',
    marginRight: '0.7rem',
    verticalAlign: 'bottom',
    height: '2.5rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    paddingRight: '0.5rem',
    display: 'inline-block !important',
    '&:hover': {
      position: 'relative',
      cursor: 'pointer',
      display: 'inline-block !important',
      '::after': {
        content: '""',
        marginTop: '6px',
        height: '4px',
        background: '#A8FCDC',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'block',
      },
    },
  },
  styledGreenUnderline: {
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-block !important',
    '::after': {
      content: '""',
      marginTop: '6px',
      height: '4px',
      background: '#6CE9A6',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      display: 'block',
    },
  },
};

export default AdminHeader;
