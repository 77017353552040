import { useState, useContext } from 'react';
import SelectMFAMethod from './SelectMFAMethod';
import SetupMFAUsingSMS from './SetupMFAUsingSMS';
import SetupMFAUsingTOTP from './SetupMFAUsingTOTP';
import { AuthContext } from '../../../library/contexts/AuthContext';

export default function MFASetup({ back, onComplete, location = 'settings', email }) {
  const { user } = useContext(AuthContext);
  const [step, setStep] = useState('selectMethod');
  const nextText = location === 'settings' ? 'Back to Settings' : 'Back to Login';
  switch (step) {
    case 'selectMethod':
      return <SelectMFAMethod setMFAMethod={(t) => setStep(t)} back={back} location={location} />;
    case 'sms':
      return (
        <SetupMFAUsingSMS
          user={user}
          onComplete={onComplete}
          nextText={nextText}
          withMFA={true}
          back={() => setStep('selectMethod')}
        />
      );
    case 'totp':
      return (
        <SetupMFAUsingTOTP
          user={user}
          back={() => setStep('selectMethod')}
          onComplete={onComplete}
          nextText={nextText}
          email={email}
        />
      );
    default: {
      <SelectMFAMethod setMFAMethod={(t) => setStep(t)} location={location} back={back} />;
    }
  }
}
