import {
  Box,
  Button,
  Checkbox,
  debounce,
  Divider,
  FormControlLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-ca';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { Close } from '@mui/icons-material';
import FilterButton from '../../../components/common/FilterButton';
import useDocumentSearchStore from '../useDocumentSearchStore';
import useCaseDocumentTagFilters from '../api-queries/useCaseDocumentTagFilters';
import MultipleSelectList from './MultipleSelectList';
import { mapTags, updateSubTags } from './useResetTimelineFilters';
import { toObjectMap } from '../../../utils/arrayUtils';
import useCaseFiles from '../../Files/useCaseFiles';

const getMapOfTagsToValues = (allTagsList, savedTagsList, formTagValues) => {
  const selectedTagsMap = savedTagsList ? toObjectMap(savedTagsList, (tag) => tag.value) : null;
  const selectedFileNameMap = savedTagsList ? toObjectMap(savedTagsList, (tag) => tag) : {};

  const tagToValueMap = toObjectMap(
    allTagsList,
    (tag) => tag.value,
    (tag) => {
      if (formTagValues && !Object.hasOwn(formTagValues, tag.value)) {
        return true;
      }

      if (formTagValues) {
        return Boolean(formTagValues[tag.value]);
      }

      if (!savedTagsList) {
        return true;
      }

      return Boolean(selectedTagsMap[tag.value] ?? selectedFileNameMap[tag.value]);
    },
  );

  return tagToValueMap;
};

const tagsInListAndMapInSync = (map, list) => {
  const allListValuesAreInMap = list.every((item) =>
    Object.prototype.hasOwnProperty.call(map ?? {}, item.value),
  );
  const listAndMapHaveSameValues = list.length === Object.values(map ?? {}).length;

  return allListValuesAreInMap && listAndMapHaveSameValues;
};

export default function ConfigurableViewFilter({ onSubmit, sortingOptions, defaultSortOrder }) {
  const [filters, goingToSource, setGoingToSource] = useDocumentSearchStore((state) => [
    state.filters,
    state.goingToSource,
    state.setGoingToSource,
  ]);
  const [currentCaseID] = useDocumentSearchStore((state) => [state.currentCaseID]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateErrorText, setDateErrorText] = useState(null);
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const { caseID } = useParams();

  const [searchParams] = useSearchParams();
  const documentID = searchParams.get('documentID');
  const { data: caseTags } = useCaseDocumentTagFilters({ caseID });
  const sourceList = caseTags?.sourceTags;
  const contentList = caseTags?.contentTags;

  const { data: caseFiles } = useCaseFiles(caseID);
  const fileList =
    caseFiles?.map((file) => ({ value: file.documentID, label: file.docFileName })) || [];
  const handleSubItems = useCallback(
    (contentTagsToUpdate, isValueSelected) =>
      updateSubTags(contentList, contentTagsToUpdate, isValueSelected),
    [contentList],
  );
  const {
    control,
    formState,
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    ...remainingMethods
  } = useForm({
    defaultValues: {
      'exclude-confirmed-duplicates': true,
    },
  });

  const sourceRef = useRef(undefined);
  const contentRef = useRef(undefined);
  const fileRef = useRef(undefined);
  const currentSelectedValues = getValues();

  const hasAppliedFilters = useMemo(() => {
    const duplicatesHidden = currentSelectedValues['exclude-confirmed-duplicates'];
    const viewedPagesHidden = currentSelectedValues['exclude-viewed-pages'];
    const onlyFavouritesShown = currentSelectedValues['only-favourites'];
    const startDate = currentSelectedValues['start-date'];
    const endDate = currentSelectedValues['end-date'];
    const documentIDsSelected = Object.values(currentSelectedValues?.file?.items ?? {}).every(
      (value) => value === true || value === undefined,
    );
    const allSourcesChecked = Object.values(currentSelectedValues?.source?.items ?? {}).every(
      (value) => value === true || value === undefined,
    );
    const content = currentSelectedValues?.content?.items;
    const subContents = currentSelectedValues?.content?.subItems;
    const allContentChecked = (() => {
      let allChecked = true;
      Object.keys(content ?? {}).forEach((type) => {
        if (!subContents?.[type] && content[type] === false) {
          allChecked = false;
          return;
        }
        if (
          !Object.values(subContents?.[type] ?? {}).every(
            (value) => value === true || value === undefined,
          )
        ) {
          allChecked = false;
        }
      });

      return allChecked;
    })();
    if (
      duplicatesHidden ||
      onlyFavouritesShown ||
      viewedPagesHidden ||
      !documentIDsSelected ||
      !allSourcesChecked ||
      !allContentChecked ||
      startDate ||
      endDate
    ) {
      return 1;
    }

    return 0;
  }, [sourceList, contentList, fileList, currentSelectedValues]);

  useEffect(() => {
    const formValues = getValues();
    if (!contentList) {
      return;
    }
    const allSubContentsList = contentList
      ?.filter((content) => content?.subItems?.length > 0)
      .map((content) => content.subItems)
      .flatMap((subItem) => subItem);

    const allDocsPresent = tagsInListAndMapInSync(formValues?.file?.items, fileList);
    const allSourcesPresent = tagsInListAndMapInSync(formValues?.source?.items, sourceList);
    const allContentsPresent = tagsInListAndMapInSync(formValues?.content?.items, contentList);
    const allSubContentsFormValuesMap = Object.assign(
      {},
      ...Object.values(formValues?.content?.subItems ?? {}),
    );
    const allSubContentsPresent = tagsInListAndMapInSync(
      allSubContentsFormValuesMap,
      allSubContentsList,
    );
    const allTagsHandled =
      allSourcesPresent && allContentsPresent && allSubContentsPresent && allDocsPresent;
    const shouldSkip =
      caseID !== currentCaseID ||
      !sourceList.length ||
      !contentList.length ||
      !fileList.length ||
      allTagsHandled ||
      !filters;

    if (goingToSource) {
      handleReset();
      setGoingToSource(false);
    }

    if (shouldSkip) {
      return;
    }

    const setValueOptions = {
      shouldDirty: true,
    };

    const savedSourceFilters = getMapOfTagsToValues(
      sourceList,
      filters.sources,
      formValues?.source?.items,
    );

    const savedDocuments = getMapOfTagsToValues(
      fileList,
      filters.documentID,
      formValues?.file?.items,
    );

    const savedContentTypes = getMapOfTagsToValues(
      contentList,
      filters.contentTypes,
      formValues?.content?.items,
    );
    const savedSubContentTypes = getMapOfTagsToValues(
      allSubContentsList,
      filters.subContentTypes,
      formValues?.content?.subItems ? allSubContentsFormValuesMap : null,
    );

    setValue('sort-by', filters.documentOrder ?? defaultSortOrder, setValueOptions);
    setValue('only-favourites', filters.showFavourited, setValueOptions);
    setValue('end-date', filters.beforeDate, setValueOptions);
    setValue('start-date', filters.afterDate, setValueOptions);
    setValue('exclude-confirmed-duplicates', filters.hideDuplicates, setValueOptions);
    setValue('exclude-viewed-pages', filters.hideViewed, setValueOptions);
    setValue(
      'source',
      {
        items: savedSourceFilters,
      },
      setValueOptions,
    );
    setValue(
      'content',
      {
        items: savedContentTypes,
        subItems: handleSubItems(allSubContentsList, (value) => savedSubContentTypes[value]),
      },
      setValueOptions,
    );
    setValue('file', { items: savedDocuments }, setValueOptions);
  }, [
    filters,
    sourceList,
    contentList,
    fileList,
    setValue,
    handleSubItems,
    formState.isDirty,
    goingToSource,
    caseID,
    currentCaseID,
  ]);

  const handleFilterFormSubmit = useCallback(
    (data) => {
      const { selectedSources, selectedContentTypes, selectedSubContentTypes, selectedDocuments } =
        mapTags(sourceList, contentList, fileList, data);

      const end = validDateOrNull(data['end-date']);
      const start = validDateOrNull(data['start-date']);

      const requestPayload = {
        showFavourited: data['only-favourites'],
        hideViewed: data['exclude-viewed-pages'],
        hideDuplicates: data['exclude-confirmed-duplicates'],
        sources: selectedSources,
        contentTypes: selectedContentTypes,
        subContentTypes: selectedSubContentTypes,
        confidenceScores: data['confidence-scores'],
        documentOrder: data['sort-by'],
        beforeDate: end,
        afterDate: start,
        documentID: selectedDocuments.map((doc) => doc.value),
      };

      if (data['start-date'] && !start) {
        setStartError(true);
        setDateErrorText('Invalid start date');
      } else if (data['end-date'] && !end) {
        setEndError(true);
        setDateErrorText('Invalid end date');
      } else if (data['end-date'] && data['start-date'] && end && start) {
        if (start > end) {
          setDateErrorText('Start date cannot be after end date');
          setEndError(true);
        } else {
          setDateErrorText(null);
          setEndError(false);
        }
      } else {
        setDateErrorText(null);
        setStartError(false);
        setEndError(false);
      }
      onSubmit(requestPayload);
    },
    [onSubmit, sourceList, contentList, fileList],
  );

  const debouncedSubmit = debounce(handleSubmit(handleFilterFormSubmit), 500);

  const handleOnChange = () => {
    debouncedSubmit();
  };

  useEffect(() => {
    const subscription = watch(handleOnChange);
    return () => {
      subscription.unsubscribe();
    };
  }, [watch, handleOnChange]);

  const handleReset = () => {
    reset(
      {
        'end-date': null,
        'start-date': null,
        'only-favourites': false,
        'exclude-viewed-pages': false,
        'exclude-confirmed-duplicates': false,
        'sort-by': defaultSortOrder ?? filters.documentOrder,
        source: {
          items: Object.fromEntries(sourceList.map((source) => [source.value, true])),
        },
        file: {
          items: Object.fromEntries(fileList.map((file) => [file.value, true])),
        },
        content: {
          items: Object.fromEntries(contentList.map((content) => [content.value, true])),
          subItems: handleSubItems(
            contentList
              .filter((content) => content.subItems.length > 0)
              .map((content) => content?.subItems)
              .flatMap((subItem) => subItem),
            true,
          ),
        },
        'confidence-scores': {
          type: ' ',
          value: ' ',
          min: ' ',
          max: ' ',
        },
      },
      {
        keepDirty: true,
      },
    );
  };

  useEffect(() => {
    const handleDataChange = () => {
      const currentValues = getValues();

      if (
        currentValues.source?.items === undefined ||
        currentValues.content?.items === undefined ||
        currentValues.file?.items === undefined
      ) {
        return;
      }

      if (
        sourceRef.current === undefined ||
        contentRef.current === undefined ||
        fileRef.current === undefined
      ) {
        sourceRef.current = currentValues.source?.items;
        contentRef.current = currentValues.content?.items;
        fileRef.current = currentValues.file?.items;
      } else if (
        !isEqual(currentValues.source?.items, sourceRef.current) ||
        !isEqual(currentValues.content?.items, contentRef.current) ||
        !isEqual(currentValues.file?.items, fileRef.current)
      ) {
        sourceRef.current = currentValues.source?.items;
        contentRef.current = currentValues.content?.items;
        fileRef.current = currentValues.file?.items;

        handleSubmit(debouncedSubmit)();
      }
    };

    const debouncedHandleDataChange = debounce(handleDataChange, 500);
    debouncedHandleDataChange();
  }, [sourceList, contentList, fileList, getValues, handleSubmit, handleFilterFormSubmit]);

  const methods = {
    control,
    formState,
    getValues,
    setValue,
    handleSubmit,
    ...remainingMethods,
  };

  const onCloseModal = () => {
    setAnchorEl(null);
  };

  return (
    <FormProvider {...methods}>
      <FilterButton
        onClick={(event) => setAnchorEl(event.target)}
        filterCount={hasAppliedFilters}
        dot
        containerName="review-tab-timeline"
      />

      <Popover
        elevation={1}
        onClose={onCloseModal}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted={false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack component="form" padding={3} pt={2.5} width="526px" position="relative">
          <Stack
            direction="row"
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
            }}
          >
            <Button disabled={!hasAppliedFilters} variant="text" size="small" onClick={handleReset}>
              Reset
            </Button>
            <IconButton sx={{ ml: '0.5rem' }} onClick={onCloseModal}>
              <Close />
            </IconButton>
          </Stack>

          <Stack gap={0.5}>
            <Typography variant="filterMenuHead">Sorting & Filtering</Typography>
            <Typography variant="filterMenuSubHead">Select filters below</Typography>
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Stack direction="row" justifyContent="space-between">
            <FilterInput
              name="sort-by"
              label="Sort by"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  variant="outlined"
                >
                  {sortingOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Stack>

          <Divider sx={{ my: 2 }} />

          <FilterInputLabel>Event Date</FilterInputLabel>
          <Stack direction="row" spacing={4}>
            <Box>
              <FilterInputLabel>Start</FilterInputLabel>
              <ControlledDateField
                name="start-date"
                error={startError}
                control={control}
                label="Select Date"
              />
            </Box>
            <Box>
              <FilterInputLabel>End</FilterInputLabel>
              <ControlledDateField
                name="end-date"
                control={control}
                error={endError}
                label="Select Date"
              />
            </Box>
          </Stack>
          {dateErrorText && (
            <Typography variant="error" mt={1} mb={-1}>
              {dateErrorText}
            </Typography>
          )}
          <Divider sx={{ my: 2 }} />

          <Stack direction="row" spacing={4}>
            <Stack width={165}>
              <FilterInputLabel>Show</FilterInputLabel>

              <ControlledFilterCheckbox
                name="only-favourites"
                label="Only Favourited Pages"
                control={control}
              />
            </Stack>

            <Stack width={165}>
              <FilterInputLabel>Hide</FilterInputLabel>

              <ControlledFilterCheckbox
                name="exclude-confirmed-duplicates"
                label="Confirmed Duplicates"
                control={control}
              />

              <ControlledFilterCheckbox
                name="exclude-viewed-pages"
                label="Viewed Pages"
                control={control}
              />
            </Stack>
          </Stack>

          <Divider sx={{ my: 1.8 }} />

          <Stack>
            <FilterInputLabel>Source Type</FilterInputLabel>
            <MultipleSelectList items={sourceList} control={control} name="source" />
          </Stack>
          <Stack mt={1.5}>
            <FilterInputLabel>Content</FilterInputLabel>
            <MultipleSelectList items={contentList} control={control} name="content" />
          </Stack>
          <Stack mt={1.5}>
            <FilterInputLabel>File</FilterInputLabel>
            <MultipleSelectList items={fileList} control={control} name="file" />
          </Stack>
        </Stack>
      </Popover>
    </FormProvider>
  );
}

export function FilterInputLabel({ children }) {
  return (
    <Typography fontSize="13px" lineHeight="20px" fontWeight={500} color="#344054" mb={0.75}>
      {children}
    </Typography>
  );
}

function FilterInput({ width = 200, label, name, control, render }) {
  return (
    <Stack width={width}>
      <FilterInputLabel>{label}</FilterInputLabel>
      <Controller name={name} control={control} render={render} />
    </Stack>
  );
}

export function FilterCheckbox({ label, value, indeterminate, ...props }) {
  return (
    <FormControlLabel
      {...props}
      checked={!!value}
      value={!!value}
      label={label}
      fontSize="12px"
      sx={{
        minHeight: 20,
        height: 'fit-content',
        marginY: 0.5,
        marginX: 0,
        '& .MuiFormControlLabel-label': {
          fontSize: '12px',
          lineHeight: '20px',
          fontWeight: 400,
          color: '#344054',
        },
        '& .MuiSvgIcon-root': {
          width: 15,
          height: 15,
          fill: value || indeterminate ? '#2F5394' : '#D0D5DD',
        },
        '& .MuiButtonBase-root': {
          paddingY: 0,
          height: 20,
          width: 20,
        },
      }}
      control={<Checkbox indeterminate={indeterminate} />}
    />
  );
}

export function ControlledFilterCheckbox({ name, control, label, indeterminate }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FilterCheckbox {...field} indeterminate={indeterminate} label={label} />
      )}
    />
  );
}

export function ControlledDateField({ name, label, control, error }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-ca">
          <DatePicker
            label={label}
            control={control}
            value={!value ? null : dayjs(value)}
            sx={{
              width: '10rem',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: error && '#F05353 !important',
                },
                '&.Mui-focused fieldset': {
                  borderColor: error && '#F05353 !important',
                },
              },
              svg: { color: error && 'themeDuplicatesDark.main' },
              input: { color: error && 'themeDuplicatesDark.main' },
            }}
            slotProps={{
              inputAdornment: {
                position: 'start',
              },
              textField: {
                placeholder: label,
              },
            }}
            onChange={onChange}
          />
        </LocalizationProvider>
      )}
    />
  );
}

function validDateOrNull(datelike) {
  if (Number.isNaN(Date.parse(datelike))) {
    return null;
  }
  return datelike;
}
