import { Box } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { RotateRight, RotateLeft, Add, Remove } from '@mui/icons-material';
import { useUpdatePageRotationMutation } from '../../__generated__/graphql';
import NavigationButton from '../common/PdfNavigation/Components/NavigationButton';
import usePDFViewerStore from '../../containers/Case/usePDFViewerStore';

const documentWidth = 794;

export default function HorizontalToolBar(props) {
  const [toolBarPosition, setToolBarPosition] = useState(0);
  const observer = useRef(null);
  const { pageID: paramsPageID } = useParams();
  const pageID = props.pageID ?? paramsPageID;
  const documentRotation = usePDFViewerStore((state) => state.documentRotation);
  const setDocumentRotation = usePDFViewerStore((state) => state.setDocumentRotation);
  const documentZoom = usePDFViewerStore((state) => state.documentZoom);
  const setDocumentZoom = usePDFViewerStore((state) => state.setDocumentZoom);
  const [pageRotationMutation] = useUpdatePageRotationMutation();

  const saveRotation = async (newRotation) => {
    try {
      await pageRotationMutation({
        variables: {
          data: { pageID, rotation_angle: newRotation },
        },
      });
    } catch (error) {
      toast.error('Failed to save page rotation');
      Sentry.captureException(error);
    }
  };

  const rotateClockwise = () => {
    const currentRotation = documentRotation?.[pageID] || 0;
    setDocumentRotation({
      ...documentRotation,
      [pageID]: currentRotation === 270 ? 0 : currentRotation + 90,
    });
    saveRotation(currentRotation === 270 ? 0 : currentRotation + 90);
  };

  const rotateCounterClockwise = () => {
    const currentRotation = documentRotation?.[pageID] || 0;
    setDocumentRotation({
      ...documentRotation,
      [pageID]: currentRotation === 0 ? 270 : currentRotation - 90,
    });
    saveRotation(currentRotation === 0 ? 270 : currentRotation - 90);
  };

  const zoomOut = () => {
    setDocumentZoom(documentZoom - 0.1);
  };
  const zoomIn = () => {
    setDocumentZoom(documentZoom + 0.1);
  };

  const updateToolbarPosition = () => {
    const pdfDisplayContainer = document.getElementById('document-display');

    const box = pdfDisplayContainer.getBoundingClientRect();

    const pdfSize = documentWidth * documentZoom;
    const position = box.left + (box.width - pdfSize) / 2 + pdfSize;
    setToolBarPosition(box.width - pdfSize > 100 ? { left: `${position + 12}px` } : { right: 8 });
  };

  useEffect(() => {
    updateToolbarPosition();
    observer.current = new ResizeObserver(updateToolbarPosition);
    const body = document.getElementById('document-display');
    observer.current.observe(body);
    return () => observer.current.unobserve(body);
  }, [documentZoom]);

  return (
    <Box
      id="toolbarContainer"
      {...toolBarPosition}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'right',
        alignItems: 'center',
        width: 'fit-content',
        height: '24px',
        backgroundColor: 'selectedGrey.main',
      }}
    >
      <Box
        sx={{
          display: 'inline',
          whiteSpace: 'nowrap',
          marginRight: '0.5rem',
        }}
      >
        <NavigationButton
          id={`${props.containerName}-navbar-rotate-counterclockwise-btn`}
          onClick={rotateCounterClockwise}
          icon={<RotateLeft sx={{ fontSize: '1rem', color: '#344054' }} />}
          position="left"
        />
        <NavigationButton
          id={`${props.containerName}-navbar-rotate-clockwise-btn`}
          onClick={rotateClockwise}
          icon={<RotateRight sx={{ fontSize: '1rem', color: '#344054' }} />}
          position="right"
        />
      </Box>
      <Box sx={{ display: 'flex', whiteSpace: 'nowrap', marginLeft: '12px' }}>
        <NavigationButton
          id={`${props.containerName}-navbar-zoom-out-btn`}
          onClick={zoomOut}
          icon={
            <Remove
              sx={{
                fontSize: '1rem',
                color: documentZoom.toFixed(1) <= 0.5 ? 'inherit' : '#344054',
              }}
            />
          }
          disabled={documentZoom.toFixed(1) <= 0.5}
          position="left"
        />
        <Box
          sx={{
            border: '1px solid #D0D5DD',
            borderRight: 0,
            fontSize: '0.68rem',
            fontWeight: 600,
            paddingX: '8px',
            lineHeight: '1.6rem',
            height: '1.6rem',
          }}
        >
          {Math.round(documentZoom * 100)}%
        </Box>
        <NavigationButton
          id={`${props.containerName}-navbar-zoom-in-btn`}
          onClick={zoomIn}
          icon={
            <Add
              sx={{
                fontSize: '1rem',
                color: documentZoom.toFixed(1) >= 2 ? 'inherit' : '#344054',
              }}
            />
          }
          disabled={documentZoom.toFixed(1) >= 2}
          position="right"
        />
      </Box>
    </Box>
  );
}
