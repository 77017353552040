import {
  Box,
  Divider,
  Typography,
  Tooltip,
  ClickAwayListener,
  Paper,
  Switch,
  Popover,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

import { useParams, useLocation } from 'react-router-dom';
import IconButtonContainer from '../../components/common/IconButtonContainer';
import useDisplayStore from './useDisplayStore';
import { useGetCaseQuery } from '../../__generated__/graphql';

export default function TimelineSettings() {
  const settingsRef = React.useRef(null);
  const [settingsOpen, setSettingsOpen] = React.useState(null);
  const thumbnailSize = useDisplayStore((state) => state.thumbnailSize);
  const setThumbnailSize = useDisplayStore((state) => state.setThumbnailSize);
  const showThumbnails = useDisplayStore((state) => state.showThumbnails);
  const setShowThumbnails = useDisplayStore((state) => state.setShowThumbnails);
  const showThumbnailSource = useDisplayStore((state) => state.showThumbnailSource);
  const setShowThumbnailSource = useDisplayStore((state) => state.setShowThumbnailSource);
  const location = useLocation();
  const isDocumentView = location.pathname.indexOf('/documents') > -1;

  const params = useParams();
  const { caseID } = params;
  const { data: currentCase } = useGetCaseQuery({
    variables: {
      id: caseID,
    },
  });

  const onOpen = () => {
    if (settingsOpen) {
      setSettingsOpen(null);
    } else {
      const { top } = settingsRef.current.getBoundingClientRect();
      const { left } = settingsRef.current.getBoundingClientRect();
      setSettingsOpen({ top, left });
    }
  };
  const sizeMap = {
    1: 25,
    2: 30,
    3: 35,
    4: 40,
    5: 45,
    6: 50,
    7: 55,
    8: 60,
    9: 65,
    10: 70,
  };
  const currentSize = Object.keys(sizeMap).find((key) => sizeMap[key] === thumbnailSize);

  const adjustSize = (add = true) => {
    const newSize = add ? Number(currentSize) + 1 : Number(currentSize) - 1;
    if (newSize < 1 || newSize > 10) {
      return;
    }
    setThumbnailSize(sizeMap[newSize]);

    //set in local storage
  };

  const handleShowThumbnails = (e) => {
    const { checked } = e.target;
    setShowThumbnails(checked);
  };

  const handleShowThumbnailSource = (e) => {
    const { checked } = e.target;
    setShowThumbnailSource(checked);
  };

  return (
    <ClickAwayListener onClickAway={() => setSettingsOpen(null)}>
      <Box ref={settingsRef}>
        <IconButtonContainer
          id="review-tab-display-settings-btn"
          tooltipText="Display Settings"
          onClick={onOpen}
          size="small"
          icon={<SettingsIcon sx={{ marginTop: 0.3 }} />}
        />

        <Popover
          open={Boolean(settingsOpen)}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: (settingsOpen?.top ?? 0) + 30,
            left: (settingsOpen?.left ?? 0) - 100,
          }}
          role={undefined}
          sx={{ zIndex: 2000 }}
          onClose={() => setSettingsOpen(null)}
        >
          <Paper variant="outlined">
            <Box sx={{ p: '12px', width: '20rem' }}>
              <Typography sx={{ fontSize: '14px' }}>Display Settings</Typography>
              <Divider sx={{ mb: '.5rem' }} />

              <SwitchWithLabel
                id="review-tab-timeline-show-thumbnails-switch"
                label="Show Thumbnails"
                disabled={currentCase?.case?.pageCount > 4000 || isDocumentView}
                isDocumentView={isDocumentView}
                checked={showThumbnails}
                onClick={handleShowThumbnails}
              />

              {showThumbnails && (
                <Box sx={{ pl: '1rem' }}>
                  <SizeAdjusterWithLabel
                    label="Thumbnail Size"
                    onClick={adjustSize}
                    currentSize={+currentSize}
                    min={1}
                    max={10}
                  />
                  <SwitchWithLabel
                    label="Show Page Source"
                    checked={showThumbnailSource}
                    onClick={handleShowThumbnailSource}
                  />
                </Box>
              )}
            </Box>
          </Paper>
        </Popover>
      </Box>
    </ClickAwayListener>
  );
}

function SwitchWithLabel({ label, checked, isDocumentView, disabled, onClick, sx = {} }) {
  const tooltip = isDocumentView
    ? 'This feature is current unavailable for documents'
    : 'This feature is unavailable for cases with more than 4000 pages';
  return (
    <Tooltip title={disabled && tooltip}>
      <Box sx={{ display: 'flex', height: '32px', ...sx }}>
        <Typography
          variant="h7"
          sx={{ fontsize: '12px', lineHeight: '32px', flex: '50%', opacity: disabled && '60%' }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flex: '50%',
            margin: 'auto',
          }}
        >
          <Switch disabled={disabled} checked={checked} onClick={onClick} size="small" />
        </Box>
      </Box>
    </Tooltip>
  );
}

function SizeAdjusterWithLabel({
  label = '',
  onClick = null,
  currentSize = 0,
  min = 0,
  max = 1,
  sx = {},
}) {
  return (
    <Box sx={{ display: 'flex', height: '32px', ...sx }}>
      <Typography
        variant="h7"
        sx={{
          fontsize: '12px',
          lineHeight: '32px',
          flex: '50%',
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flex: '50%',
          margin: 'auto',
        }}
      >
        <IconButtonContainer
          size="small"
          onClick={() => onClick(false)}
          disableClick={currentSize === min}
          icon={<RemoveIcon sx={{ fontSize: '18px' }} />}
        />
        <Typography variant="h6" sx={{ lineHeight: '28px' }}>
          {currentSize}
        </Typography>
        <IconButtonContainer
          size="small"
          onClick={() => onClick(true)}
          disableClick={currentSize === max}
          icon={<AddIcon sx={{ fontSize: '18px' }} />}
        />
      </Box>
    </Box>
  );
}
