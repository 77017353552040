import React from 'react';
import { InputAdornment, TextField, CircularProgress, IconButton, Typography } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import SearchIcon from '../icons/SearchIcon';

type NewSearchBarProps = {
  label?: string;
  searchStr?: string;
  customStyles?: object;
  totalSearchOccurances?: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  searchingInProgress?: boolean;
  handleArrowUp?: () => void;
  handleArrowDown?: () => void;
  currentSearchMatchIndex: number;
};

function NewSearchBar(props: NewSearchBarProps) {
  const {
    label = 'Search',
    customStyles,
    searchStr = '',
    disabled = false,
    totalSearchOccurances = 0,
    handleChange,
    searchingInProgress = false,
    currentSearchMatchIndex = 0,
    handleArrowUp,
    handleArrowDown,
  } = props;

  return (
    <TextField
      name="search"
      placeholder={label}
      sx={{
        ...customStyles,
      }}
      onChange={handleChange}
      value={searchStr}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              opacity: '100%',
              color: 'primary.light',
              backgroundColor: 'white',
            }}
          >
            {searchingInProgress && <CircularProgress size={18} />}
            {!searchingInProgress && <SearchIcon sx={{ color: 'primary' }} />}
          </InputAdornment>
        ),
        endAdornment: !searchingInProgress && totalSearchOccurances > 0 && (
          <>
            <Typography
              sx={{
                fontSize: '0.8rem',
                textAlign: 'right',
                color: 'grey',
                whiteSpace: 'nowrap',
                flexGrow: 1,
                display: 'block',
              }}
            >
              {`${currentSearchMatchIndex + 1} of ${totalSearchOccurances}`}
            </Typography>
            <InputAdornment position="end" sx={{ margin: '0' }}>
              <IconButton
                onClick={handleArrowUp}
                sx={{
                  padding: '0',
                  backgroundColor: 'none',
                  '&:hover': {
                    background: 'none',
                  },
                }}
              >
                <ArrowUpward sx={{ color: 'primary.light' }} />
              </IconButton>
              <IconButton
                onClick={handleArrowDown}
                sx={{
                  padding: '0',
                  backgroundColor: 'none',
                  '&:hover': {
                    background: 'none',
                  },
                }}
              >
                <ArrowDownward
                  sx={{
                    color: 'primary.light',
                  }}
                />
              </IconButton>
            </InputAdornment>
          </>
        ),
      }}
    />
  );
}

export default NewSearchBar;
