import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
  Divider,
  Tooltip,
  Select,
  MenuItem,
  Skeleton,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  HelpOutlineOutlined,
} from '@mui/icons-material';
import { gql, useApolloClient } from '@apollo/client';
import { useEffect, useState, useMemo } from 'react';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import AddTagButton from '../Tags/AddTagButton';
import NotePhysicianFilter from './NotePhysicianFilter';
import Tag from '../Tags/Tag';
import usePDFViewerStore from '../Case/usePDFViewerStore';
import Loading from '../../components/common/Loading';
import Delete from '../../components/icons/Delete';
import useScreenCaptureStore from '../ScreenCapture/useScreenCaptureStore';
import { useUser } from '../../library/contexts/AuthContext';
/**
 * Component to make a new note or update notes from the documents.
 *
 */

const noteTemplateStyles = {
  pageDetails: {
    fontSize: '0.7rem',
    display: 'inline',
    marginRight: '0.2rem',
  },
  inputLabels: {
    marginTop: '1.2rem',
    marginBottom: '0.4rem',
    fontColor: '#344054',
    fontSize: '0.7rem',
  },
  textfields: {
    width: '95%',
    height: '1rem',
  },
  visibilityIcons: {
    marginRight: '0.3rem',
    fontSize: '1rem',
  },
  errorText: {
    fontSize: '0.7rem',
    color: '#F04438',
    marginTop: '1rem',
    marginBottom: '-0.7rem',
  },
};

const useCaptureStore = createSelectorFunctions(useScreenCaptureStore);
const usePDFStore = createSelectorFunctions(usePDFViewerStore);

function NoteEditableTemplate({
  open = false,
  defaultNoteValues = {},
  onClose,
  onSubmit,
  onDeleteImage,
  dialogTitle,
  isSubmitting = false,
  editNote,
  page,
  loadingPage,
  containerName = '',
}) {
  const { userId } = useUser();
  const apolloClient = useApolloClient();

  const screenCaptures = useCaptureStore.use.screenCaptures();
  const setIsScreenCapturing = usePDFStore.use.setIsScreenCapturing();

  const document = useMemo(
    () =>
      apolloClient.cache.readFragment({
        id: apolloClient.cache.identify({ id: page.documentID, __typename: 'DocumentObject' }),
        fragment: gql`
          fragment documentDetails on DocumentObject {
            documentID: id
            docFileName: document_file_name
          }
        `,
      }),
    [page.documentID],
  );
  const [currentScreenShotCount, setCurrentScreenShotCount] = useState(0);

  // Cancel screenshot in progress on unmount
  useEffect(() => () => setIsScreenCapturing(false), []);

  const [noteTitle, setNoteTitle] = useState(defaultNoteValues?.title || '');
  const [physician, setPhysician] = useState(defaultNoteValues?.physician || '');
  const [privateNote, setPrivateNote] = useState(!!defaultNoteValues?.privateNote || false);
  const [noteTags, setNoteTags] = useState(defaultNoteValues?.tags || []);
  const [noteDate, setDate] = useState(defaultNoteValues?.date || '');
  const [noteBody, setNoteBody] = useState(defaultNoteValues?.body || '');
  const [activeField, setActiveField] = useState('noteBody');

  useEffect(() => {
    if (!noteDate) {
      setDate(defaultNoteValues?.date ?? '');
    }
  }, [defaultNoteValues?.date]);

  function addNoteTag(tag) {
    tag = { ...tag, userID: userId };
    const declared = noteTags.find((n) => n.tagID === tag.tagID);
    if (!declared) {
      setNoteTags((prevTags) => [...prevTags, tag]);
    }
  }

  function deleteNoteTag(tag) {
    const tags = noteTags.filter((t) => t.tagID !== tag.tagID);
    setNoteTags(tags);
  }

  function onTagUpdate(tag) {
    setNoteTags((tags) => tags.map((t) => (t.tagID === tag.tagID ? tag : t)));
  }

  const onDialogClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  useEffect(() => {
    setNoteTitle(defaultNoteValues?.title || '');
    setNoteTags(defaultNoteValues?.tags || []);
    setPhysician(defaultNoteValues?.physician || '');
    setNoteBody(defaultNoteValues?.body || '');
    setDate(defaultNoteValues?.date || '');
    setPrivateNote(
      defaultNoteValues?.privateNote !== undefined ? !!defaultNoteValues?.privateNote : false,
    );
  }, [defaultNoteValues?.body]);

  function submitNote() {
    if (onSubmit) {
      onSubmit(noteTitle, noteBody, physician, noteTags, !noteDate ? null : noteDate, privateNote);
    }
  }

  const maxCaptures = screenCaptures.length + (defaultNoteValues?.images?.length || 0) >= 9;

  useEffect(() => {
    setIsScreenCapturing(!maxCaptures);
  }, [maxCaptures, setIsScreenCapturing]);

  useEffect(() => {
    if (!maxCaptures && screenCaptures?.length > currentScreenShotCount) {
      const newCaptureText = screenCaptures[screenCaptures.length - 1]?.capturedText || '';
      if (activeField === 'noteTitle') {
        setNoteTitle((prevTitle) => {
          if (prevTitle.length > 0) {
            return `${prevTitle}\n${newCaptureText}`;
          }
          return `${newCaptureText}`;
        });
      } else if (activeField === 'physician') {
        setPhysician((prevPhysician) => {
          if (prevPhysician.length > 0) {
            return `${prevPhysician}\n${newCaptureText}`;
          }
          return `${newCaptureText}`;
        });
      } else if (activeField === 'noteBody') {
        setNoteBody((prevBody) => {
          if (prevBody.length > 0) {
            return `${prevBody}\n${newCaptureText}`;
          }
          return `${newCaptureText}`;
        });
      }

      setCurrentScreenShotCount(screenCaptures.length);
    }
  }, [screenCaptures, currentScreenShotCount, activeField, maxCaptures]);

  return (
    <Box
      onClose={onDialogClose}
      open={open}
      maxWidth="md"
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          color: 'primary.main',
          fontWeight: 500,
          padding: '1rem',
          backgroundColor: 'selectedGrey.main',
        }}
      >
        <Typography color="primary" sx={{ color: 'primary', fontSize: '1rem' }}>
          {dialogTitle}
        </Typography>
      </Box>
      {isSubmitting ? (
        <Loading
          text="Updating Note..."
          customStyles={{ paddingTop: '30%', paddingBottom: '50%' }}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            padding: '1rem',
            paddingTop: '0.5rem',
            borderRadius: '1rem',
          }}
        >
          <Box>
            {loadingPage ? (
              <Skeleton
                variant="rectangular"
                width={130}
                height={25}
                sx={{ borderRadius: '18px' }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    ...noteTemplateStyles.pageDetails,
                    color: '#9AA4B2',
                  }}
                >
                  Page {page?.pageNumber} of
                </Typography>

                <Typography
                  sx={{
                    ...noteTemplateStyles.pageDetails,
                    color: '#364152',
                    fontWeight: 600,
                  }}
                >
                  {document?.docFileName} |
                </Typography>
                <Typography
                  sx={{
                    ...noteTemplateStyles.pageDetails,
                    color: '#364152',
                  }}
                >
                  {page?.source}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              maxHeight: '100%',
            }}
          >
            {editNote &&
              defaultNoteValues?.images?.map((image) => (
                <Box sx={{ width: '33%' }} key={image.image_key_url}>
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      color: '#667085',
                      display: 'inline',
                      marginRight: '0.3rem',
                    }}
                  >
                    Capture {defaultNoteValues.images.findIndex((x) => x === image) + 1}{' '}
                  </Typography>
                  <Delete onClick={() => onDeleteImage(image)} width={11} height={12} />
                </Box>
              ))}
            {screenCaptures?.map((image) => (
              <Box sx={{ width: '33%' }} key={image.image}>
                <Typography
                  sx={{
                    fontSize: '0.7rem',
                    color: '#667085',
                    display: 'inline',
                    marginRight: '0.3rem',
                  }}
                >
                  Capture{' '}
                  {screenCaptures.findIndex((x) => x === image) +
                    1 +
                    (editNote ? defaultNoteValues?.images?.length || 0 : 0)}
                </Typography>
                <Delete onClick={() => onDeleteImage(image)} width={11} height={12} />
              </Box>
            ))}
            {maxCaptures && (
              <Typography sx={{ color: 'red', fontSize: '0.8rem', marginTop: '1rem' }}>
                Maximum capture limit reached. You cannot add more captures to this note.
              </Typography>
            )}
          </Box>
          <Divider sx={{ width: '94%', marginTop: '0.7rem' }} />

          <Box
            sx={{
              backgroundColor: '#FFFFFF',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flex: '1 0 40%',
                }}
              >
                <InputLabel sx={noteTemplateStyles.inputLabels}>Title</InputLabel>
                <TextField
                  sx={noteTemplateStyles.textfields}
                  inputProps={{ style: { fontSize: '0.8rem' } }}
                  value={noteTitle}
                  onChange={(e) => setNoteTitle(e.target.value)}
                  onFocus={() => setActiveField('noteTitle')}
                  name="noteTitle"
                  size="small"
                  className="fs-exclude"
                />
                {noteTitle.length > 100 && (
                  <Typography sx={noteTemplateStyles.errorText}>
                    Title must be less than 100 characters
                  </Typography>
                )}
                <InputLabel sx={noteTemplateStyles.inputLabels}>Name</InputLabel>
                <NotePhysicianFilter
                  physician={physician}
                  setPhysician={setPhysician}
                  onFocus={() => setActiveField('physician')}
                />
                {physician.length > 100 && (
                  <Typography
                    sx={{
                      ...noteTemplateStyles.errorText,
                    }}
                  >
                    Name must be less than 100 characters
                  </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '52%',
                    }}
                  >
                    <InputLabel sx={noteTemplateStyles.inputLabels}>Date</InputLabel>
                    {loadingPage ? (
                      <Skeleton
                        variant="rectangular"
                        width={130}
                        height={25}
                        sx={{ borderRadius: '18px' }}
                      />
                    ) : (
                      <TextField
                        id="inciDate"
                        type="date"
                        size="small"
                        name="noteDate"
                        sx={{ ...noteTemplateStyles.textfields }}
                        value={noteDate === '1900-01-01' ? '' : noteDate}
                        onChange={(e) => setDate(e.target.value)}
                        inputProps={{
                          min: '1000-01-01',
                          max: '2099-12-31',
                          style: { fontSize: '0.8rem' },
                        }}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel sx={noteTemplateStyles.inputLabels}>
                      Visibility{' '}
                      <Tooltip title="The visibility icon allows anyone viewing this case to see your notes. If deselected, these notes can be seen only by you.">
                        <HelpOutlineOutlined
                          sx={{
                            marginLeft: '0.2rem',
                            fontColor: '#98A2B3',
                            fontSize: '1rem',
                            verticalAlign: 'top',
                          }}
                        />
                      </Tooltip>
                    </InputLabel>
                    <Select
                      value={privateNote}
                      label="Age"
                      sx={{ width: '7.5rem', mt: '-0.3rem' }}
                      onChange={(e) => setPrivateNote(e.target.value)}
                    >
                      <MenuItem value={false}>
                        <VisibilityOutlined sx={noteTemplateStyles.visibilityIcons} />
                        Public
                      </MenuItem>
                      <MenuItem value={true}>
                        <VisibilityOffOutlined sx={noteTemplateStyles.visibilityIcons} />
                        Private
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
                <InputLabel sx={noteTemplateStyles.inputLabels} required>
                  Note
                </InputLabel>
                <TextField
                  label={noteBody ? ' ' : 'Enter text...'}
                  multiline
                  rows={5}
                  sx={{
                    ...noteTemplateStyles.textfields,
                    height: '8.5rem',
                    '& .MuiOutlinedInput-root': {
                      height: '8.5rem',
                      paddingRight: 0.8,
                    },
                  }}
                  inputProps={{
                    sx: { fontSize: '0.8rem' },
                  }}
                  value={noteBody}
                  onChange={(e) => setNoteBody(e.target.value)}
                  onFocus={() => setActiveField('noteBody')}
                  name="noteBody"
                  className="fs-exclude"
                />
                {noteBody.length > 4000 && (
                  <Typography
                    sx={{
                      ...noteTemplateStyles.errorText,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Note must be less than 4000 characters
                  </Typography>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '8px',
                  }}
                >
                  <Box
                    sx={{
                      marginTop: 0,
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    {noteTags.map((tag) => (
                      <Tag tag={tag} key={tag.tagID} onDelete={deleteNoteTag} space />
                    ))}
                    <AddTagButton
                      tags={noteTags}
                      text={noteTags.length === 0}
                      onClick={addNoteTag}
                      onNewTag={addNoteTag}
                      onDelete={deleteNoteTag}
                      onUpdate={onTagUpdate}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {!isSubmitting && (
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            backgroundColor: 'selectedGrey.main',
            paddingBottom: '2.5rem',
            paddingTop: '1rem',
          }}
        >
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              backgroundColor: 'selectedGrey.main',
              paddingBottom: '2.5rem',
              paddingTop: '1rem',
            }}
          >
            <Button
              id={`${containerName}-tab-cancel-editing-note-btn`}
              sx={{ mr: '0.8rem', backgroundColor: 'white' }}
              variant="outlined"
              size="small"
              disabled={noteTitle.length > 100 || noteBody.length > 4000 || physician.length > 100}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              id={`${containerName}-tab-save-note-btn`}
              sx={{ fontWeight: 600 }}
              variant="contained"
              size="small"
              disabled={
                !noteBody ||
                noteTitle.length > 100 ||
                noteBody.length > 4000 ||
                physician.length > 100
              }
              onClick={submitNote}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

NoteEditableTemplate.propTypes = {
  open: PropTypes.bool,
  defaultNoteValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  dialogTitle: PropTypes.string,
};

NoteEditableTemplate.defaultProps = {
  open: false,
  defaultNoteValues: {},
  onClose: () => {},
  onSubmit: () => {},
  dialogTitle: 'Edit Note',
};

export default NoteEditableTemplate;
