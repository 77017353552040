import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '@mui/system';
import { toast } from 'react-toastify';

import { useUpdateIndexTemplate } from './hooks/useUpdateIndexTemplate';
import { useStringifiedIndexDetails } from './hooks/useStringifiedIndexDetails';
import AdminHeader from '../AdminHeader';
import PageHeader from '../../../components/common/PageHeader';
import IndexSchemaForm from './components/IndexSchemaForm';
import UpArrowWithTail from '../../../components/icons/UpArrowWithTail';
import Loading from '../../../components/common/Loading';
import { calculateObjectDifferences } from './utils';

export default function UpdateIndexTemplate() {
  const { templateID = '' } = useParams();
  const {
    loading,
    templateDetails,
    shouldSyncReports,
    setShouldSyncReports,
    updateIndexTemplate,
    errors: validationErrors,
    clearErrors: clearValidationErrors,
  } = useUpdateIndexTemplate(templateID);
  const {
    indexDetails,
    onChanges,
    getParsedIndexDetails,
    isParsed,
    errors: jsonParseErrors,
  } = useStringifiedIndexDetails(templateDetails);
  const navigate = useNavigate();

  const errors: Record<
    'sections' | 'exclude_pages' | 'exclude_documents' | 'collapse_documents' | 'name',
    string[] | null
  > = useMemo(() => {
    return {
      sections: jsonParseErrors.sections ?? validationErrors.sections,
      exclude_pages: jsonParseErrors.exclude_pages ?? validationErrors.exclude_pages,
      exclude_documents: jsonParseErrors.exclude_documents ?? validationErrors.exclude_documents,
      collapse_documents: jsonParseErrors.collapse_documents ?? validationErrors.collapse_documents,
      name: validationErrors.name,
    };
  }, [jsonParseErrors, validationErrors]);

  const onUpdateIndexTemplate = useCallback(async () => {
    if (templateDetails == null) {
      return;
    }
    clearValidationErrors();
    const updatedTemplateDetails = getParsedIndexDetails();
    const changedFields = calculateObjectDifferences(templateDetails, updatedTemplateDetails);
    if (Object.keys(changedFields).length === 0) {
      toast.error('You must edit one of the fields before updating the index template.');
      return;
    }
    await updateIndexTemplate(changedFields);
  }, [getParsedIndexDetails, templateDetails, templateID, clearValidationErrors]);
  if (loading || !isParsed) {
    return <Loading />;
  }
  return (
    <Container
      maxWidth="lg"
      sx={{
        marginY: '2rem',
      }}
    >
      <AdminHeader />
      <PageHeader title="Update Index Template" />
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/admin/index')}
      >
        <UpArrowWithTail style={{ transform: 'rotate(-90deg)' }} />
        <span style={{ paddingLeft: '0.5rem' }}>Return to Templates</span>
      </div>
      <IndexSchemaForm indexDetails={indexDetails} onChanges={onChanges} errors={errors} />
      <div style={{ display: 'flex', marginBottom: '2rem' }}>
        <input
          type="checkbox"
          className="sm-checkbox"
          style={{ marginRight: '1rem' }}
          checked={shouldSyncReports}
          onChange={() => setShouldSyncReports(!shouldSyncReports)}
        />
        <span
          style={{
            fontWeight: 500,
          }}
        >
          Sync All Existing Reports to Match Template
        </span>
      </div>
      <button
        type="button"
        className="sm-button-primary"
        onClick={onUpdateIndexTemplate}
        style={{ width: 'fit-content' }}
      >
        Update Index Template
      </button>
    </Container>
  );
}
