import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import EditHoverCellEffect from '../../../../components/common/Table/EditHoverCellEffect';
import { isRowEditable } from '../utils/tableUtils';
import IconGroup from '../../../../components/icons/IconGroup';
import Bookmark from '../../../../components/icons/Bookmark';

export default function DocumentNameCell(
  params: GridRenderCellParams & {
    onBookmarkClick: (rowID: string, updateBookmark: boolean) => void;
  },
) {
  const bookmarkStyle = params.row.is_bookmarked ? { fill: '#385D9F' } : {};
  const displayIcon = (
    <>
      {params.row.isCollapsed && (
        <Tooltip title="Collapsed Documents">
          <span>
            <IconGroup />
          </span>
        </Tooltip>
      )}
      {params.row.hierarchy.length > 1 && (
        <Tooltip title={params.row.is_bookmarked ? 'Hide Bookmark' : 'Show Bookmark'}>
          <span
            onClick={() => params.onBookmarkClick(params.row.id, !params.row.is_bookmarked)}
            style={{ cursor: 'pointer' }}
          >
            <Bookmark {...bookmarkStyle} />
          </span>
        </Tooltip>
      )}
    </>
  );

  return (
    <EditHoverCellEffect
      params={params}
      cellValue={params.value}
      displayIcon={displayIcon}
      isEditable={isRowEditable(params.row)}
    />
  );
}
