/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Divider } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import ConfigurableViewFilter from '../Timeline/Components/ConfigurableViewFilter';
import TimelineDocumentViewSelector from '../Timeline/Components/TimelineDocumentViewSelector';
import DocumentListContainer from './DocumentListContainer';
import TimelineSettings from '../Timeline/TimelineSettings';
import useDocumentDescriptors from './gql/useDocumentDescriptors';
import { ListPagesSortByEnum } from '../Timeline/types/timelineTypes';
import useDocumentSearchStore from '../Timeline/useDocumentSearchStore';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import SearchBarWithNavigation from '../../components/Searchbar/SearchBarWithNavigation';

export default function DocumentView() {
  const { caseID, pageID } = useParams();

  const [filters, setFilters] = useDocumentSearchStore(
    (state) => [state.filters, state.setFilters],
    shallow,
  );
  const isFileProcessor = useIsFileProcessor() ?? false;

  const documentDescriptorQuery = useDocumentDescriptors({
    caseId: caseID,
    filters,
  });

  const pageList = useMemo(() => {
    return (
      documentDescriptorQuery.data?.documentListDescriptor.flatMap((doc) =>
        doc.pages.map((page) => ({
          ...page,
          documentID: doc.documentID[0],
        })),
      ) ?? []
    );
  }, [documentDescriptorQuery.data]);

  const handleFiltersChange = useCallback(
    (formFilters) => {
      documentDescriptorQuery.refetch({
        caseId: caseID,
        filters: formFilters,
      });
      setFilters(formFilters);
    },
    [documentDescriptorQuery, caseID, setFilters],
  );

  const navigate = useNavigate();

  // Navigate to first page if none selected
  useEffect(() => {
    if (!pageID && documentDescriptorQuery?.data?.documentListDescriptor) {
      const firstDocument = documentDescriptorQuery.data.documentListDescriptor[0];
      if (firstDocument && firstDocument.documentID && firstDocument.pages.length > 0) {
        navigate(`${firstDocument.documentID}/${firstDocument.pages[0].id}`);
      }
    }
  }, [pageID, documentDescriptorQuery]);

  return (
    <Box sx={{ height: '100%', backgroundColor: 'selectedGrey.main' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '.5rem',
          paddingTop: '1rem',
          paddingBottom: '0.2rem',
        }}
      >
        <Box display="flex">
          <Box paddingX=".5rem" flex={1}>
            <TimelineDocumentViewSelector caseID={caseID} />
          </Box>
          <Box>
            <TimelineSettings />
          </Box>
        </Box>
        <Divider sx={{ marginBottom: '0.5rem', marginTop: '0.8rem' }} />
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              padding: '.5rem',
              paddingBottom: '1rem',
            }}
          >
            <SearchBarWithNavigation caseID={caseID} pageList={pageList} type="documents" />
            {!isFileProcessor && (
              <ConfigurableViewFilter
                onSubmit={handleFiltersChange}
                sortingOptions={[
                  {
                    value: ListPagesSortByEnum.DateAscending,
                    label: 'Date Ascending',
                  },
                  {
                    value: ListPagesSortByEnum.DateDescending,
                    label: 'Date Descending',
                  },
                  { value: ListPagesSortByEnum.Document, label: 'Document' },
                ]}
              />
            )}
          </Box>
          <Divider />
        </>
      </Box>

      <Box
        sx={{
          overflowY: 'auto',
          paddingBottom: '6.6rem',
        }}
        id="timeline-document-view-container"
      >
        <DocumentListContainer documentDescriptorQuery={documentDescriptorQuery} caseID={caseID} />
      </Box>
    </Box>
  );
}
