import React from 'react';
import { useParams } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress, Typography, Button } from '@mui/material';
import { PageDate } from '../../__generated__/graphql';
import PageHighlight from './components/PageHighlight';
import { formatSegmentDate } from '../../library/utilities/useDates';
import useUpdateEntryDates from '../../containers/Timeline/gql/useUpdateEntryDates';
import { useAnnotations, Extraction } from './useAnnotations';

export default function DateHighlight({
  entryID,
  dates,
  selectedDate,
  rotation,
  setSelectedDate,
}: {
  entryID: string;
  dates: PageDate[];
  selectedDate: string;
  rotation: number;
  setSelectedDate: (date: string) => void;
}) {
  const { caseID = '' } = useParams();
  const [selected, setSelected] = React.useState<PageDate | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const { updateEntryDates, updateInProgress } = useUpdateEntryDates();
  const { data: fetchedDates } = useAnnotations(
    {
      caseID: caseID ?? '',
      documentId: Number(entryID),
      type: 'date',
    },
    !!caseID && !!entryID,
  );
  const proposedDates: Extraction[] = fetchedDates ?? [];

  if (!dates) {
    return null;
  }

  const minBoundingBoxDimension = 0.0001; //this is the minimum
  const filteredDates = dates.filter(
    (date) =>
      Math.abs(date.ex - date.sx) > minBoundingBoxDimension &&
      Math.abs(date.ey - date.sy) > minBoundingBoxDimension,
  );

  const clickDate = (event: React.SyntheticEvent, date: PageDate) => {
    setSelected(date);
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setSelected(null);
    setAnchorEl(null);
  };
  const updateDate = async () => {
    if (!selected) {
      return;
    }

    const updateEntryDatesInput: {
      newStartDate: string;
      newEndDate: string;
      entryStartDate: string;
      entryEndDate: string;
      dates: Extraction[];
      entryID: number;
    } = {
      entryID: Number(entryID),
      dates: proposedDates,
      newStartDate: selected.extracted_date,
      newEndDate: '',
      entryStartDate: selectedDate,
      entryEndDate: '',
    };

    await updateEntryDates(updateEntryDatesInput);
    setSelectedDate(selected.extracted_date);
    onClose();
  };

  return (
    <>
      {filteredDates.map((date, index) => (
        <PageHighlight
          key={index}
          boundingBox={{ sx: date.sx, sy: date.sy, ex: date.ex, ey: date.ey }}
          rotation={rotation}
          sx={{
            backgroundColor:
              selectedDate === date.extracted_date
                ? 'rgba(99, 237, 71, 0.3)'
                : 'rgba(253, 255, 50, 0.2)',
            border: `1px solid ${selectedDate === date.extracted_date ? 'green' : 'gold'}`,
            padding: '4px',
          }}
          onClick={selectedDate === date.extracted_date ? null : (e) => clickDate(e, date)}
        />
      ))}

      {selected && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          role={undefined}
          sx={{ zIndex: 2000 }}
          onClose={onClose}
        >
          <Paper variant="outlined" sx={{ p: '12px' }}>
            <Typography variant="caption">
              Set date to {formatSegmentDate(selected.extracted_date)}?
            </Typography>
            <Button
              onClick={updateDate}
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: '1rem' }}
              disabled={updateInProgress}
              endIcon={updateInProgress ? <CircularProgress size={12} /> : <CheckIcon />}
            >
              Yes
            </Button>
          </Paper>
        </Popover>
      )}
    </>
  );
}
