import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export default function Bookmark(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
      <path
        d="M0.87085 4.99836C0.87085 3.69759 0.87085 3.04721 1.124 2.55038C1.34667 2.11336 1.70198 1.75805 2.139 1.53537C2.63583 1.28223 3.28621 1.28223 4.58698 1.28223H7.99343C9.2942 1.28223 9.94458 1.28223 10.4414 1.53537C10.8784 1.75805 11.2337 2.11336 11.4564 2.55038C11.7096 3.04721 11.7096 3.69759 11.7096 4.99836V15.2177L6.2902 12.1209L0.87085 15.2177V4.99836Z"
        fill={props.fill ?? 'white'}
        stroke={props.color ?? '#344054'}
        strokeWidth="1.54839"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
