/* eslint-disable camelcase */
import { Close, KeyboardArrowDown, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo, useState, useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import Draggable from 'react-draggable';
import { useParams } from 'react-router-dom';
import { useIsFileProcessor } from '../AccountSettings/useFileProcessing';
import useSources from './gql/useSources';
import { selectors } from './useSourceContent';
import { useUserGroup } from '../MyCases/useCases';
import EntitySelect from './EntitySelect';
import useTimelineStore from './useTimelineStore';
import useEntities from './gql/useEntities';
import DateRangeSelector from './Components/DateRangeSelector';
import useUpdateEntryDates from './gql/useUpdateEntryDates';

function IntentDialog({
  open,
  setDialogOpen,
  source,
  setSource,
  startDate,
  endDate,
  handleChangeTimelineEntry,
  onClose,
  entry,
}) {
  const [author, setAuthor] = useState({ id: entry.author_id ?? null, name: null });
  const [org, setOrg] = useState({ id: entry.org_id ?? null, name: null });
  const [subject, setSubject] = useState({ id: entry.subject_id ?? null, name: null });
  const [newStartDate, setNewStartDate] = useState(startDate);
  const [newEndDate, setNewEndDate] = useState(endDate);
  const [dates, setDates] = useState([]);

  const sourcesQuery = useSources();
  const sourceTypes = useMemo(() => {
    const sourceMap = selectors.asMap(sourcesQuery ?? []);
    return Object.values(sourceMap);
  }, [sourcesQuery]);
  const params = useParams();
  const { pageID: currentPageID, caseID } = params;
  const { hiddenEntities } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
    }),
    shallow,
  );

  const isFileProcessor = useIsFileProcessor();
  const { data: userGroup } = useUserGroup();
  const processorOrLabeller = isFileProcessor || userGroup === 'Labeller';

  const { updateEntryDates } = useUpdateEntryDates();

  const {
    sortedOrgsForCase,
    sortedOrgsForEntry,
    allOrgs,
    sortedAuthorsForCase,
    sortedAuthorsForEntry,
    allAuthors,
  } = useEntities(caseID, entry?.id);

  const confirmTimelineEntryChanges = useCallback(async () => {
    await handleChangeTimelineEntry(source, author, subject, org, currentPageID);
    if (newStartDate !== startDate || newEndDate !== endDate) {
      updateEntryDates({
        entryStartDate: startDate,
        entryEndDate: endDate,
        newStartDate,
        newEndDate,
        entryID: entry.id,
        dates,
      });
    }
    setDialogOpen(false);
  }, [
    setDialogOpen,
    source,
    author,
    subject,
    org,
    currentPageID,
    startDate,
    endDate,
    newStartDate,
    newEndDate,
    entry.id,
    dates,
  ]);

  const filteredAuthors = useMemo(() => {
    return sortedAuthorsForCase.filter((author) => {
      return !hiddenEntities.includes(author.value) && !author.hidden;
    });
  }, [sortedAuthorsForCase, hiddenEntities]);

  const filteredOrgs = useMemo(() => {
    return sortedOrgsForCase.filter((org) => {
      return !hiddenEntities.includes(org.value) && !org.hidden;
    });
  }, [sortedOrgsForCase, hiddenEntities]);

  return (
    <Draggable>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={true}
        PaperProps={{
          sx: {
            width: '40%',
            minWidth: 500,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle
          fontSize="1rem"
          sx={{
            paddingBottom: 0,
            marginTop: '2%',
            fontWeight: 600,
          }}
        >
          Edit Timeline Entry
        </DialogTitle>
        <Typography
          sx={{
            fontSize: '0.8rem',
            marginLeft: '1.5rem',
            color: '#475467',
            paddingBottom: '1rem',
          }}
        >
          Please select a source type and page label as well as a date for your new entry.
        </Typography>
        <Divider />
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }}>
              <FormHelperText id="source-label" sx={{ ...labelStyle, mt: '16%' }}>
                Source Type
              </FormHelperText>
              <FormHelperText id="date-label" sx={{ ...labelStyle }}>
                Date
              </FormHelperText>
              {processorOrLabeller && (
                <>
                  <FormHelperText id="author-label" sx={{ ...labelStyle, mt: '2rem' }}>
                    Author
                  </FormHelperText>
                  <FormHelperText id="org-label" sx={{ ...labelStyle }}>
                    Organization
                  </FormHelperText>
                  <FormHelperText id="subject-label" sx={{ ...labelStyle }}>
                    Subject
                  </FormHelperText>
                </>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '2 1 0' }}>
              <FormControl
                sx={{
                  width: '100%',
                  mt: '6%',
                  mb: '6%',
                }}
              >
                <Select
                  value={source}
                  displayEmpty
                  labelId="source-label"
                  IconComponent={KeyboardArrowDown}
                  onChange={(e) => {
                    setSource(e.target.value);
                  }}
                  sx={{
                    fontSize: '0.8rem',
                  }}
                >
                  {sourceTypes
                    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
                    .filter((sourceOption) => !sourceOption.deprecated)
                    .map((sourceOption) => (
                      <MenuItem key={sourceOption.tag_id} value={sourceOption.tag_id}>
                        {sourceOption.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box
                sx={{
                  width: '100%',
                  marginLeft: '-1rem',
                  marginTop: '-1rem',
                }}
                name="timelineDate"
              >
                <DateRangeSelector
                  entryID={entry.id}
                  entryStartDate={startDate ?? ''}
                  entryEndDate={endDate ?? ''}
                  orientation="horizontal"
                  isPopUp={false}
                  shouldOpenByDefault={true}
                  hideTitle={true}
                  setNewStartDate={setNewStartDate}
                  setNewEndDate={setNewEndDate}
                  setDates={setDates}
                />
              </Box>
              {processorOrLabeller && (
                <>
                  <EntitySelect
                    entityName="author"
                    valueID={author.id}
                    optionsForCase={filteredAuthors}
                    optionsForEntry={sortedAuthorsForEntry}
                    allOptions={allAuthors}
                    setValues={(e) => setAuthor({ id: e.id, name: e.name })}
                  />
                  <EntitySelect
                    entityName="organization"
                    valueID={org.id}
                    optionsForCase={filteredOrgs}
                    optionsForEntry={sortedOrgsForEntry}
                    allOptions={allOrgs}
                    setValues={(e) => setOrg({ id: e.id, name: e.name })}
                  />
                  <EntitySelect
                    entityName="subject"
                    valueID={subject.id}
                    optionsForCase={filteredAuthors}
                    optionsForEntry={sortedAuthorsForEntry}
                    allOptions={allAuthors}
                    setValues={(e) => setSubject({ id: e.id, name: e.name })}
                  />
                </>
              )}
            </Box>
          </Box>
          <Divider sx={{ mt: '2rem' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button variant="outlined" classes="primary" onClick={onClose} sx={buttonStyles}>
              Cancel
            </Button>
            <Button
              variant="contained"
              classes="primary"
              onClick={() => confirmTimelineEntryChanges(isFileProcessor)}
              sx={buttonStyles}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
            onClick={() => setDialogOpen(false)}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}

export default function TimelineViewCardMenu({
  entry,
  currentSource,
  startDate,
  endDate,
  handleDownload,
  handleChangeTimelineEntry,
  entryID,
  rotateDocument,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [source, setSource] = useState();

  const isFileProcessor = useIsFileProcessor();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onDialogClose = () => {
    setDialogOpen(false);
  };
  const onDialogOpen = () => {
    setSource(currentSource || '');
    setDialogOpen(true);
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Entry settings">
          <IconButton
            id={`review-tab-entry-settings-btn-entry-id-${entryID}`}
            onClick={handleClick}
            size="small"
            edge="end"
            sx={{ paddingTop: '2px' }}
          >
            <MoreVert fontSize="inherit" style={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      </Box>
      {dialogOpen && (
        <IntentDialog
          open={dialogOpen}
          setDialogOpen={setDialogOpen}
          handleChangeTimelineEntry={handleChangeTimelineEntry}
          source={source}
          entry={entry}
          setSource={setSource}
          startDate={startDate}
          endDate={endDate}
          onClose={onDialogClose}
          entryID={entryID}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuList sx={{ width: 195, maxWidth: '100%', paddingBottom: '0.5rem' }}>
          {!isFileProcessor && <MenuItem onClick={handleDownload}>Download</MenuItem>}
          <MenuItem onClick={() => onDialogOpen()}>Edit</MenuItem>
          <MenuItem onClick={() => rotateDocument('clockwise')}>Rotate Clockwise</MenuItem>
          <MenuItem onClick={() => rotateDocument('counterclockwise')}>
            Rotate Counterclockwise
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

const labelStyle = {
  fontSize: '0.8rem',
  fontWeight: 500,
  marginLeft: 0,
  mt: 4.5,
};

const buttonStyles = {
  fontWeight: 600,
  mt: '4%',
  width: '40%',
  mx: 1,
};
