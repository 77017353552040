import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, TextField, Box } from '@mui/material';
import { Circle, Edit } from '@mui/icons-material';
import format from 'date-fns/format';
import CaseHeader from '../../Timeline/CaseHeader';
import CaseContext from '../CaseContext';
import './case-insights-container.css';
import useInsights, { CaseDetailsFields } from './useInsights';
import Theme from '../../../theme';
import GoToSource from '../../../components/icons/GoToSource';
import { useUser } from '../../../library/contexts/AuthContext';

export default function CaseInsightsDashboard() {
  const { caseInstance } = useContext(CaseContext);
  const { caseID } = useParams();
  const { user } = useUser();
  const isSupportAccount = user?.email === 'support@siftmed.ca';

  const {
    timelineID,
    handleUpdateCaseDetails,
    caseDetails,
    currentEditField,
    setCurrentEditField,
    imeReportSpecialtyInsights,
    filterAndViewDocuments,
  } = useInsights(caseID ?? '');

  const caseStatusText = caseStatusMap[caseDetails.caseStatus];
  const caseStatusColor = caseStatusColorMap[caseDetails.caseStatus];
  const caseStatusBackground = caseStatusBackgroundMap[caseDetails.caseStatus];

  return (
    <>
      <CaseHeader caseInstance={caseInstance} timelineID={timelineID} />
      <div
        id="case-insights"
        style={{
          padding: '16px',
        }}
      >
        <div
          className="details-box"
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '16px',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div id="case-details" style={{ paddingRight: '8px', width: '35%' }}>
              <CaseNameEdit
                currentValue={caseDetails.caseName}
                field="caseName"
                currentEditField={currentEditField}
                setCurrentEditField={setCurrentEditField}
                handleUpdateCaseDetails={handleUpdateCaseDetails}
              />
              <div style={{ display: 'flex', flexDirection: 'row', padding: '8px' }}>
                <span
                  className="details-chip"
                  style={{
                    backgroundColor: caseStatusBackground,
                    color: caseStatusColor,
                  }}
                >
                  {caseStatusText}
                </span>
                {caseDetails.caseType && (
                  <span
                    className="details-chip"
                    style={{
                      backgroundColor: Theme.palette.duplicatesByDocumentBlue.light,
                      color: Theme.palette.primary.dark,
                    }}
                  >
                    {caseDetails.caseType}
                  </span>
                )}
              </div>
              <CaseDetailsDateEdit
                currentValue={caseDetails.dateOfInjury}
                field="dateOfInjury"
                label="Date of Incident"
                currentEditField={currentEditField}
                setCurrentEditField={setCurrentEditField}
                handleUpdateCaseDetails={handleUpdateCaseDetails}
              />
              <CaseDetailsDateEdit
                currentValue={caseDetails.dueDate}
                field="dueDate"
                label="Due Date"
                currentEditField={currentEditField}
                setCurrentEditField={setCurrentEditField}
                handleUpdateCaseDetails={handleUpdateCaseDetails}
              />
            </div>
            <div id="case-description" style={{ paddingLeft: '8px', width: '65%' }}>
              <CaseDetailsTextEdit
                currentValue={caseDetails.claimReason?.substring(0, 75) ?? ''}
                field="claimReason"
                currentEditField={currentEditField}
                setCurrentEditField={setCurrentEditField}
                handleUpdateCaseDetails={handleUpdateCaseDetails}
              />
            </div>
          </div>
          <Divider style={{ padding: '8px' }} />
          <div>
            <div className="details-box-row" style={{ padding: '16px', paddingBottom: 0 }}>
              <span className="details-header">Subject</span>
            </div>
            <div>
              <CaseNameEdit
                currentValue={caseDetails.patientName}
                field="patientName"
                currentEditField={currentEditField}
                setCurrentEditField={setCurrentEditField}
                handleUpdateCaseDetails={handleUpdateCaseDetails}
              />
              <CaseDetailsDateEdit
                currentValue={caseDetails.dateOfBirth}
                field="dateOfBirth"
                label="Date of Birth"
                currentEditField={currentEditField}
                setCurrentEditField={setCurrentEditField}
                handleUpdateCaseDetails={handleUpdateCaseDetails}
              />
            </div>
          </div>
        </div>
        {imeReportSpecialtyInsights?.length > 0 && isSupportAccount && (
          <div className="tag-counts-box">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <span className="details-header">IME Reports</span>
              <Box
                sx={{ cursor: 'pointer', paddingLeft: '5px' }}
                onClick={() => filterAndViewDocuments(175)}
              >
                <GoToSource stroke="black" />
              </Box>
            </div>
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {imeReportSpecialtyInsights?.map((data: { tag: string; count: number }) => (
                <div
                  key={data.tag}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    minWidth: '50px',
                  }}
                >
                  <span>
                    <Circle
                      sx={{
                        fontSize: '0.75rem',
                        marginRight: '0.25rem',
                        color: 'green',
                      }}
                    />
                    <span className="details-value">{data.tag}</span>
                  </span>
                  <span style={{ marginLeft: '1rem' }} className="tag-count-number">
                    {data.count}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function CaseDetailsDateEdit({
  currentValue,
  field,
  label,
  currentEditField,
  setCurrentEditField,
  handleUpdateCaseDetails,
}: {
  currentValue: string;
  field: CaseDetailsFields;
  label: string;
  currentEditField: string | null;
  setCurrentEditField: (field: CaseDetailsFields) => void;
  handleUpdateCaseDetails: (field: CaseDetailsFields, value: string) => void;
}) {
  const formattedDate = currentValue ? format(new Date(currentValue), 'MMMM d, yyyy') : '';
  const [hover, setHover] = useState(false);
  const [currentEditValue, setCurrentEditValue] = useState(currentValue);

  return (
    <div
      className="details-box-row"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span className="details-label">{label}</span>
      {currentEditField === field ? (
        <div
          style={{ paddingLeft: '5px', height: '21px' }}
          onBlur={() => handleUpdateCaseDetails(field, currentEditValue)}
        >
          <TextField
            id={field}
            type="date"
            size="small"
            sx={{ height: '21px' }}
            value={currentEditValue}
            autoFocus
            onChange={(event) => setCurrentEditValue(event.target.value)}
            InputProps={{
              inputProps: {
                min: '1000-01-01',
                max: '2099-12-31',
                style: { fontSize: '14px', padding: '0 5px 0 5px' },
              },
              role: 'textbox',
            }}
          />
        </div>
      ) : (
        <span className="details-value">
          {`: ${formattedDate}`}
          {hover && (
            <Edit
              id={field}
              style={{
                flex: '0 0 auto',
                marginLeft: '1rem',
                marginTop: '-5px',
                position: 'relative',
                cursor: 'pointer',
                fontSize: '1.2rem',
              }}
              onClick={() => setCurrentEditField(field)}
            />
          )}
        </span>
      )}
    </div>
  );
}

function CaseDetailsTextEdit({
  currentValue,
  field,
  currentEditField,
  setCurrentEditField,
  handleUpdateCaseDetails,
}: {
  currentValue: string;
  field: CaseDetailsFields;
  currentEditField: string | null;
  setCurrentEditField: (field: CaseDetailsFields) => void;
  handleUpdateCaseDetails: (field: CaseDetailsFields, value: string) => void;
}) {
  const [hover, setHover] = useState(false);
  const [currentEditValue, setCurrentEditValue] = useState(currentValue);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onBlur={() => handleUpdateCaseDetails(field, currentEditValue)}
    >
      <div className="details-box-row" style={{ padding: '16px' }}>
        <span className="details-header">
          Description
          {hover && (
            <Edit
              id={field}
              style={{
                flex: '0 0 auto',
                marginLeft: '1rem',
                marginTop: '-5px',
                position: 'relative',
                cursor: 'pointer',
                fontSize: '1.2rem',
              }}
              onClick={() => setCurrentEditField(field)}
            />
          )}
        </span>
      </div>
      {currentEditField === field ? (
        <div>
          <TextField
            id={field}
            size="small"
            onChange={(event) => setCurrentEditValue(event.target.value)}
            defaultValue={currentValue}
            autoFocus
            sx={{ width: '80%' }}
            InputProps={{
              disableUnderline: true,
              sx: {
                margin: '0 8px 0 8px',
                fontSize: '14px',
              },
            }}
          />
        </div>
      ) : (
        <div className="details-box-row">
          <span
            className="details-value"
            style={{
              width: '80%',
              marginLeft: '4px',
              overflowWrap: 'normal',
              whiteSpace: 'normal',
            }}
          >
            {currentValue}
          </span>
        </div>
      )}
    </div>
  );
}

function CaseNameEdit({
  currentValue,
  field,
  currentEditField,
  setCurrentEditField,
  handleUpdateCaseDetails,
}: {
  currentValue: string;
  field: CaseDetailsFields;
  currentEditField: string | null;
  setCurrentEditField: (field: CaseDetailsFields) => void;
  handleUpdateCaseDetails: (field: CaseDetailsFields, value: string) => void;
}) {
  const [hover, setHover] = useState(false);
  const [currentEditValue, setCurrentEditValue] = useState(currentValue);
  return (
    <div
      className="details-box-row"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onBlur={() => handleUpdateCaseDetails(field, currentEditValue)}
      style={{ padding: '16px 16px 0 16px' }}
    >
      {currentEditField === field ? (
        <div>
          <TextField
            id={field}
            size="small"
            sx={{ padding: 0 }}
            onChange={(event) => setCurrentEditValue(event.target.value)}
            defaultValue={currentValue}
            variant="standard"
            autoFocus
            InputProps={{
              disableUnderline: true,
              inputProps: { style: { padding: '1px', fontSize: '18px' } },
            }}
          />
        </div>
      ) : (
        <div style={{ height: '28px' }}>
          <span
            className="details-header"
            style={{ fontWeight: field === 'patientName' ? 500 : 700 }}
          >
            {currentValue}
            {hover && (
              <Edit
                id={field}
                style={{
                  flex: '0 0 auto',
                  marginLeft: '1rem',
                  marginTop: '-5px',
                  position: 'relative',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                }}
                onClick={() => setCurrentEditField(field)}
              />
            )}
          </span>
        </div>
      )}
    </div>
  );
}

const caseStatusMap = {
  NEW: 'Files Required',
  PROCESSING: 'Processing',
  REQUIRES_PROCESSOR: 'Processing',
  READY: 'Open',
  CLOSED: 'Closed',
  ERROR: 'Error',
};
const caseStatusColorMap = {
  NEW: Theme.palette.duplicatesByDocumentRed.main,
  PROCESSING: Theme.palette.themeOrange.main,
  REQUIRES_PROCESSOR: Theme.palette.themeOrange.main,
  READY: Theme.palette.status.success,
  CLOSED: Theme.palette.modernGrey.main,
  ERROR: Theme.palette.error.main,
};

const caseStatusBackgroundMap = {
  NEW: Theme.palette.duplicatesByDocumentRed.light,
  PROCESSING: Theme.palette.themeOrange.lighter,
  REQUIRES_PROCESSOR: Theme.palette.themeOrange.lighter,
  READY: Theme.palette.lightSuccess.light,
  CLOSED: Theme.palette.selectedGrey.light,
  ERROR: Theme.palette.error.main,
};
