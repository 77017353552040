/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { shallow } from 'zustand/shallow';
import { ChangeTimelineEntryDetailsInput } from '../../../__generated__/graphql';
import config from '../../../config';
import TimelineViewCard from '../TimelineViewCard';
import {
  TimelineEntryDescriptor,
  TimelineEntry as TimelineEntryType,
} from '../types/timelineTypes';
import useDisplayStore from '../useDisplayStore';
import TimelineEntrySkeleton from './TimelineEntrySkeleton';
import useToggleFavouriteMutation from '../../Page/gql/useUpdatePageFavourite';
import useUpdatePageViewed from '../../Page/gql/updatePageViewed';
import { PageControls } from '../../Page/types/pageTypes';
import useNotes from '../../Notes/gql/useNotes';
import { maxPagesBeforeScroll } from '../Components/PageListView';
import { useIsFileProcessor } from '../../AccountSettings/useFileProcessing';
import GroupingModal from '../../DocumentGrouping/DocumentGroupingModal';
import { SearchMatch } from '../../../library/utilities/types/search';

export default function TimelineEntryList({
  listKey,
  initialScrollOffset,
  onScroll,
  height,
  outerRef,
  // pagination callbacks
  onLoadMoreItems,
  showGroupingButton,
  // data
  count,
  items,
  itemDescriptors,
  caseID,
  handleIsSegmentDownloading,
  setShowTimelineUpdated,
  updateTimelineEntry,
  pageSearchResultsMap,
  currentDocumentID,
}: TimelineListEntryProps) {
  const listRef = useRef<List | null>();
  const handleIsItemLoaded = useCallback((index: number) => Boolean(items?.[index]), [items]);
  const [
    showThumbnails,
    getThumbnailGridListHeight,
    thumbnailSize,
    timelineWidth,
    setGroupingPreviewPageID,
    groupingPreviewPageID,
  ] = useDisplayStore((state) => [
    state.showThumbnails,
    state.getThumbnailGridListHeight,
    state.thumbnailSize,
    state.timelineWidth,
    state.setGroupingPreviewPageID,
    state.groupingPreviewPageID,
  ]);

  const [changeFavouriteMark] = useToggleFavouriteMutation();
  const [updatePageToViewed] = useUpdatePageViewed();
  const { data: notes } = useNotes({ case_id: caseID });
  const isFileProcessor = useIsFileProcessor();

  const [groupingModalOpen, setGroupingModalOpen] = useState(false);

  const { setLastGroupingScrollPosition, lastGroupingScrollPosition } = useDisplayStore(
    (state) => ({
      setLastGroupingScrollPosition: state.setLastGroupingScrollPosition,
      lastGroupingScrollPosition: state.lastGroupingScrollPosition,
    }),
    shallow,
  );

  const handleOpenGroupingModal = useCallback(
    (pageID: number) => {
      setGroupingPreviewPageID(pageID);
      if (currentDocumentID === lastGroupingScrollPosition.fileID) {
        setLastGroupingScrollPosition({ documentID: null, position: 0, fileID: null });
      }
      setGroupingModalOpen(true);
    },
    [lastGroupingScrollPosition, currentDocumentID, setGroupingPreviewPageID],
  );

  const handleCloseGroupingModal = () => {
    setGroupingPreviewPageID(null);
    setGroupingModalOpen(false);
  };

  const calculateItemSize = useCallback(
    (index: number) =>
      getHeight({
        index,
        items: itemDescriptors,

        showThumbnails,
        getThumbnailGridListHeight,
      }),
    [itemDescriptors, showThumbnails, getThumbnailGridListHeight],
  );

  useEffect(() => {
    // recalculate the cached sizes
    // ref: https://react-window.vercel.app/#/api/VariableSizeList
    listRef.current?.resetAfterIndex(0, true);

    // adding to this list has huge performance implications.
  }, [thumbnailSize]);

  const pageControls: PageControls = useMemo(() => {
    return {
      handleToggleViewed: updatePageToViewed,
      handleToggleFavourite: changeFavouriteMark,
    };
  }, []);

  const renderEntry = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const entry = items[index];
      const descriptor = itemDescriptors[index];
      if (!entry) {
        return (
          <TimelineEntrySkeleton
            style={style}
            index={index}
            entryID={descriptor.id}
            countOfPages={descriptor.pages.length}
          />
        );
      }
      return (
        <Box id={`${entry.id}`} style={style}>
          <TimelineViewCard
            pageSearchResultsMap={pageSearchResultsMap}
            entry={entry}
            caseID={caseID}
            handleIsSegmentDownloading={handleIsSegmentDownloading}
            setShowTimelineUpdated={setShowTimelineUpdated}
            updateTimelineEntry={updateTimelineEntry}
            showGroupingButton={showGroupingButton}
            handleOpenGroupingModal={handleOpenGroupingModal}
            pageControls={pageControls}
            notes={notes}
            currentDocumentID={currentDocumentID}
          />
        </Box>
      );
    },
    [
      items,
      itemDescriptors,
      pageSearchResultsMap,
      caseID,
      handleIsSegmentDownloading,
      setShowTimelineUpdated,
      updateTimelineEntry,
      showGroupingButton,
      pageControls,
      notes,
      currentDocumentID,
    ],
  );

  return (
    <>
      {/* @ts-ignore */}
      <InfiniteLoader
        itemCount={count}
        isItemLoaded={handleIsItemLoaded}
        loadMoreItems={onLoadMoreItems}
        threshold={10}
        minimumBatchSize={config.timeline.gql.defaultTake}
      >
        {({ onItemsRendered, ref }) => (
          /*  @ts-ignore */
          <List
            key={showThumbnails ? `${listKey}-with-thumbnails` : `${listKey}-without-thumbnails`}
            className="List side-nav-list"
            initialScrollOffset={initialScrollOffset}
            onScroll={onScroll}
            height={height}
            itemCount={count}
            itemSize={calculateItemSize}
            ref={(r) => {
              ref(r);
              listRef.current = r;
            }}
            onItemsRendered={onItemsRendered}
            outerRef={outerRef}
            style={{ paddingBottom: isFileProcessor ? '6rem' : 0 }}
            overscanCount={1}
            width={timelineWidth}
          >
            {renderEntry}
          </List>
        )}
      </InfiniteLoader>
      <GroupingModal
        isOpen={groupingModalOpen}
        onClose={handleCloseGroupingModal}
        fileID={currentDocumentID ?? ''}
        caseID={caseID}
        pageID={groupingPreviewPageID}
      />
    </>
  );
}

type getHeightProps = {
  items: TimelineEntryDescriptor[];
  index: number;
  showThumbnails: boolean;
  getThumbnailGridListHeight: (numberOfPages: number) => number;
};

export const getHeight = ({
  items,
  index,
  showThumbnails,
  getThumbnailGridListHeight,
}: getHeightProps) => {
  const currentEntry = items[index];

  const numberOfPages = currentEntry?.pages.length;
  // if extend pages, use page list height
  const pageListHeight = showThumbnails
    ? getThumbnailGridListHeight(numberOfPages)
    : Math.min(numberOfPages * 26, maxPagesBeforeScroll * 26);

  return 130 + pageListHeight;
};

type TimelineListEntryProps = {
  listKey: string;
  initialScrollOffset: number;
  onScroll: ({ scrollOffset }: { scrollOffset: number }) => void;
  height: number;
  outerRef: React.Ref<any>;
  onLoadMoreItems: (from: number, to: number) => void;
  items: TimelineEntryType[];
  itemDescriptors: TimelineEntryDescriptor[];
  count: number;
  caseID: string;
  handleIsSegmentDownloading: Function;
  updateTimelineEntry: (params: ChangeTimelineEntryDetailsInput) => Promise<unknown>;
  setShowTimelineUpdated: (show: boolean) => void;
  showGroupingButton: boolean;
  pageSearchResultsMap: Record<string, SearchMatch[]>;
  currentDocumentID?: string;
};
