import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import Modal from '../../components/common/HTML_components/Modal/Modal';
import CloseIcon from '../../components/icons/Close';
import DocumentGroupingContainer from './DocumentGroupingContainer';
import { useGroupings } from './utils/documentState';
import useDisplayStore from '../Timeline/useDisplayStore';

type GroupingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fileID: string;
  caseID: string;
  pageID: string;
};

export default function GroupingModal({
  isOpen,
  onClose,
  fileID,
  caseID,
  pageID,
}: GroupingModalProps) {
  const {
    pages,
    status,
    canBuildGroupings,
    groupingActionOnClicks,
    getButtonEnabledStatuses,
    depthAt,
    pageIndexById,
    groupIDByPageID,
    attachmentIDByPageID,
    groupedDocumentTree,
    startPage,
    endPage,
    onChangePageRange,
    documentScrollerCompatiblePages,
    buildGroupings,
  } = useGroupings(caseID ?? '', fileID ?? '');

  useEffect(() => {
    if (isOpen && pageID) {
      const currentGroup = groupIDByPageID[pageID];
      const startPageID =
        Object.keys(groupIDByPageID).find((key) => groupIDByPageID[key] === currentGroup - 1) ??
        Object.keys(groupIDByPageID)[0];
      const endPageGroup = Object.keys(groupIDByPageID).filter(
        (key) => groupIDByPageID[key] === currentGroup + 1,
      );
      const endPageID = endPageGroup.length
        ? endPageGroup[endPageGroup.length - 1]
        : Object.keys(groupIDByPageID)[Object.keys(groupIDByPageID).length - 1];

      const startPageNumber = pages?.[pageIndexById.get(startPageID) ?? 0]?.page_number;
      const endPageNumber = pages?.[pageIndexById.get(endPageID) ?? 0]?.page_number;
      if (startPageNumber || endPageNumber) {
        onChangePageRange(startPageNumber ?? 0, endPageNumber ?? 0);
      }
    }
  }, [isOpen]);

  const [setGroupingPreviewPageID] = useDisplayStore((state) => [state.setGroupingPreviewPageID]);

  const handlePageChange = (nextIndex: number) => {
    const nextPage = pages?.[nextIndex - 1];
    setGroupingPreviewPageID(nextPage.id);
  };

  const handleChangePageRange = (start: number, end: number) => {
    const startPageID = onChangePageRange(start, end);
    setGroupingPreviewPageID(startPageID);
  };

  const buildGroupingsOnClick = async () => {
    if (endPage && startPage) {
      const { status: buildGroupingsStatus } = await buildGroupings(startPage, endPage, false);
      if (buildGroupingsStatus === 'rejected') {
        toast.error('Failed to build groupings');
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ height: '95%', width: '95%' }}>
      <div style={{ width: '100%', height: '100%' }} id="grouping-previewer-modal">
        <div
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '1.5rem',
            paddingBottom: 0,
            width: '100%',
            overflow: 'hidden',
            zIndex: 1,
          }}
        >
          <div>Edit Grouping</div>
          <button
            className="sm-icon-button"
            type="button"
            style={{ position: 'absolute', top: '1rem', right: '1rem' }}
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </div>
        <DocumentGroupingContainer
          pageID={String(pageID)}
          pages={pages}
          depthAt={depthAt}
          groupingActionOnClicks={groupingActionOnClicks}
          pageIndexById={pageIndexById}
          groupIDByPageID={groupIDByPageID}
          attachmentIDByPageID={attachmentIDByPageID}
          getButtonEnabledStatuses={getButtonEnabledStatuses}
          groupedDocumentTree={groupedDocumentTree}
          startPage={startPage}
          endPage={endPage}
          onChangePageRange={handleChangePageRange}
          documentScrollerCompatiblePages={documentScrollerCompatiblePages}
          onChangePage={handlePageChange}
          groupingType="preview"
        />
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: 'fit-content',
          marginRight: '2rem',
          marginBottom: '1.5rem',
        }}
      >
        {status === 'staged' || status === 'saving' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
            <span
              style={{
                marginLeft: '0.5rem',
              }}
            >
              {' '}
              Update In Progress
            </span>
          </div>
        ) : (
          <button
            type="button"
            className="sm-button"
            disabled={status === 'loading' || status === 'errored'}
            onClick={buildGroupingsOnClick}
            style={{
              backgroundColor: 'var(--color-primary-main)',
              color: 'white',
              opacity: status === 'loading' || status === 'errored' ? 0.5 : 1,
            }}
          >
            Save Groupings
          </button>
        )}
      </div>
    </Modal>
  );
}
