import Box from '@mui/material/Box';
import FeedbackPopup from '../../../components/common/FeedbackPopup';

export default function ActionFooter({
  setShowTimelineUpdated,
  showTimelineUpdated,
}: ActionFooterProps) {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        bottom: 16,
        marginBottom: 0,
      }}
    >
      <FeedbackPopup
        text="Timeline successfully updated"
        open={showTimelineUpdated}
        onClose={() => setShowTimelineUpdated(false)}
      />
    </Box>
  );
}

type ActionFooterProps = {
  setShowTimelineUpdated: (value: boolean) => void;
  showTimelineUpdated: boolean;
};
