import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { CreateIndexTemplateBody } from '../../../../api/index-management/types';
import { createNewIndexTemplate } from '../../../../api/index-management/api';

export function useCreateIndexTemplate() {
  const [shouldGenerateReports, setShouldGenerateReports] = useState<boolean>(true);
  const [sectionsError, setSectionsError] = useState<string[] | null>(null);
  const [excludePagesError, setExcludePagesError] = useState<string[] | null>(null);
  const [excludeDocumentsError, setExcludeDocumentsError] = useState<string[] | null>(null);
  const [collapseDocumentsError, setCollapseDocumentsError] = useState<string[] | null>(null);
  const [nameError, setNameError] = useState<string[] | null>(null);

  const navigate = useNavigate();

  const baseIndexTemplate = useMemo(
    () => ({
      name: 'Index Template',
      show_empty_sections: 0,
      exclude_pages: ['has', ['tag'], 'in', '141', '142'],
      exclude_documents: null,
      collapse_documents: null,
      group_attachments: 0,
      should_autogenerate: 1,
      sections: [
        {
          name: 'All Documents',
          rules: ['true'],
          columns: [
            'document-name',
            'document-tags',
            'organization',
            'author',
            'document-date',
            'number-pages',
          ],
          order_by: [['asc', ['field', 'document-date']]],
          collapse_documents: 0,
        },
      ],
    }),
    [],
  );

  const createIndexTemplate = useCallback(
    async (values: CreateIndexTemplateBody) => {
      try {
        await createNewIndexTemplate({
          ...values,
          shouldGenerateReports,
        });
        setSectionsError(null);
        setExcludePagesError(null);
        setExcludeDocumentsError(null);
        setCollapseDocumentsError(null);
        setNameError(null);

        toast.success('Successfully Created New Index Template');
        navigate('/admin/index');
      } catch (error) {
        toast.error('Failed to create index template. Please check error messages and try again.');

        const errorFields = error?.response?.data;

        if (errorFields == null) {
          return;
        }

        if (errorFields.sections) {
          setSectionsError(errorFields.sections);
        }
        if (errorFields.exclude_pages) {
          setExcludePagesError(errorFields.exclude_pages);
        }
        if (errorFields.exclude_documents) {
          setExcludeDocumentsError(errorFields.exclude_documents);
        }
        if (errorFields.collapse_documents) {
          setCollapseDocumentsError(errorFields.collapse_documents);
        }
        if (errorFields.name) {
          setNameError(errorFields.name);
        }
      }
    },
    [shouldGenerateReports],
  );

  const errors = useMemo(
    () => ({
      sections: sectionsError,
      exclude_pages: excludePagesError,
      exclude_documents: excludeDocumentsError,
      collapse_documents: collapseDocumentsError,
      name: nameError,
    }),
    [sectionsError, excludePagesError, excludeDocumentsError, collapseDocumentsError, nameError],
  );

  const clearErrors = useCallback(() => {
    setSectionsError(null);
    setExcludePagesError(null);
    setExcludeDocumentsError(null);
    setCollapseDocumentsError(null);
    setNameError(null);
  }, [
    setSectionsError,
    setExcludePagesError,
    setExcludeDocumentsError,
    setCollapseDocumentsError,
    setNameError,
  ]);

  return {
    baseIndexTemplate,
    createIndexTemplate,
    shouldGenerateReports,
    setShouldGenerateReports,
    errors,
    clearErrors,
  };
}
