import React, { useCallback, useMemo } from 'react';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import { useStringifiedIndexDetails } from './hooks/useStringifiedIndexDetails';
import IndexSchemaForm from './components/IndexSchemaForm';
import { useCreateIndexTemplate } from './hooks/useCreateIndexTemplate';
import AdminHeader from '../AdminHeader';
import PageHeader from '../../../components/common/PageHeader';
import UpArrowWithTail from '../../../components/icons/UpArrowWithTail';

export default function CreateIndexTemplate() {
  const {
    baseIndexTemplate,
    createIndexTemplate,
    shouldGenerateReports,
    setShouldGenerateReports,
    errors: validationErrors,
    clearErrors: clearValidationErrors,
  } = useCreateIndexTemplate();

  const {
    indexDetails,
    onChanges,
    getParsedIndexDetails,
    errors: jsonParseErrors,
  } = useStringifiedIndexDetails(baseIndexTemplate);

  const navigate = useNavigate();

  const onCreateNewTemplate = useCallback(async () => {
    clearValidationErrors();
    await createIndexTemplate(getParsedIndexDetails());
  }, [getParsedIndexDetails, clearValidationErrors]);

  const errors: Record<
    'sections' | 'exclude_pages' | 'exclude_documents' | 'collapse_documents' | 'name',
    string[] | null
  > = useMemo(() => {
    return {
      sections: jsonParseErrors.sections ?? validationErrors.sections,
      exclude_pages: jsonParseErrors.exclude_pages ?? validationErrors.exclude_pages,
      exclude_documents: jsonParseErrors.exclude_documents ?? validationErrors.exclude_documents,
      collapse_documents: jsonParseErrors.collapse_documents ?? validationErrors.collapse_documents,
      name: validationErrors.name,
    };
  }, [jsonParseErrors, validationErrors]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginY: '2rem',
      }}
    >
      <AdminHeader />
      <PageHeader title="Create Index Template" />
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/admin/index')}
      >
        <UpArrowWithTail style={{ transform: 'rotate(-90deg)' }} />
        <span style={{ paddingLeft: '0.5rem' }}>Return to Templates</span>
      </div>
      <IndexSchemaForm indexDetails={indexDetails} onChanges={onChanges} errors={errors} />
      <div style={{ display: 'flex', marginBottom: '2rem' }}>
        <input
          type="checkbox"
          className="sm-checkbox"
          style={{ marginRight: '1rem' }}
          checked={shouldGenerateReports}
          onChange={() => setShouldGenerateReports(!shouldGenerateReports)}
        />
        <span
          style={{
            fontWeight: 500,
          }}
        >
          Insert as Report on Every Existing Case
        </span>
      </div>
      <button
        type="button"
        className="sm-button-primary"
        onClick={onCreateNewTemplate}
        style={{ width: 'fit-content' }}
      >
        Create New Index Template
      </button>
    </Container>
  );
}
