import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import UpArrowIcon from '../../../icons/UpArrow';
import DownArrowIcon from '../../../icons/DownArrow';
import './multi-select-dropdown.css';

type MenuPosition = {
  top: number;
  left: number;
} | null;

type Option = {
  label: string;
  value: string;
};

type Props = {
  selectedValues: string[];
  options: Option[];
  onChange: (newOptions: string[]) => void;
  shouldOpenByDefault?: boolean;
  selectAll?: () => void;
};

export default function MultiSelectDropdownDates({
  selectedValues,
  options,
  onChange,
  shouldOpenByDefault = false,
  selectAll,
}: Props) {
  const [isOpen, setIsOpen] = useState(shouldOpenByDefault);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>(null);
  const anchorRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const rect = anchorRef.current ? anchorRef.current?.getBoundingClientRect() : null;
      if (rect) {
        setMenuPosition({
          top: rect.top + rect.height + window.scrollY,
          left: rect.left + window.scrollX,
        });
      }
    };
    document.getElementById('app-container')?.addEventListener('scroll', handleScroll);
    document
      .getElementById('scrollable-report-container')
      ?.addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById('app-container')?.removeEventListener('scroll', handleScroll);
      document
        .getElementById('scrollable-report-container')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const rect = anchorRef.current ? anchorRef.current.getBoundingClientRect() : null;
    if (isOpen && rect) {
      setMenuPosition({
        top: rect.top + rect.height + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isOpen]);

  const handleDropdownOptionOnClick = (optionChanging: string) => {
    if (selectedValues.includes(optionChanging)) {
      onChange(selectedValues?.filter((selected) => optionChanging !== selected));
    } else {
      onChange([...selectedValues, optionChanging]);
    }
  };

  const handleToggleDropdownOpen = () => {
    setIsOpen(!isOpen);
  };

  const dropdownMenu = menuPosition != null && (
    <div
      className="dropdown-menuList"
      ref={dropdownRef}
      style={{
        transform: `translate3d(${menuPosition.left}px, ${menuPosition.top}px, 0)`,
        width: '20rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          marginLeft: '0.5rem',
          marginTop: '0.5rem',
          flexDirection: 'row',
        }}
        onMouseDown={(e) => e.preventDefault()}
      >
        <button
          type="button"
          style={{
            color: 'primary.light',
            fontSize: '0.7rem',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
          }}
          onClick={selectAll}
        >
          Select all
        </button>
        <button
          type="button"
          style={{
            color: 'primary.light',
            fontSize: '0.7rem',
            marginLeft: '1.5rem',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
          }}
          onClick={() => onChange([])}
        >
          Clear all
        </button>
      </div>
      {options
        .sort((a, b) => a.value?.localeCompare(b.value))
        .map((option) => {
          return (
            <div
              key={option.value}
              className="dropdown-menuItem"
              onClick={() => handleDropdownOptionOnClick(option.value)}
              style={{
                backgroundColor: selectedValues.includes(option.value)
                  ? 'var(--color-selected-grey-main)'
                  : 'white',
              }}
            >
              <input type="checkbox" checked={selectedValues.includes(option.value)} readOnly />
              <span
                style={{
                  paddingLeft: '1rem',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                }}
              >
                {option.label}
              </span>
            </div>
          );
        })}
    </div>
  );

  const iconStyle = {
    width: '20px',
    height: '20px',
  };

  return (
    <div
      className="input-container"
      ref={anchorRef}
      style={{
        margin: '0.5rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      }}
    >
      <input
        className="input"
        placeholder={
          selectedValues?.length > 0 ? `${selectedValues?.length} selected` : 'Select from list'
        }
        style={{ paddingLeft: '0.5rem' }}
        onClick={handleToggleDropdownOpen}
      />
      <div className="dropdown" onClick={handleToggleDropdownOpen}>
        <div className="dropdown-selectedValues" />
        {isOpen ? <UpArrowIcon style={iconStyle} /> : <DownArrowIcon style={iconStyle} />}
      </div>
      {isOpen && ReactDOM.createPortal(dropdownMenu, document.body)}
    </div>
  );
}
