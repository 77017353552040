import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateEntrySourceMutation } from '../../../__generated__/graphql';
import { DocumentTag, DocumentTagFilter } from '../../../api';

export default function useUpdateEntryDetails() {
  const [sourceMutation, sourceState] = useUpdateEntrySourceMutation();
  const client = useApolloClient();
  const queryClient = useQueryClient();

  const updateEntrySource = useCallback(
    async ({
      entryID,
      caseID,
      source,
    }: {
      entryID: number;
      caseID: string;
      source: DocumentTag;
    }) => {
      try {
        await queryClient.cancelQueries(['case-document-tags', caseID]);
        const caseTags: { sourceTags: DocumentTagFilter[] } | undefined =
          await queryClient.getQueryData(['case-document-tags', caseID]);
        let newCaseSources = caseTags?.sourceTags ?? [];
        if (!newCaseSources.some((tag) => source.tag_id === tag.tag_id)) {
          newCaseSources.push({
            tag_id: Number(source.tag_id),
            name: source.name,
            category_id: 1,
            value: Number(source.tag_id),
            label: source.name,
          });
          queryClient.setQueryData(['case-document-tags', caseID], {
            ...caseTags,
            sourceTags: newCaseSources,
          });
        }

        return sourceMutation({
          variables: {
            data: { entryID, sourceID: source.tag_id },
          },

          update: (cache) => {
            const entry: { id: number; pages: { id: number }[] } | null = cache.readFragment({
              id: cache.identify({
                __typename: 'TimelineEntryObject',
                id: entryID,
              }),
              fragment: entryUpdateFragment,
            });

            if (!entry) {
              return;
            }

            const sourceID = source.tag_id;
            cache.modify({
              id: cache.identify({
                __typename: 'TimelineEntryObject',
                id: entryID,
              }),
              fields: {
                source_id() {
                  return sourceID;
                },
              },
            });
            // Update all pages associated with the entry
            entry.pages.forEach((page) => {
              cache.modify({
                id: cache.identify({
                  __typename: 'PageObject',
                  id: page.id,
                }),
                fields: {
                  source() {
                    return {
                      id: sourceID,
                      name: source.name,
                      type: 'source',
                      confidence: 0,
                    };
                  },
                },
              });
            });
          },
        });
      } catch (error) {
        toast.error(
          'A problem occured while updating Entry Source. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        throw error;
      }
    },
    [sourceMutation, client],
  );

  return useMemo(() => {
    return { updateEntrySource, sourceState };
  }, [updateEntrySource, sourceState]);
}

const entryUpdateFragment = gql`
  fragment Pages on TimelineEntryObject {
    id
    pages {
      id
    }
  }
`;
