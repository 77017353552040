import React, { useState, useMemo, useEffect } from 'react';
import MultiSelectDropdownDates from '../../../components/common/HTML_components/MultiSelectDropdown/MultiSelectDropdownDates';
import Modal from '../../../components/common/HTML_components/Modal/Modal';
import Theme from '../../../theme';
import CloseIcon from '../../../components/icons/Close';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentlySelectedDates: Array<string>;
  handleChangeSelectedDates: (values: Array<string>) => void;
  allDates: Array<string>;
};

export default function UploadDateFilterModal({
  isOpen = false,
  onClose,
  currentlySelectedDates,
  handleChangeSelectedDates,
  allDates,
}: Props) {
  const [selectedDateInputValues, setSelectedDateInputValues] = useState<string[]>([]);
  useEffect(() => {
    setSelectedDateInputValues(
      currentlySelectedDates?.length > 0 ? currentlySelectedDates : allDates,
    );
  }, [currentlySelectedDates, allDates]);

  const resetAppliedDate = () => {
    if (
      selectedDateInputValues?.length !== allDates?.length &&
      selectedDateInputValues?.length !== 0
    ) {
      handleChangeSelectedDates(allDates);
    }
    setSelectedDateInputValues(allDates);
  };

  const submitUpdatedFilterDate = () => {
    handleChangeSelectedDates(selectedDateInputValues);
    onClose();
  };

  const onCloseModal = () => {
    setSelectedDateInputValues(currentlySelectedDates ?? allDates);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <UploadDateFilterModalContent
        onClose={onCloseModal}
        allDates={allDates}
        submitUpdatedFilterDate={submitUpdatedFilterDate}
        selectedDateInputValues={selectedDateInputValues}
        setSelectedDateInputValues={setSelectedDateInputValues}
        resetAppliedDate={resetAppliedDate}
        style={{
          width: 'fit-content',
        }}
      />
    </Modal>
  );
}

type UploadDateFilterModalContentProps = {
  onClose: () => void;
  submitUpdatedFilterDate: () => void;
  selectedDateInputValues: string[];
  setSelectedDateInputValues: (newOptions: string[]) => void;
  resetAppliedDate: () => void;
  allDates: string[];
  style?: React.CSSProperties;
};

function UploadDateFilterModalContent({
  onClose,
  submitUpdatedFilterDate,
  selectedDateInputValues,
  setSelectedDateInputValues,
  resetAppliedDate,
  allDates,
}: UploadDateFilterModalContentProps) {
  const handleReset = () => {
    resetAppliedDate();
    onClose();
  };
  const options = useMemo(() => {
    return allDates?.map((date) => ({
      value: date,
      label: `${
        date !== '1900-01-01'
          ? new Date(date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              timeZone: 'UTC',
            })
          : 'No date'
      } `,
    }));
  }, [allDates]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '23rem',
        padding: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <UploadDateFilterModalHeader />
        <span>
          <button
            id="reports-tab-index-reset-filter-btn"
            type="button"
            className="sm-button"
            style={{
              border: 'none',
              backgroundColor: 'white',
              marginRight: '0.25rem',
              color: Theme.palette.primary.main,
              fontSize: '0.9rem',
            }}
            tabIndex={-1}
            onClick={handleReset}
          >
            Reset
          </button>
          <CloseIcon onClick={onClose} />
        </span>
      </div>
      <hr className="sm-horizontal-divider" style={{ marginTop: '0.5rem' }} />
      <span
        style={{
          fontSize: '0.9rem',
          fontWeight: 500,
        }}
      >
        File Upload Dates
      </span>

      <MultiSelectDropdownDates
        options={options}
        selectedValues={selectedDateInputValues}
        onChange={setSelectedDateInputValues}
        shouldOpenByDefault={false}
        selectAll={() => setSelectedDateInputValues(allDates)}
      />
      <UploadDateFilterModalFooter
        onClose={onClose}
        onSubmit={submitUpdatedFilterDate}
        selectedValues={selectedDateInputValues}
      />
    </div>
  );
}

function UploadDateFilterModalHeader() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.5rem',
      }}
    >
      <h6>Filter</h6>
    </div>
  );
}

type UploadDateFilterModalFooterProps = {
  onClose: () => void;
  onSubmit: () => void;
  selectedValues?: string[];
};

function UploadDateFilterModalFooter({
  onClose,
  onSubmit,
  selectedValues,
}: UploadDateFilterModalFooterProps) {
  const noDates = selectedValues?.length === 0;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <button
        id="reports-tab-index-cancel-filter-btn"
        type="button"
        onClick={onClose}
        style={{
          marginRight: '0.5rem',
        }}
        className="sm-button"
      >
        Cancel
      </button>
      <button
        id="reports-tab-index-apply-filter-btn"
        type="button"
        className="sm-button"
        style={{
          backgroundColor: 'var(--color-primary-main-light)',
          opacity: noDates ? 0.5 : 1,
          color: 'white',
          cursor: noDates ? 'not-allowed' : 'pointer',
        }}
        disabled={noDates}
        onClick={onSubmit}
        title={noDates ? 'Please select a value' : ''}
      >
        Apply
      </button>
    </div>
  );
}
