import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { createAnnotations, Annotation, dismissAnnotations } from '../../../api';
import { isValidDate } from '../../../library/utilities/useDates';

export default function useUpdateEntryDates() {
  const client = useApolloClient();
  const { caseID } = useParams();
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const updateEntryDates = useCallback(
    async (params: {
      entryID: number;
      newStartDate: string;
      newEndDate: string;
      entryStartDate: string;
      entryEndDate: string;
      dates: any[];
    }) => {
      setUpdateInProgress(true);
      const { entryID, dates, newEndDate, newStartDate, entryEndDate, entryStartDate } = params;
      let datesToDismiss: any[] = [];
      const inputChange = [];
      const isStartDateChanged = newStartDate !== entryStartDate;
      const isEndDateChanged = newEndDate !== entryEndDate;
      let startDate: Annotation | undefined;
      let endDate: Annotation | undefined;

      if (isStartDateChanged) {
        if (isValidDate(newStartDate)) {
          const extraction = dates.find((date) => date.value === newStartDate);
          startDate = {
            type: 'date',
            field: 'start-date',
            value: newStartDate,
            extractionId: extraction?.extraction_id,
            pageId: extraction?.page_id,
            kind: extraction ? 'selection' : 'annotation',
          };
        }
        const undismissedStartDates = dates
          ?.filter((date) => date.field === 'start-date' && date.selection_status !== 'Dismissed')
          .map((date) => ({
            selectionId: date.selection_id,
            kind: 'selection',
            extractionId: date.extraction_id,
          }));
        datesToDismiss = [...undismissedStartDates];
        inputChange.push({ value: newStartDate, label: 'start-date' });
      }
      if (isEndDateChanged) {
        if (isValidDate(newEndDate)) {
          const extraction = dates.find((date) => date.value === newEndDate);
          endDate = {
            type: 'date',
            field: 'end-date',
            value: newEndDate,
            extractionId: extraction?.extraction_id,
            pageId: extraction?.page_id,
            kind: extraction ? 'selection' : 'annotation',
          };
        }
        const undismissedEndDates = dates
          ?.filter((date) => date.field === 'end-date' && date.selection_status !== 'Dismissed')
          .map((date) => ({
            selectionId: date.selection_id,
            kind: 'selection',
            extractionId: date.extraction_id,
          }));
        datesToDismiss = [...datesToDismiss, ...undismissedEndDates];
        inputChange.push({ value: newEndDate, label: 'end-date' });
      }

      const fields: any = {
        start_date: () => startDate?.value,
      };
      if (isEndDateChanged) {
        fields.end_date = () => endDate?.value;
      }

      try {
        const datesToUpdate: Annotation[] = [];
        if (startDate) {
          datesToUpdate.push(startDate);
        }
        if (endDate) {
          datesToUpdate.push(endDate);
        }

        await createAnnotations({
          caseID: caseID ?? '',
          documentId: entryID,
          annotations: datesToUpdate,
        });

        if (datesToDismiss.length > 0) {
          await dismissAnnotations({
            caseID: caseID ?? '',
            documentId: entryID,
            annotations: datesToDismiss,
          });
        }
        const entry: { id: number; entry_date: Date; pages: { id: number }[] } | null =
          client.cache.readFragment({
            id: client.cache.identify({
              __typename: 'TimelineEntryObject',
              id: +entryID,
            }),
            fragment: entryUpdateFragment,
          });
        if (!entry) {
          setUpdateInProgress(false);
          return;
        }
        client.cache.modify({
          id: client.cache.identify({
            __typename: 'TimelineEntryObject',
            id: entryID,
          }),
          fields,
        });
        setUpdateInProgress(false);
      } catch (error) {
        toast.error(
          'A problem occured while updating Entry date. If issues persist, please try refreshing the page.',
        );
        Sentry.captureException(error);
        setUpdateInProgress(false);
        throw error;
      }
    },
    [client],
  );

  return useMemo(() => {
    return { updateEntryDates, updateInProgress };
  }, [updateEntryDates, updateInProgress]);
}

const entryUpdateFragment = gql`
  fragment EntryDate on TimelineEntryObject {
    id
    start_date
    end_date
  }
`;
