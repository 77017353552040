import {
  Alert,
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useMemo, useState } from 'react';
import { useUser } from '../../library/contexts/AuthContext';
import { useFetchTemplates } from '../../containers/Templates/TemplateSelection';
import apiUrl from '../../library/utilities/apiUrl';
import Templates from '../icons/Templates';
import Loading from '../common/Loading';
import tableIcon from '../../resources/images/table_icon.png';
import reportIcon from '../../resources/images/written_report_icon.png';
import RadioCardSelector from '../common/Selectors/RadioCardSelectors/RadioCardSelector';

/*
  This is temporarily disabled while the index MVP is being built.
  Once completed, we can remove this flag and users will have the option to build
  written reports, or table reports.
*/

const SHOULD_USE_NEW_CREATE_REPORT_MODAL = false;

/**
 * Modal for creating a new document.
 *
 */
// Temporary setup while index is stage specific
function FlaggedDocumentModal({ isOpen = false, onModalClose = null, onCreateNewFile }) {
  return SHOULD_USE_NEW_CREATE_REPORT_MODAL ? (
    <NewDocumentModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      onCreateNewFile={onCreateNewFile}
    />
  ) : (
    <LegacyDocumentModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      onCreateNewFile={onCreateNewFile}
    />
  );
}

export default FlaggedDocumentModal;

export const useGetTemplate = (user) => {
  return async (template) => {
    if (template) {
      const { templateId, templateName } = template;
      const docObj = {
        username: user.username,
        templateId: parseInt(templateId, 10),
        templateName,
      };
      try {
        const existing = await axios.post(`${apiUrl}getTemplate`, docObj);
        return existing.data;
      } catch (e) {
        console.log(e);
        toast.error('Failed to get template...', {
          toastId: 'get-template',
        });
      }
    }
  };
};

export const DocumentModalStates = {
  CLOSED: null,
  NEW_DOCUMENT: 'new_document',
  NEW_TEMPLATE: 'new_template',
};

function checkIfReportNameIsValid(name) {
  const reg = /^[a-zA-Z0-9 _-]+$/;
  return name.match(reg) && name.length <= 100 && name.trim();
}

const templatesList = (templates) =>
  templates.map((template, i) => (
    <MenuItem key={i} value={template}>
      {template.templateName}
    </MenuItem>
  ));

function LegacyDocumentModal({ isOpen = false, onModalClose = null, onCreateNewFile }) {
  const [newDocumentName, setDocName] = useState('');
  const [creatingDoc, setCreatingDoc] = useState(false);
  const [template, setTemplate] = useState('');
  const [privateTemplate] = useState(false);
  const { user } = useUser();
  const { isLoading, templates } = useFetchTemplates(user.username);
  const getTemplate = useGetTemplate(user);

  useEffect(() => {
    if (isOpen) {
      setCreatingDoc(false);
      setDocName('');
      setTemplate('');
    }
  }, [isOpen]);

  async function createDocument() {
    if (!checkIfReportNameIsValid(newDocumentName)) {
      return;
    }
    setCreatingDoc(true);
    const templateSfdt =
      template !== ''
        ? await getTemplate(template)
        : JSON.stringify(require('../../resources/templates/emptySfdt.json'));

    await onCreateNewFile(
      {
        fileName: newDocumentName,
        userID: user.username,
        private: privateTemplate ? 1 : 0,
      },
      templateSfdt,
    );
    onModalClose();
  }

  function handleTemplateSelect(event) {
    setTemplate(event.target.value);
  }

  const reportNameIsValid = checkIfReportNameIsValid(newDocumentName);
  return (
    <Dialog
      onClose={onModalClose}
      aria-labelledby="dialogTitle"
      open={isOpen}
      maxWidth="sm"
      PaperProps={{
        sx: { maxWidth: '35rem', width: '50%' },
      }}
    >
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={onModalClose}
      >
        <Close />
      </IconButton>
      <DialogTitle
        id="dialogTitle"
        sx={{
          fontSize: '1.2rem',
          marginTop: '3rem',
          marginBottom: '2rem',
          color: 'primary.light',
          textAlign: 'center',
        }}
        onClose={onModalClose}
      >
        <Templates sx={{ fontSize: '3.5rem' }} />
        <Typography sx={{ fontWeight: 600 }}>Create New Report</Typography>
      </DialogTitle>
      <DialogContent>
        {!creatingDoc ? (
          <Box
            sx={{
              display: 'flex',
              marginTop: '1rem',
              justifyContent: 'center',
              gap: (theme) => theme.spacing(2),
            }}
          >
            <TextField
              value={newDocumentName}
              onChange={(event) => setDocName(event.target.value)}
              sx={{ width: '16rem' }}
              label="Report Name"
              className="fs-exclude"
            />
            <FormControl variant="standard">
              <InputLabel
                sx={{ paddingLeft: 2.3, marginTop: -1.2 }}
                id="document-select-template-label"
              >
                {isLoading ? <CircularProgress size={20} /> : 'Template (optional)'}
              </InputLabel>
              <Select
                sx={{ width: '13rem' }}
                labelId="document-select-template-label"
                label="Template (optional)"
                variant="outlined"
                size="small"
                value={template}
                onChange={handleTemplateSelect}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {templatesList(templates)}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Loading text="Creating Report..." />
        )}
        {!reportNameIsValid && newDocumentName !== '' && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {newDocumentName.length > 100 || newDocumentName.trim().length === 0
              ? 'Report name must be between 0 and 100 characters'
              : 'Report name must not contain any special characters other than _ and -'}
          </Alert>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginTop: '1rem' }}>
        <Button
          id="reports-tab-new-report-modal-create-btn"
          size="small"
          variant="contained"
          sx={{ marginBottom: '1rem' }}
          onClick={createDocument}
          disabled={!reportNameIsValid || creatingDoc}
          color={newDocumentName === '' ? 'inherit' : 'primary'}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function NewDocumentModal({ isOpen = false, onModalClose = null, onCreateNewFile }) {
  const [newDocumentName, setDocName] = useState('');
  const [creatingDoc, setCreatingDoc] = useState(false);
  const [template, setTemplate] = useState('');
  const [privateTemplate] = useState(false);
  const [reportType, setReportType] = useState(''); // ['Report Table', 'Written Report'
  const { user } = useUser();
  const { isLoading, templates } = useFetchTemplates(user.username);
  const getTemplate = useGetTemplate(user);
  useEffect(() => {
    if (isOpen) {
      setCreatingDoc(false);
      setDocName('');
      setTemplate('');
      setReportType('');
    }
  }, [isOpen]);

  const radioCardOptions = [
    {
      title: 'Report Table',
      icon: tableIcon,
    },
    {
      title: 'Written Report',
      icon: reportIcon,
    },
  ];

  async function createDocument() {
    if (!checkIfReportNameIsValid(newDocumentName)) {
      return;
    }
    setCreatingDoc(true);
    const templateSfdt =
      template !== ''
        ? await getTemplate(template)
        : JSON.stringify(require('../../resources/templates/emptySfdt.json'));

    await onCreateNewFile(
      {
        fileName: newDocumentName,
        userID: user.username,
        private: privateTemplate ? 1 : 0,
      },
      templateSfdt,
    );
    onModalClose();
  }

  function handleTemplateSelect(event) {
    setTemplate(event.target.value);
  }

  const reportNameIsValid = checkIfReportNameIsValid(newDocumentName);

  const isCreateButtonDisabled = useMemo(() => {
    if (reportType === 'Written Report') {
      return !reportNameIsValid || creatingDoc;
    }
    if (reportType === 'Report Table') {
      return creatingDoc;
    }
    return true;
  }, [reportType, reportNameIsValid, creatingDoc]);

  const createButtonOnClick = useMemo(() => {
    if (reportType === 'Written Report') {
      return createDocument;
    }
  }, [reportType, newDocumentName]);

  return (
    <Dialog
      onClose={onModalClose}
      aria-labelledby="dialogTitle"
      open={isOpen}
      maxWidth="sm"
      PaperProps={{
        sx: { maxWidth: '40rem', width: '50%' },
      }}
    >
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={onModalClose}
      >
        <Close />
      </IconButton>
      <DialogTitle
        id="dialogTitle"
        sx={{
          fontSize: '1.125rem',
        }}
        onClose={onModalClose}
      >
        <Typography sx={{ fontWeight: 600 }}>Select Report Type</Typography>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 400 }}>
          Please select the type of report you want to create below. Then click &ldquo;Create
          Report&rdquo;
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {!creatingDoc ? (
          <>
            <RadioCardSelector
              options={radioCardOptions}
              display="flex"
              justifyContent="space-evenly"
              width="100%"
              cardProps={{
                width: '232px',
                height: '168px',
              }}
              radioOnChange={setReportType}
            />
            {reportType === 'Written Report' && (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '2rem',
                  width: '100%',
                }}
              >
                <TextField
                  value={newDocumentName}
                  onChange={(event) => setDocName(event.target.value)}
                  label="Report Name"
                  sx={{
                    flex: 1,
                    marginRight: '0.5rem',
                    height: '100%',
                    /*
                      this is gross, but it's the only way I could find to get the input to be the same height as the Select
                      otherwise the height of the underlying MUI component was rendering taller than the Select
                    */
                    '.MuiOutlinedInput-root': {
                      height: '100%',
                      alignItems: 'center',
                    },
                    '.MuiOutlinedInput-input': {
                      padding: '10px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      top: 0,
                    },
                  }}
                  variant="outlined"
                  required
                />
                <Box sx={{ flex: 1, marginLeft: '0.5rem', display: 'flex', alignItems: 'center' }}>
                  <FormControl variant="outlined" size="small" fullWidth sx={{ height: '100%' }}>
                    <InputLabel id="document-select-template-label">
                      {' '}
                      {isLoading ? <CircularProgress size={20} /> : 'Template (optional)'}
                    </InputLabel>
                    <Select
                      labelId="document-select-template-label"
                      label="Template (optional)"
                      value={template}
                      onChange={handleTemplateSelect}
                      IconComponent={CircularProgress}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {templatesList(templates)}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Loading
            text={reportType === 'Report Table' ? 'Building Index...' : 'Creating Report...'}
          />
        )}
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center', marginTop: '1rem' }}>
        <Button
          size="small"
          variant="outlined"
          sx={{ marginBottom: '1rem', width: '15.625rem' }}
          onClick={onModalClose}
          backgroundColor="#FFFFFF"
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ marginBottom: '1rem', width: '15.625rem' }}
          onClick={createButtonOnClick}
          disabled={isCreateButtonDisabled}
          color="primary"
        >
          Create Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}
