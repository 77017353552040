import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

export function useStringifiedIndexDetails(indexDetails: any) {
  const [isParsed, setIsParsed] = useState(false);
  const [sections, setSections] = useState<string | null>(null);
  const [excludePages, setExcludePages] = useState<string | null>(null);
  const [excludeDocuments, setExcludeDocuments] = useState<string | null>(null);
  const [collapseDocuments, setCollapseDocuments] = useState<string | null>(null);
  const [name, setName] = useState<string>('');
  const [shouldAutogenerate, setShouldAutogenerate] = useState<0 | 1>(0);
  const [showEmptySections, setShowEmptySections] = useState<0 | 1>(0);
  const [groupAttachments, setGroupAttachments] = useState<0 | 1>(0);

  const [sectionsError, setSectionsError] = useState<string[] | null>(null);
  const [excludePagesError, setExcludePagesError] = useState<string[] | null>(null);
  const [excludeDocumentsError, setExcludeDocumentsError] = useState<string[] | null>(null);
  const [collapseDocumentsError, setCollapseDocumentsError] = useState<string[] | null>(null);

  useEffect(() => {
    if (indexDetails == null) {
      return;
    }
    setSections(JSON.stringify(indexDetails.sections, null, 2));
    setExcludePages(indexDetails.exclude_pages ? JSON.stringify(indexDetails.exclude_pages) : null);
    setExcludeDocuments(
      indexDetails.exclude_documents ? JSON.stringify(indexDetails.exclude_documents) : null,
    );
    setCollapseDocuments(
      indexDetails.collapse_documents
        ? JSON.stringify(indexDetails.collapse_documents, null, 2)
        : null,
    );
    setName(indexDetails.name);
    setShouldAutogenerate(indexDetails.should_autogenerate);
    setShowEmptySections(indexDetails.show_empty_sections);
    setGroupAttachments(indexDetails.group_attachments);

    setIsParsed(true);
  }, [indexDetails]);

  const getParsedIndexDetails = () => {
    let hasError = false;

    const parseJSON = (
      input: string | null,
      setError: React.Dispatch<React.SetStateAction<string[] | null>>,
    ) => {
      if (!input) {
        setError(null);
        return null;
      }
      try {
        setError(null);
        return JSON.parse(input);
      } catch (error) {
        setError([error.message]);
        hasError = true;
        return null;
      }
    };

    const parsedSections = parseJSON(sections, setSectionsError);
    const parsedExcludePages = excludePages ? parseJSON(excludePages, setExcludePagesError) : null;
    const parsedExcludeDocuments = excludeDocuments
      ? parseJSON(excludeDocuments, setExcludeDocumentsError)
      : null;
    const parsedCollapseDocuments = collapseDocuments
      ? parseJSON(collapseDocuments, setCollapseDocumentsError)
      : null;

    if (hasError) {
      toast.error(
        'There was an issue parsing the JSON structure of the index template. Please resolve the error messages and try again.',
      );
      throw new Error('One or more fields failed to parse');
    }
    return {
      sections: parsedSections,
      exclude_pages: parsedExcludePages,
      exclude_documents: parsedExcludeDocuments,
      collapse_documents: parsedCollapseDocuments,
      name,
      should_autogenerate: shouldAutogenerate,
      show_empty_sections: showEmptySections,
      group_attachments: groupAttachments,
    };
  };

  const parsedIndexDetails = useMemo(
    () => ({
      sections,
      exclude_pages: excludePages,
      exclude_documents: excludeDocuments,
      collapse_documents: collapseDocuments,
      name,
      should_autogenerate: shouldAutogenerate,
      show_empty_sections: showEmptySections,
      group_attachments: groupAttachments,
    }),
    [
      sections,
      excludePages,
      excludeDocuments,
      collapseDocuments,
      name,
      shouldAutogenerate,
      showEmptySections,
      groupAttachments,
    ],
  );

  const errors = useMemo(
    () => ({
      sections: sectionsError,
      exclude_pages: excludePagesError,
      exclude_documents: excludeDocumentsError,
      collapse_documents: collapseDocumentsError,
    }),
    [sectionsError, excludePagesError, excludeDocumentsError, collapseDocumentsError],
  );

  const onChanges = useMemo(
    () => ({
      sections: setSections,
      exclude_pages: setExcludePages,
      exclude_documents: setExcludeDocuments,
      collapse_documents: setCollapseDocuments,
      name: setName,
      should_autogenerate: setShouldAutogenerate,
      show_empty_sections: setShowEmptySections,
      group_attachments: setGroupAttachments,
    }),
    [
      setSections,
      setExcludePages,
      setExcludeDocuments,
      setCollapseDocuments,
      setName,
      setShouldAutogenerate,
      setShowEmptySections,
      setGroupAttachments,
    ],
  );

  return {
    indexDetails: parsedIndexDetails,
    onChanges,
    errors,
    getParsedIndexDetails,
    isParsed,
  };
}
