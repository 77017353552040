import { Routes, Route } from 'react-router-dom';
import CasesManagement from './CasesManagement';
import UserManagement from './UserManagement';
import IndexManagementContainer from './IndexManagement/IndexManagementContainer';
import CreateIndexTemplate from './IndexManagement/CreateIndexTemplate';
import UpdateIndexTemplate from './IndexManagement/UpdateIndexTemplate';
import { useUser } from '../../library/contexts/AuthContext';
import AccessProtected from '../../components/UtilComponents/AccessProtected';

function AdminContainer() {
  const { user } = useUser();
  const isAdmin = user?.email === 'support@siftmed.ca';

  return (
    <Routes>
      <Route path="/cases" element={<CasesManagement />} />
      <Route path="/users" element={<UserManagement />} />
      <Route
        path="/index"
        element={
          <AccessProtected canAccessResource={isAdmin} redirectPath="../../">
            <IndexManagementContainer />
          </AccessProtected>
        }
      />
      <Route
        path="/index/create"
        element={
          <AccessProtected canAccessResource={isAdmin} redirectPath="../../">
            <CreateIndexTemplate />
          </AccessProtected>
        }
      />
      <Route
        path="/index/update/:templateID"
        element={
          <AccessProtected canAccessResource={isAdmin} redirectPath="../../">
            <UpdateIndexTemplate />
          </AccessProtected>
        }
      />
    </Routes>
  );
}

export default AdminContainer;
