import { useMemo } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tag } from '../../../Timeline/types/timelineTypes';
import MultiSelectDropdown from '../../../../components/common/HTML_components/MultiSelectDropdown/MultiSelectDropdown';

export function DocumentTypeEditCell(
  params: GridRenderCellParams & {
    contentTagOptions: Tag[];
    deprecatedTagOptions: Tag[];
  },
) {
  const handleInputChange = (newValue: { value: string | number; label: string }[]) => {
    const mappedValues = newValue.map((value) => ({
      id: value.value,
      name: value.label,
    }));
    params.api.setEditCellValue({ id: params.id, field: params.field, value: mappedValues });
  };

  const selectedValues = useMemo(() => {
    return params.value.map((tag: { id: string }) => {
      const matchingOption =
        params.contentTagOptions.find((option) => option.value === tag.id) ||
        params.deprecatedTagOptions.find((option) => option.value === tag.id);
      return {
        value: tag.id,
        label: matchingOption?.label,
      };
    });
  }, [params.value, params.contentTagOptions, params.deprecatedTagOptions]);

  return (
    <MultiSelectDropdown
      options={params.contentTagOptions}
      deprecatedOptions={params.deprecatedTagOptions}
      selectedValues={selectedValues}
      onChange={handleInputChange}
      shouldOpenByDefault={true}
    />
  );
}
