import { useEffect, useState, JSX } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { verifyTOTPSetup, setUpTOTP, updateMFAPreference } from '@aws-amplify/auth';
import QRCode from 'react-qr-code';
import { CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import MFAVerificationSuccessful from './MFAVerificationSuccessful';
import BackButton from '../../../components/common/BackButton';
import EnterVerificationCode from './EnterVerificationCode';

interface SetupMFAUsingTOTPProps {
  back: () => void;
  user: Record<string, any>;
  onComplete: () => void;
  nextText: string;
  email: string;
}

interface ScanQRCodeProps {
  back: () => void;
  user: Record<string, any>;
  next: () => void;
  email: string;
}

type PageState = 'ScanQRCode' | 'EnterQRCode' | 'verifyTOTPSuccess';

export default function SetupMFAUsingTOTP({
  back,
  user,
  onComplete,
  nextText,
  email,
}: SetupMFAUsingTOTPProps): JSX.Element {
  const [page, setPage] = useState<PageState>('ScanQRCode');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onAlertClose = (): void => {
    setErrorMessage('');
  };

  const submitVerificationCode = async (code: string): Promise<void> => {
    try {
      await verifyTOTPSetup({
        code,
      });

      await updateMFAPreference({
        totp: 'PREFERRED',
      });

      setPage('verifyTOTPSuccess');
    } catch (error) {
      setErrorMessage('There was an error verifying your code. Please try again.');
      Sentry.captureException(error);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: '80%' }}>
        {page === 'ScanQRCode' && (
          <ScanQRCode user={user} next={() => setPage('EnterQRCode')} email={email} back={back} />
        )}
        {page === 'EnterQRCode' && (
          <EnterVerificationCode
            onSubmit={submitVerificationCode}
            back={() => setPage('ScanQRCode')}
            title="Enter the code on your authenticator app below"
          />
        )}
        {page === 'verifyTOTPSuccess' && (
          <MFAVerificationSuccessful next={onComplete} nextText={nextText} />
        )}
      </Box>

      {errorMessage !== '' && (
        <Alert severity="error" onClose={onAlertClose} sx={{ marginTop: '.5rem' }}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
}

function ScanQRCode({ back, user, next, email }: ScanQRCodeProps): JSX.Element {
  const [secret, setSecret] = useState<string>('');

  useEffect(() => {
    const setupTOTP = async (): Promise<void> => {
      const output = await setUpTOTP();
      const setupUri = output.getSetupUri('Siftmed', user.email);
      setSecret(setupUri.toString());
    };
    setupTOTP();
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      {secret !== '' ? (
        <>
          <BackButton
            onClick={back}
            style={{
              float: 'left',
              marginTop: '-2px',
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              textAlign: 'center',
              marginBottom: '2rem',
            }}
          >
            Scan the following QR code with your authenticator app.
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <QRCode value={secret} size={96} />
          </Box>
          <Box sx={{ my: 2 }} />
          <Typography
            variant="body2"
            sx={{
              textAlign: 'center',
              color: 'rgba(0,0,0,0.75)',
            }}
          >
            Tap next when you have completed the setup.
          </Typography>
          <Button fullWidth variant="contained" color="primary" onClick={next}>
            Next
          </Button>
        </>
      ) : (
        <Box sx={{ textAlign: 'center', paddingTop: '2rem' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
